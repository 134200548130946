import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';

function InvBarcode(props) {
  const { InvForFatora } = props;
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};

  const sty = {
    dirL: { direction: "ltr" },
  };
  return (
    <Grid item container style={sty.dirL}>
      {InvForFatora.tax > 0 && (
        
        <Grid item xs={12}>
          <ButtonBase style={{ border: "solid 1px #be151f" }}>
            <img
              src={`data:image/jpeg;base64,${InvForFatora.qr_image}`}
              style={{ height: 120, width: 120 }}
              alt={"found.nameA"}
            />
          </ButtonBase>
        </Grid>
      )}
    </Grid>
  );
}
export default InvBarcode;
