import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PrintInvoiceDirectly } from "../../components/printSerivce";
import { sto } from "../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { reFormatDate } from "../../../../../utils/utils";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

export default function SearchInvTable(props) {
    const { data, isPurches,showChosedInv, setOr, or, setting, setIsClick, ished ,isHedden,
         permistionInv,setIshedden, isStock} = props;


    const stylH = {
        // borderWidth: 1,
        border: "solid 0.6px",
        borderColor: "black",
        borderStyle: "solid",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 15,
        color: "#fff"
    }
    const stylRo = {
        BorderBottom: "solid 0.5px",
        //borderStyle: "solid",
        borderColor: "gray",
        fontFamily: "Amiri-Regular",
        fontWeight: 700,
        fontSize: 14,
        color: "#000"
    }

    const onClickPrint = async (row) => {
        const serializedinv = JSON.stringify({

            proc_id: row.proc_id,
            invN: setting.titleHed,
            invNEn: setting.titleHedEn,
            // salesman: valueSalesmen.name,
            backInv: "InvSO",
            branch: sto.branchi,
            ProcTypeInvProc: setting.PrTy_InPr,
        });
        await PrintInvoiceDirectly(
            JSON.parse(serializedinv),
            false,
            () => { }
        );
    }
    const onRowClick = (rowData) => {
        setOr({ ...or, postput: "toPut", PrAC: 0 });
        showChosedInv(false, rowData.proc_id);
        setIsClick({ ...ished, hed: true });
        if (setting.PrTy_InPr==="02"){
            setIshedden(true);
        }else{
            setIshedden(permistionInv.Up==true?false:true);
        }
       // setIshedden(permistionInv.Up==true  &&setting.PcIdIvPc=="02" ?true:permistionInv.Up==true?false:true);
    };
    const { t } = useTranslation(["forms"]);
    const classes = useStyles();
    return (
        <TableContainer component={Paper} >
            <Table
                //className={classes.table} 
                size="small" aria-label="a dense table" >
                <TableHead>
                    <TableRow
                        style={{
                            backgroundColor: isPurches ? "#008080" : isStock ? "#3f51b5" : colors.green[300],
                            color: "#000",
                            fontWeight: 800,
                            fontFamily: "Amiri-Regular",
                            fontSize: 15,
                            border: "solid 0.6px"
                        }}
                    >
                        <TableCell style={stylH} align="center">
                            {"الرقم"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"التاريخ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"الوقت"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {isPurches ? "المورد" : "العميل"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"المبلغ"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"نوع الفاتورة"}
                        </TableCell>
                        
                        <TableCell style={stylH} align="center">
                            {"طريقة الدفع"}
                        </TableCell>
                        
                        <TableCell style={stylH} align="center">
                            {"المدفوع"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"المتبقي"}
                        </TableCell>
                        
                        <TableCell style={stylH} align="center">
                            {"كاش"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"شبكة"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"بنك"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"أجل"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"المستخدم"}
                        </TableCell>
                        <TableCell style={stylH} align="center">
                            {"Action"}
                        </TableCell>
                        {/* <TableCell style={stylH} align="center">  </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.proc_id}
                            style={{
                                padding: "5px 0px",
                                textAlign: "-webkit-center",
                                margin: "0px",
                                whiteSpace: "nowrap",
                                color: "#000",
                                fontWeight: 600,
                                fontFamily: "Amiri-Regular",
                                fontSize: 15,
                                border: "solid 0.6px"
                            }}
                           >
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.id}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{reFormatDate(row.date)}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.time}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.customer_name}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.net_total?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center"onClick={() => { onRowClick(row); }} > {row.inv_type == 1 ? "اجل" : "نقدي"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.inv_type == 1 ? "اجل" : row.pay_type == 2 ? "شبكة" : "كاش"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > { (parseFloat(row.cash_part)+parseFloat(row.network_part)+parseFloat(row.bank_part))?.toFixed(2)}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.agel_part?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.cash_part?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.network_part?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.bank_part?.toFixed(2) ?? "-"}</TableCell>
                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }} > {row.agel_part?.toFixed(2) ?? "-"}</TableCell>

                            <TableCell style={stylRo} align="center" onClick={() => { onRowClick(row); }}>{row.username}</TableCell>
                            <TableCell  align="center" >
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    // onChange= {getCell(false)}
                                    style={{maxHeight:"0.7rem"}}
                                    onClick={() => {
                                        onClickPrint(row);
                                    }}>
                                    <Print size={"small"} />
                                </IconButton>
                            </TableCell>
                            {/* <TableCell  style={stylRo} align="center" ></TableCell> */}


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
