import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../help/Api";
import { Apis } from "../help/Apis";
import { getLS } from "../utils/useLS";
import dataF from "../Server/dataF.json";
import { insh } from "../Context/InshData";
import { tbReGitData } from "../Context/InshPublic";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

import Slide from "@material-ui/core/Slide";

import DialogNotes from "../utils/DialogNotes";
import i18next from "i18next";
import SelectLanguage from "../components/SelectLanguage";
import { useGridKeyboardNavigation } from "@material-ui/data-grid";

const languageOptions = [
  {
    key: 1,
    value: "العربية",
    lang: "ar",
  },
  {
    key: 2,
    value: "english",
    lang: "en",
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  iroot: {
    flexGrow: 1,
  },
  root2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(5, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  Box: {
    bottom: "14px",
    position: "absolute",
  },
  Btnlink: {
    bottom: "14px",
    position: "absolute",
    color: "#2688ba",
    backgroundImage: "linear-gradient(to right, #4e9e8414 , #2688ba14)",
  },
  title: {
    color: "#4e9e84",
  },
  button: {
    margin: theme.spacing(2),
    height: "3.5rem",
    borderRadius: 10,
    fontSize: 17,
    backgroundImage: "linear-gradient(to right, #4e9e84 , #2688ba)",
  },
  placeholder: {
    height: 40,
  },
  typography: {
    marginTop: 16,
  },
}));

const SignIn = () => {
  const classes = useStyles();
  let his = useHistory();

  const [user, setUser] = useState({ username: "", password: "" });

  const [wherRem, setWherRem] = useState({ remWher: false });

  // const [optionsdb, setOptionsdb] = React.useState([]);
  const [valuedb, setValuedb] = React.useState({});
  //Branch
  const [optionsBranch, setOptionsBranch] = React.useState([]);
  const [valueBranch, setValueBranch] = React.useState({});

  const [ListUsers, setListUsers] = useState(insh.tbname.Users);
  const [valueUsers, setValueUsers] = React.useState({});

  const [dNoteState, setDNoteState] = useState(false);
  const [dNote, setDNote] = useState({ title: "", sub: "", txtBtn: "" });
  const clickDialogNots = (title, sub, txtBtn) => {
    setDNoteState(!dNoteState);
    setDNote({ title: title, sub: sub, txtBtn: txtBtn });
  };

  const locConn = JSON.parse(window.localStorage.getItem("infoConn")) || {};

  const inshState = {
    server: locConn.server,
    ipinsh: locConn.ipinsh,
    ip: locConn.ip,
    changip: "",
    endDigtip: "",
    namedb: locConn.namedb,
    id: locConn.id,
    pass: locConn.pass,
    port: locConn.port,
    ftp: locConn.ftp,
    useUri: false,
    typeConnct: locConn.typeConnct,
    mode: locConn.mode,
    leavle: "endemam",
  };

  const [state, setState] = useState(locConn);

  async function setInfoConn(stateConn) {
    window.localStorage.setItem("infoConn", JSON.stringify(stateConn));
  }

  const refresh = () => window.location.reload(false);

  const tostart = () => {
    setInfoConn(inshState);
    his.replace({ pathname: "/" });

    // refresh();
  };

  const data = { username: user.username, password: user.password };

  function firestWhere() {
    localStorage.setItem("DataRem", JSON.stringify(data));
  }

  const changebanch = (newValue) => {
    // localStorage.setItem('iBranch', JSON.stringify(newValue.id));
    setValueBranch(newValue);

    loadGet(true, false, newValue.id);
  };

  const changAutos = (e) => {
    setUser({ ...user, username: e.name });
    setValueUsers(e);
  };

  // const getBranch = () => {
  //   axios.get(uri+'Branch').then(res => {
  //     setOptionsBranch(res.data);
  //     setValueBranch(res.data[0]);
  //     // localStorage.setItem('iBranch', JSON.stringify(res.data[0].id));
  //   }).catch(error => { })
  // }

  const loadGet = async (stateU, stateB, idB) => {
    const tb = {
      Users: stateU,
      Branches: stateB,
      parms: { ibranchid: idB },
      autos: {
        Users: true,
        Branches: true,
      },
    };

    const uris = Apis();
    // alert('uris sigin = '+ uris)

    console.log("tb");
    console.log(tb);
    //  await axios.post('http://localhost:8184/api/Res', tb)
    await axios
      .post(uris + "Res", tb)
      .then((res) => {
        console.log("Users data");
        console.log(res.data.Users);
        console.log("Branches data");
        console.log(res.data.Branches);
        setListUsers(res.data.Users);

        if (stateB == true) {
          setOptionsBranch(res.data.Branches);
          setValueBranch(res.data.Branches[0]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let loc = getLS("infoConn", {});
    setState(loc);

    loadGet(true, true, -1);

    clearTimeout(timerRef.current);
    // getBranch();

    const locRem = JSON.parse(window.localStorage.getItem("DataRem")) || {};

    if (locRem.userWher === false) {
      firestWhere();
    } else {
    }
  }, []);

  const clickHide = () => {
    setQuery("success");
    timerRef.current = window.setTimeout(() => {
      setQuery("success");
    }, 2000);
  };

  const clickQueryShow = (val) => {
    clearTimeout(timerRef.current);
    setQuery(val);
    timerRef.current = window.setTimeout(() => {
      setQuery(val);
    }, 1000);
  };

  // const stor geing = async (user_id,emp_id,branch_id) => {

  const allowLog = (saf, stok, custA, custM) => {
    if (saf == 0 || stok == 0 || custA == 0 || custM == 0) return false;
    else return true;
  };

  const storgeing = async (uinfo) => {
    tbReGitData.parms.ibranchid = uinfo.branchi;
    tbReGitData.parms.iempid = uinfo.empi;
    tbReGitData.parms.iuserid = uinfo.idi;
    // let tbReGitData1 ={
    //   ...tbReGitData,
    //   parms:{...tbReGitData.parms, ibranchid: }
    // }
    console.log("the uinfo", uinfo);
    const uris = Apis();
    console.log("tbReGitData");
    console.log(tbReGitData);
    //  await axios.post('http://localhost:8184/api/Res',tbReGitData)
    await axios
      .post(uris + "Res", tbReGitData)
      .then((res) => {
        console.log("the rsult", res);
        let allow = allowLog(
          res.data.Safes.length,
          res.data.Stocks.length,
          res.data.CustomersA.length,
          res.data.CustomersM.length
        );

        // alert(allow +' - '+ typeof(allow))

        if (allow == true) {
          uinfo.found = res.data.FoundatioT.nameA;
          uinfo.branchNames = valueBranch.name;
          uinfo.branchi = valueBranch.id;
          uinfo.Step_inv2 = res.data?.Step_inv2;
          window.localStorage.setItem("DataRem", JSON.stringify(uinfo));
          window.localStorage.setItem("sto", JSON.stringify(res.data));
          window.localStorage.setItem(
            "premiApp",
            JSON.stringify(res.data.permisApp)
          );

          setInfoConn(state);
          clickHide();
          his.replace({ pathname: "/" });
          // window.location.reload(false);
        } else {
          clickQueryShow("idle");
          clickDialogNots(
            "رسالة توضيحية",
            "يجب التأكد من إضافة صناديق او مخازن وعملاء وموردين لهذا المستخدم",
            "فـهـمـت"
          );
        }
      })
      .catch((error) => {
        // console.log('nooooo sttorgeing');
        alert(error);
      });
  };

  const Login = (e) => {
    e.preventDefault();
    setWherRem({ remWher: true });

    // debugger;
    const data = {
      username: user.username,
      password: user.password,
      branch: valueBranch.name,

      // path:valuedb.dbName,
    };

    if (user.username == "" && user.password == "")
      // click DialogNots(true,'قم بإختيار إسم المستخدم وكلمة المرور')
      clickDialogNots(
        "رسالة توضيحية",
        "قم بإختيار إسم المستخدم وإدخال كلمة المرور",
        "فـهـمـت"
      );
    else if (user.username == "")
      // click DialogNots(true,'قم بإختيار الإسم')
      clickDialogNots("رسالة توضيحية", "قم بإختيار الإسم", "فـهـمـت");
    else if (user.password == "")
      // click DialogNots(true,'قم بإدخال كلمة المرور')
      clickDialogNots("رسالة توضيحية", "قم بإدخال كلمة المرور", "فـهـمـت");
    else {
      clickQueryShow("progress");

      console.log("data");
      console.log(data);
      const uris = Apis();
      console.log(data);

      axios
        .post(uris + "Login" + "/Loginpth", data)
        .then((result) => {
          if (result.data.status == "200") {
            state.leavle = "main";
            console.log("the user info", result.data.userinfo);
            let uinfo = { ...result.data.userinfo, branchi: valueBranch.id };
            storgeing(uinfo);
          } else {
            clickQueryShow("idle");
            clickDialogNots(
              "رسالة توضيحية",
              "كلمة المرور غير صحيحة",
              "فـهـمـت"
            );
            // click DialogNots(true,'إسم المستخدم أو كلمة المرور غير صحيحة');
          }
        })
        .catch((err) => {
          clickQueryShow("idle");
          console.log("the error is", err);
          clickDialogNots(
            "رسالة توضيحية",
            'يبدوا ان الـ ip تغير قم بالرحوع إلى إنظمام بالنقر على "تغيير نوع الإنظمام"',
            "فـهـمـت"
          );
        });
    }
  };

  const onChange = (e) => {
    // e.persist();
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const [query, setQuery] = React.useState("idle");
  const timerRef = React.useRef();

  // const SingnSaync_ = () => {
  //   return (
  //     <div className={classes.root}>
  //       <div className={classes.placeholder}>
  //         {query === 'success' ? (
  //         <Typography className={classes.typography}>تم التحقق من بياناتك بنجاح</Typography>)
  //         : (
  //           <Fade in={query === 'progress'}
  //             style={{ transitionDelay: query === 'progress' ? '900ms' : '0ms',}}unmountOnExit>
  //             <CircularProgress />
  //           </Fade>
  //         )}
  //       </div>
  //       <Button fullWidth type="submit" className={classes.button}
  //                     variant={query !== 'idle' ? 'outlined' : 'contained'}
  //                     color="primary"
  //                     onClick={Login}
  //                     // className={classes.submit}
  //                     endIcon={<ArrowForwardIcon />}
  //             disabled={query !== 'idle' ? true : false}
  //       >
  //         {'دخــول'}
  //       </Button>
  //     </div>
  //   )
  // }

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid item container className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}> <LockOutlinedIcon /> </Avatar> */}
            <Typography component="h1" variant="h5" className={classes.title}>
              تسجيل الدخول
            </Typography>

            <Grid className={classes.form}>
              <form>
                <Autocomplete
                  value={valueBranch}
                  onChange={(event, newValue) => {
                    changebanch(newValue);
                  }}
                  id="controllable-states-demo"
                  options={optionsBranch}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  //style={{ width: 300 }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="الفرع"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />

                <Autocomplete
                  value={valueUsers}
                  onChange={(event, newValue) => {
                    changAutos(newValue);
                  }}
                  id="controllable-states-demo"
                  options={ListUsers}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  disableClearable
                  autoComplete={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="إسم المستخدم"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </form>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="كلمة المرور"
                type="password"
                id="password"
                value={user.password}
                onChange={onChange}
              />
              <SelectLanguage />
              <div className={classes.root2}>
                <div className={classes.placeholder}>
                  {query === "success" ? (
                    <Typography className={classes.typography}>
                      تم التحقق من بياناتك بنجاح
                    </Typography>
                  ) : (
                    <Fade
                      in={query === "progress"}
                      style={{
                        transitionDelay: query === "progress" ? "900ms" : "0ms",
                      }}
                      unmountOnExit
                    >
                      <CircularProgress />
                    </Fade>
                  )}
                </div>
                <Button
                  fullWidth
                  type="submit"
                  className={classes.button}
                  variant={query !== "idle" ? "outlined" : "contained"}
                  color="primary"
                  onClick={Login}
                  // className={classes.submit}
                  endIcon={<ArrowForwardIcon />}
                  disabled={query !== "idle" ? true : false}
                >
                  {"دخــول"}
                </Button>
              </div>

              <Grid item container component="main">
                <Button
                  className={classes.Btnlink}
                  onClick={tostart}
                  // variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<LinkIcon />}
                >
                  {/* (ip) تغيير الـ */}
                  الرجوع لتغيير الإرتباط
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <DialogNotes
        DialogOpen={dNoteState}
        DialogClose={() => setDNoteState(false)}
        content={{ title: dNote.title, sub: dNote.sub, txtBtn: dNote.txtBtn }}
      />
    </React.Fragment>
  );
};

export default SignIn;
