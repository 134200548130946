import { sto } from "./InshData";

const tbReGitData = {
  /*other:true,*/ // out
  wait: true,

  Currencies: true,
  /* Permissions:true,PermissionsCurr:true, */ // out
  groups: true,
  FavGroup: true,
  View_CurrencieUnit: true,
  View_CurrenciesToInv: true,
  // View_CurrenciesChose:true, // out
  // ViewR_SAMER_jard_safe:true, /* rex Error now go to Inves */ // out
  Users: true,
  Foundations: true,
  Step_inv2: true,
  types: true,
  Safes: true,
  Stocks: true, // Error // out
  Banks: true,
  Customers: true,
  salesmen: true,
  costcenters: true,
  Branches: true,
  Employees: true,
  typesFltr: true,
  PermissionApp: true,
  omlat: true,
  /*params*/ // out
  parms: {
    // sProcTypeInvProc:"02", // out
    ibranchid: sto.branchi,
    iempid: sto.empi,
    iuserid: sto.idi,
    groupsSub: true,
    scurrenciestype: "I",
    
    scurrencieShow: "Fav",
    safe: sto.safe,
    // currShow:"D",
    currShow: "M",
    igroupid: -1,
    currType: 2, // out
    takei: 50,
    sSearch: "",
  },
  autos: {
    Users: true,
    Branches: true,
    Safes: true,
    Stocks: true,
    Banks: true,
    Employees: true,
    salesmens: true,
    costcenters: true,
    FavGroup: true,
    groups: true,
    // omlat: true,
  },
};

export { tbReGitData };
