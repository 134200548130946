import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../help/Api";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { useInvCa } from "../../../St/Reviews/UseStyle";
import { sto } from "../../../Context/InshData";
import { QRCodeCanvas } from "qrcode.react";

function Footer(props) {
  const { onUnmountComplete } = props;
  const clas = useInvCa();
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [found, setFound] = useState({});
  const [inv, setInv] = useState({});

  const getFound = async () => {
    await axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setFound(res.data);
      })
      .catch((error) => {});
  };

  const getInv = async () => {
    await axios
      .get(uri + `InvForFatora?proc_id=${locDinv.proc_id}`)
      .then((res) => {
        console.log("child footer", res.data);
        setInv(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    
    setTimeout(async () => {
      await getFound();
      await getInv();
      
      onUnmountComplete();
    }, 0);
  }, []);
  const sty = {
    cntr: { textAlign: "center" },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: { direction: "rtl", color: "black" },
    dirL: { direction: "ltr" },
  };

  return (
    // <span style={{color:'black'}}>
    <Grid item container style={sty.dirR}>
      <Grid item container style={sty.cntr} spacing={0}>
      <Grid item xs={12}>
        {inv?.notes?.toString()?.startsWith('Receipt voucher') && inv?.notes}
      </Grid>
        <br />
        <Grid xs={12}>
          <Typography
            className={[clas.contntSub]}
            style={{
              margin: "0.5rem 0.1rem 0.5rem 0.1rem",
              borderRadius: 5,
              color: "#000000",
              background: "#ffffee",
              padding: "0.5rem 0.5rem 0.5rem 0.5rem",
              fontSize: 10,
            }}
          >
            {found.Address}
          </Typography>
        </Grid>
        {/*     
        <Grid item xs={12}>  
          <ButtonBase style={{border:'solid 0.1px',borderRadius:'3',margin:0,padding:0}}>
          <img src={`data:image/jpeg;base64,${inv.qr_image}`} style={{height:'57mm',width:'57mm'}} alt={found.nameA} /> 
         </ButtonBase>
        </Grid> */}

        <Grid item xs={12}>
          <ButtonBase
            style={{ borderRadius: "3", margin: 0, padding: 0, marginTop: -20 }}
          >
            {
              (sto?.Step_inv2 && inv?.ZatcaQR)?<QRCodeCanvas value={inv?.ZatcaQR} />
              :  <img
              src={`data:image/jpeg;base64,${inv.qr_image}`}
              style={{ height: "47mm", width: "47mm" }}
              alt={found.nameA}
            />
             }
            
           
          </ButtonBase>
        </Grid>

        <Grid item xs={12}>
          {"_____"}
        </Grid>
        <Grid item xs={12}>
          {sto.usernames}
        </Grid>
      </Grid>
    </Grid>
    // </span>
  );
}
export default Footer;

// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import {uri, }  from '../../../../help/Api';
// import Grid from '@material-ui/core/Grid';
// import { Typography } from '@material-ui/core';

// function Footer(){

//   const [state, setState] = useState({});
//   const getInfo = async () => {
//   axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
// }

// useEffect(() => {
//   getInfo();
// }, []);

//     return (
//     <Grid className="fixed-footer bg">
//       <Grid item container style={{textAlign:'center',direction:'rtl',}}>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المستخدم</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المحاسب</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المراجع</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المدير المالي</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المدير العام</Typography> <Typography className="box b cw"></Typography>
//         </Grid>

//         <Grid lg={12} md={12} sm={12} xs={12} >
//           <Typography className="cw b">{state.Address}</Typography>
//         </Grid>

//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">هاتف:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Tel}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">مبايل:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Mobile}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">فاكس:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Fax}</Typography></Grid>
//       </Grid>
//     </Grid>
//     );
//   }

// export default Footer
