import moment from "moment/moment";
import { sto } from "../Context/InshData";
import { CostType } from "./currencySalesProfit-services";

export const selectTypes = [
  { id: 1, name: "Stocks", label: "stock" },
  { id: 2, name: "Customers", label: "customer" },
  { id: 3, name: "Currencies", label: "item" },
  { id: 4, name: "Month", label: "months" },
  { id: 5, name: "Day", label: "days" },
  { id: 6, name: "salesmen", label: "salesman" },
  { id: 7, name: "Users", label: "user" },
  { id: 8, name: "Group", label: "group" },
  // {id:1, name:'Stocks', label:'الصندوق'},
  // {id:2, name:'Customers', label:'العميل'},
  // {id:3, name:'Currencies', label:'الصنف'},
  // {id:4, name:'Month', label:'الشهور'},
  // {id:5, name:'Day', label:'الأيام'},
  // {id:6, name:'salesmen', label:'مندوب البيع'},
  // {id:7, name:'Users', label:'المستخدم'},
  // {id:8, name:'Group', label:'مجموعة الصنف'},
];

export const selectDateOrTime = [
  { id: 1, name: "false", label: "by-date-only" },
  { id: 2, name: "true", label: "by-date-time" },
];
export const invSearchOptions = [
  { id: 1, name: "false", label: "by-date-only" },
  { id: 2, name: "true", label: "by-date-time" },
  { id: 3, name: "false", label: "Zatca " },
];
export const selectVatTypes = [
  { id: 0, name: "0", label: "كل الأصناف" },
  { id: 1, name: "1", label: "أصناف خاضعة للضريبة" },
  { id: 2, name: "2", label: "أصناف غير خاضعة للضريبة" },
];
export const selectTransferType = [
  { id: 0, label: "تحويل فقط" },
  { id: 1, label: "استلام تلقائي" },
];


export const SAdefaultValues = {
  selectType: selectTypes[0],
  dateFrom: moment(new Date()).format('YYYY-MM-DD'),
  timeFrom: moment(new Date()).format('HH:mm'),
  dateTo: moment(new Date()).format('YYYY-MM-DD'),
  timeTo: moment(new Date()).format('HH:mm'),
  selectTime:selectDateOrTime[0],
  safe: { id: -1, name: "الكل" },
  cust: { id: -1, name: "الكل" },
  user: { id: -1, name: "الكل" },
  stock: { id: -1, name: "الكل" },
  sales_man: { id: -1, name: "الكل" },
  group: { id: -1, name: "الكل" },
  branch: { id: -1, name: "الكل" },
  chk_withvat: false,
  type_cost: 1,
};
export const ValueItemsSearch = {
  tax_group: { id: -1, name: "الكل" },
  group: { id: -1, name: "الكل" },
  branch: { id: -1, name: "الكل" },
  unit: { id: -1, name: "الكل" },
  type: { id: -1, name: "الكل" },
};


export const salesmanCommTypes = [
  { id: 0, name: "العمولة من إجمالي قيمة الفاتورة" },
  { id: 1, name: "العمولة من إجمالي كمية الصنف" },
];

export const invoiceTypes = [
  { id: -1, name: "الكل" },
  { id: 1, name: "أجلة" },
  { id: 2, name: "نقدية" },

];

export const salesmanrpt = {
  dateFrom: moment(new Date()).format('YYYY-MM-DD'),
  timeFrom: moment(new Date()).format('HH:mm'),
  dateTo: moment(new Date()).format('YYYY-MM-DD'),
  timeTo: moment(new Date()).format('HH:mm'),
  selectTime:selectDateOrTime[0],
  salesMan: { id: -1, name: "الكل" },
  branch: sto.branchi,
  CommType: salesmanCommTypes[0],
  type_Cost: CostType[0],
  CommWithVat: false,
  CommWithProfit: false,
};
