import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import Paper from "@material-ui/core/Paper";
import { getTotalAmount } from "../../../../utils/utils";
import { useBody } from "../../invCompany/style";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Rept, sto } from "../../../../Context/InshData";
import { QRCodeCanvas } from "qrcode.react";


const UnderHeader = (props) => {

    const {found ,inv,sta,locInv}=props;
    const clasm = useInvMain();
    const clas = useInvAF();
    const [showSc, setShowSc] = useState(true);
    const calssBody = useBody();

    const styf = { fontWeight: "500" };
    const styfC = { fontWeight: "500", color: "#9a3f47" };
 

    return  (
        <Grid
        item
        container
        // className={clas.Bdyroot}
        // <Grid item container className={cls.root}>
        // <Grid
        //   item
        //   container
        //   className={classHeader.headerContainer}
          style={{ fontWeight: "bold" ,fontFamily: "Amiri-Regular",marginLeft: "0cm",marginRight:"0rem",paddingTop:"0.5rem"}}
      
    >
        <Grid item xs={12}>

            <Grid item container >
                {locInv.ProcTypeInvProc != "03" ?
                    (
                        <Grid item xs={3} container>
                            <Grid item xs={3}></Grid>


                            {
                            //     <Grid item xs={8}>
                            //     <ButtonBase style={{ border: "solid 1px" }}>
                            //         <img
                            //             src={`data:image/jpeg;base64,${inv.qr_image}`}
                            //             style={{ height: 100, width: 100 }}
                            //         // alt={found.nameA}
                            //         />
                            //     </ButtonBase>
                            // </Grid>

                            <Grid item xs={8}>
                            <ButtonBase
                              style={{ borderRadius: "3", margin: 0, padding: 0,}}
                            >
                              {
                                (sto?.Step_inv2 && inv?.ZatcaQR)?<QRCodeCanvas value={inv?.ZatcaQR} />
                                :  <img
                                src={`data:image/jpeg;base64,${inv.qr_image}`}
                                style={{ height: "47mm", width: "47mm" }}
                                alt={found.nameA}
                              />
                               }
                              
                             
                            </ButtonBase>
                          </Grid>
                  
                            }

                            <Grid item xs={1}></Grid>

                        </Grid>
                    )
                    :
                    (<Grid item xs={2} container>
                        <Grid item xs={12}></Grid>
                    </Grid>)
                }
                <Grid item xs={9} container  style={{alignContent:"right"}}>
                    {locInv.ProcTypeInvProc != "03" && (
                        <Grid item xs={12} container>
                            {/* <Grid item xs={1}></Grid> */}

                            <Grid item xs={11} container className={clas.BdytitleHedMusa}>

                                {/* <Typography className={clasm.minclr}>{txtA.titleFatora}</Typography> */}

                                <Grid item xs={6} className={clasm.minclr} style={{textAlign:"center"}}>
                                    {sta.tot.tax_no_cust === ""
                                        ? "فاتورة ضريبية مبسطة"
                                        : "فاتورة ضريبية"}
                                </Grid>
                                <Grid item xs={6} className={clasm.minclr} style={{textAlign:"center"}}>
                                    {sta.tot.tax_no_cust === ""
                                        ? "Simplified Tax Invoice"
                                        : "Tax Invoice"}
                                </Grid>

                            </Grid>

                            <Grid item xs={1}></Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} container>
                        {/* <Grid item xs={1}></Grid> */}
                        <Grid item container xs={11} className={clas.BdytitleHedMusa}>
                            <Grid item xs={6} className={clasm.minclr}>
                                {locInv.invN +
                                    " " +
                                    sta.tot.inv_type_name +
                                    " " +
                                    sta.tot.pay_type_name}
                            </Grid>
                            <Grid item xs={6} className={clasm.minclr}>
                                {locInv.invNEn +
                                    " " +
                                    sta.tot.inv_type_name_en +
                                    " " +
                                    sta.tot.pay_type_name_en}
                            </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>


                </Grid>
                {/* <Grid item xs={2}></Grid> */}
            </Grid>


            <Grid item container style={{ direction: "rtl" ,paddingTop:"0.5rem" }}>
                {/* <Grid item xs={1}></Grid> */}
                <Grid item xs={12}/* className={calssBody.BdymtHeader} */
                >
                    <Grid item container className={clasm.mincntr}>
                        <Grid item container xs={12}>
                            <Grid item container xs={6}>
                                <Grid item xs={3} className={clas.BdyborBtRTOP1} style={{ textAlign: "right", paddingRight: 7 }}>
                                    {txtA.invNumr}
                                </Grid>
                                <Grid item xs={6} className={clas.BdyborBtRTOP3} >
                                    <Paper className={clasm.minclr}>{sta.tot.id}</Paper>
                                </Grid>
                                <Grid item xs={3} className={clas.BdyborBtRTOP2} style={{ textAlign: "left" ,paddingLeft: 7}}>
                                    {/* {txtE.invNumr} */}
                                    {"Inv No "}

                                </Grid>
                            </Grid>
                            <Grid item container xs={6}>
                                <Grid item xs={3} className={clas.BdyborBtRTOP1} style={{ textAlign: "right" ,paddingRight:7}}>
                                    {txtA.invRef}
                                </Grid>
                                <Grid item xs={6} className={clas.BdyborBtRTOP3}>
                                    <Paper className={clasm.minclr}>{sta.tot.refno}</Paper>
                                </Grid>
                                <Grid item xs={3} className={clas.BdyborBtRTOP2} style={{ textAlign: "left", paddingLeft: 7 }}>
                                    {txtE.invRef}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={clas.BdyborBtR} style={{ textAlign: "right", paddingRight: 7 }}>
                                    {"التاريخ"}
                                </Grid>
                                <Grid item xs={6} className={clasm.minborB}>
                                    <Paper className={clasm.minclr}>
                                        {moment(sta.tot.date).format("YYYY/MM/DD")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3} className={clas.BdyborBtL} style={{ textAlign: "left", paddingLeft: 7 }}>
                                    {"Date"}
                                </Grid>
                        <Grid item xs={3} className={clas.BdyborBtR} style={{ textAlign: "right", paddingRight: 7 }}>
                            {txtA.custmName}
                        </Grid>
                        <Grid item xs={6} className={clasm.minborB}>
                            <Paper className={clasm.minclr}>{sta.tot.cust_name}</Paper>
                        </Grid>
                        <Grid item xs={3} className={clas.BdyborBtL} style={{ textAlign: "left", paddingLeft: 7 }}>
                            {txtE.custmName}
                        </Grid>

                        <Grid item xs={3} className={clas.BdyborBtR} style={{ textAlign: "right", paddingRight: 7 }}>
                            {txtA.custAddress}
                        </Grid>
                        <Grid item xs={6} className={clasm.minborB}>
                            <Paper className={clasm.minclr}>{sta.tot.cust_address}</Paper>
                        </Grid>
                        <Grid item xs={3} className={clas.BdyborBtL} style={{ textAlign: "left", paddingLeft: 7 }}>
                            {txtE.custAddress}
                        </Grid>

                        <Grid item xs={3} className={clas.BdyborBtR} style={{ textAlign: "right", paddingRight: 7 }}>
                            {txtA.taxNumr}
                        </Grid>
                        <Grid item xs={6} className={clasm.minborB}>
                            <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
                        </Grid>
                        <Grid item xs={3} className={clas.BdyborBtL} style={{ textAlign: "left", paddingLeft: 7 }}>
                            {"VAT NO"}
                        </Grid>
                        <Grid item xs={3} className={clas.BdyborBtR} style={{ textAlign: "right", paddingRight: 7 }}>

                            {"السجل التجاري"}
                        </Grid>
                        <Grid item xs={6} className={clasm.minborB}>
                            <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
                        </Grid>
                        <Grid item xs={3} className={clas.BdyborBtL} style={{ textAlign: "left", paddingLeft: 7 }}>
                            {"C R No"}
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={1}></Grid> */}
            </Grid>
        </Grid>

    </Grid>
    );
};

export default UnderHeader;
