import React, { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import { insh, sto, premiA } from "../../../Context/InshData";
import { locSto as locStos } from "../../../Context/Locstor";
import { getLS } from "../../../utils/useLS";
import { transToSal } from "../../../Context/Local";
import { inshF } from "../../../Context/InshFront";
import moment from "moment";
import StopIcon from "@material-ui/icons/Stop";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

import { useHistory, useLocation } from "react-router-dom";
import HomeBcode from "../../../barcode/HomeBcode";
import dataF from "../../../Server/dataF.json";
// f d
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";

// f e
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SaveIcon from "@material-ui/icons/Save";
import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import ScannerIcon from "@material-ui/icons/Scanner";
import TransformIcon from "@material-ui/icons/Transform";
// t
import MaterialTable, { MTableToolbar } from "material-table";
import { TableIcons as tableIcons } from "../../../Elemnts/TableIcons";
import {
  ArrowDownward,
  Delete,
  MoreVert,
  PersonAddRounded,
  RestaurantMenu,
} from "@material-ui/icons";

// ===================================================== btn load
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
// ===================================================== btn load
// o com
// import CustomersDialog from '../../Customers/BackEnd/Tab1/CustomersDialog';
// Container
import Container from "@material-ui/core/Container";

import Hidden from "@material-ui/core/Hidden";
import {
  useStylesUp,
  useStylesHed,
  useStylesAuto,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesFU,
  useStylesCAB,
  useStylesLbl,
  SaleCheckbox,
  useStylesDSM,
  useStylesDPM,
  StyledMenuItem,
  StyledMenu,
  useStylesSN,
  SaleSwitch,
} from "../../../St/comps/UseStyle";

import { cellS, headerS, stElemnt } from "../../../St/comps/cStyle";
import { Mtb } from "../../../St/comps/vstyle";

import "../../../St/comps/csStyle.css";
import BackdropScI from "../../../services/BackdropScI";

import FavCard from "../Elemnt/FavCard";
import ReConnect from "../../../Start/ReConnect";
import OutstandingInvoiceModal from "./models/outstanding-invoice-modal";
import { getInveTypeIndex } from "../../../modules/utils";
import InvoiceItemsModal from "./models/invoice-items-modal";
import { getDefaultOmla } from "../../../services/omlat-services";
import CustomersDialog from "../../Customers/customers/customersDialog";
import ItemsDialog from "../../bascDatas/Tab1/items/ItemsDialog";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { callAddFont } from "../../../Reviews/amiri-font";
import toast, { Toaster } from "react-hot-toast";
import {
  handleDirectPrintTemp2,
  PrintInvoiceDirectly,
} from "./components/printSerivce";
import SearchInvoiceModal from "./models/search-model/search-invoice-modal";
import ExtVoucherDialog from "./components/ExtVoucherDialog";
import DeleteConfirmDialog from "../../../components/DeleteConfirmDialog";
import PaidWindowForm from "./components/paidwidow/paidwindowform";
import PagenationButtons from "../../../components/controls/PagenationButons";
import { useLocalStorage } from "react-use";
import SearchInvoice from "./models/search-model/SearchInvoice";
import { wait } from "@testing-library/react";
import { colors } from "@material-ui/core";
import { data } from "../../Dashboard/components/LatestSales/chart";

//=====================================================================
let nevEve = "always",
  nl = null;

const useStylestolb = makeStyles((theme) => ({
  toolbarWrapper: {
    "& .MuiToolbar-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let LetvalueCurrUnits = null;

export default function FirstTermInvoice() {
  const cl = (c) => {
    console.log(c);
  };
  const [showSc, setShowSc] = useState(true);

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasSM = useStylesDSM();
  const clasPM = useStylesDPM();
  const clasBA = useStylesBA();
  const clasFu = useStylesFU();
  const clasCAB = useStylesCAB();
  const clasSN = useStylesSN();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clstoolbar = useStylestolb();
  const [gets, setGets] = useState(insh.tbname);
  const [idata, setIData] = useState(() => {
    return { ...insh.IdataInvs, date: moment(new Date()).format("YYYY-MM-DD") };
  }); //is data in ta
  //is data in ta
  const [allow, setAllow] = useState({ c: false, s: false });
  const [allowPaid, setAllowPaid] = useState({ c: false });
  /* Anemation */
  const [transition, setTransition] = React.useState(undefined);
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }
  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  const [isOpenSy, setIsOpenSy] = useState(false);

  const handleOpenSy = () => {
    setAllow({ ...allow, s: true });
    setIsOpenSy(!isOpenSy);
  }; // E rsa

  const filter = createFilterOptions();

  //rsa
  const [isOpenCustomer, setIsOpenCustomer] = useState(false);
  const [openPaidWindows, setOpenPaidWindows] = useState(false);

  const handleOpenCustomer = () => {
    setAllow({ ...allow, c: !isOpenCustomer });
    setIsOpenCustomer(!isOpenCustomer);
  }; // E rsa
  // s Dup
  const backHome = () => {
    his.push({ pathname: "/" });
    window.location.reload(false);
  };

  // s DDown
  const [openDw, setOpenDw] = useState(false);

  const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    // getInvs(
    //   dateFrom + " " + timeFrom,
    //   dateTo + " " + timeTo,
    //   null,
    //   sto.User_typei
    // );
    setOpenDw(true);
  };
  const handCloseDw = () => {
    setOpenDw(false);
  };

  const [subInfo, setSubInfo] = useState(insh.InfoSub);
  const [counts, setCounts] = useState({ curr: 0 });
  const [showPaid, setShowPaid] = useLocalStorage("show-paid-window", false);
  /* s DcuD  */
  const [openCuD, setOpenCuD] = React.useState(false);

  const [dialog, setDialog] = React.useState(inshF.dia);
  useEffect(() => {
    window.onbeforeunload = function (e) {
      e.preventDefault();
      return "you can not refresh the page";
    };
  }, []);

  const handleDia = (Transition, prop, state) => {
    setTransition(() => Transition);
    setDialog({ ...dialog, [prop]: state });
  };
  const handleClDia = (prop, state) => setDialog({ ...dialog, [prop]: state });
  const [openDwt, setOpenDwt] = useState(false);

  const handOpenDwt = (Transition) => {
    setTransition(() => Transition);
    getInvst(
      sto.User_typei
    );
    setOpenDwt(true);
  };
  const handCloseDwt = () => {
    setOpenDwt(false);
  };
  // let today = new Date();

  // const [dateFrom, setDateFrom]= useState(()=>{
  //   return  moment(today.setDate(today.getDate() - 1)).format("YYYY-MM-DD");    
  // });
  // const [dateTo, setDateTo]= useState(()=>{
  //   return  moment(today.setDate(today.getDate() + 1)).format("YYYY-MM-DD");
  // });

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const getInvst = async (utype) => {
    let today = moment(new Date()).format("YYYY-MM-DD");
    console.log("the data is", today)
    await axios
      .get(
        uri +
        "InvDatet" +
        `?id=${sto.empi}&&dateF=${today}&&dateT=${today}&&PcIdIvPc=${setting.PrTy_InPr}&&uType=${utype}&&bran=${sto.branchi}`
      )
      .then((res) => {
        cl("===res.data");
        cl(res.data);
        setInvsShowt(res.data);
        setInvsShowFltr(res.data);
      })
      .catch((error) => { });
  };
  /* s DcuD and McuD */
  const handCOpenCu = (Transition) => {
    setBarcodes("");
    // loadGetCountSafe();
    loadGetCountSafeByGroup();
    setTransition(() => Transition);
    setOpenCuD(true);
  };

  /* s PMD */
  const [openPMD, setOpenPMD] = useState(false);
  //const [typePress, setTypePress] = useState("save");
  let typePress = "save";
  let openPay = false;


  const handleClickOpenPMD = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };
  const handleClickOpenPaid = (type) => {
    typePress = type;
    // setValueBankPMD

    setOpenPMD(true);
  };
  const handClosePMD = async (state) => {
    if (state === "cancle") await restPM(state);
    await setOpenPMD(false);
  };
  /* e PMD */

  /* s BCSD */

  const [barcodes, setBarcodes] = useState("");
  const { t: transForm } = useTranslation(["forms"]);
  const _onDetected = async (result) => {
    const res = [];
    const item = currencies[barcodes_eds.indexOf(result)];
    res.push(item.units);

    if (res.length > 0) {
      await BefBarcodedataCurrencie(item.id, item.units);
      setIsBarcode(true);
    }
  };
  /* e BCSD */

  const [lbl, setLbl] = React.useState(insh.Lbl);
  const [switchState, setSwitchState] = React.useState(insh.SwitchState);


  const chashVSnetwork = (valChecked) => {

    if (valChecked == true && valueType.id != 1) {
      const text = transForm("network").toString();
      setLbl({ ...lbl, chashNetwork: text });
      setValueBank(gets.Banks[0]); //getAutobanks(0);
      setUiElement({ ...uiElement, bank: false });
      setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });

      setIData({ ...idata, pay_type: 2, bank: gets.Banks[0].id });
    } else if (valChecked == false && valueType.id != 1) {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({ ...uiElement, bank: true, stock: false });
      setIData({ ...idata, pay_type: 1, bank: 0 });
      setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

    } else if (parseFloat(payment.paidup) > 0 && valChecked == true && valueType.id == 1) {
      const text = transForm("network").toString();
      setLbl({ ...lbl, chashNetwork: text });
      setValueBank(gets.Banks[0]); //getAutobanks(0);
      setUiElement({ ...uiElement, bank: false });
      setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });
      setIData({ ...idata, pay_type: -1, bank: gets.Banks[0].id, cash_part: payment.paidup, agel_part: payment.stay, network_part: 0 });

    }
    else if (parseFloat(payment.paidup) > 0 && valChecked == false && valueType.id == 1) {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({ ...uiElement, bank: true });
      setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });
      setIData({ ...idata, pay_type: -1, bank: gets.Banks[0].id, cash_part: payment.paidup, agel_part: payment.stay, network_part: 0 });

    }
  };
  const storgeingprem = async (info) => {
    const tb = {
      PermissionApp: true,
      wait: true,
      /*params*/
      parms: {
        iuserid: premiA.user_id,
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        localStorage.setItem("premiApp", JSON.stringify(res.data.permisApp));
        setSwitchState({
          ...switchState,
          stayInDCurr: res.data.permisApp.stayInDCurr,
        });
      })
      .catch((error) => { });
  };

  const updatePrem = async (event) => {
    premiA.stayInDCurr = event;
    await axios
      .put(uri + "PermissionApp", premiA)
      .then((res) => {
        storgeingprem(res.data);
      })
      .catch((error) => { });
  };

  const changClick = (prop, ev) => {
    if (prop == "minus") setUiElement({ ...uiElement, [prop]: ev, rate: true });
    else if (prop == "rate")
      setUiElement({ ...uiElement, [prop]: ev, minus: true });
  };

  const changSwitch = (name) => (event) => {
    setSwitchState({ ...switchState, [name]: event.target.checked });

    if (name == "stayInDCurr") updatePrem(event.target.checked);
    else if (name == "cashORnetworksw") chashVSnetwork(event.target.checked);
  };

  const handleCloseCuD = () => {
    setCurrUnit([]);
    setOpenCuD(false);
    setSubInfo(insh.InfoSub);
  };

  const handleCloseCuDWithSWState = () => {
    switchState.stayInDCurr ? setOpenCuD(true) : setOpenCuD(false);
    setSubInfo(insh.InfoSub);
  };

  const changChckedPM = (network, agel, bank, emp, onlyCheked) => {
    onlyCheked === ""
      ? setChecked({
        ...checked,
        network_partck: network,
        agel_partck: agel,
        bank_partck: bank,
        emp_partck: emp,
      })
      : setUiElement({ ...uiElement, [onlyCheked]: false });
  };

  const changAutosPM = (bank, bank2, emp, onlyCheked) => {
    if (onlyCheked === "") {
      if (bank === false) setValueBankPMD(insh.AutoBank);
      if (bank2 === false) setValueBankTransPMD(insh.AutoBank);
      if (emp === false) setValueEmployePMD(insh.Auto);
      setUiElement({
        ...uiElement,
        bankPM: !bank,
        bankPM2: !bank2,
        empPM: !bank,
      });
    } else {
      if (onlyCheked === "bankPM") setValueBankPMD(insh.AutoBank);
      else if (onlyCheked === "bankPM2") setValueBankTransPMD(insh.AutoBank);
      else if (onlyCheked === "empPM") setValueEmployePMD(insh.Auto);
      setUiElement({ ...uiElement, [onlyCheked]: true });
    }
  };

  const restPM = (from) => {
    let ck = { network: true, agel: true, bank: true, emp: true };
    if (from === "rest") {
      if (idata.network_part === "") ck.network = false;
      if (idata.agel_part === "") ck.agel = false;
      if (idata.bank_part === "") ck.bank = false;
      if (idata.emp_part === "") ck.emp = false;
    } else {
      ck.network = false;
      ck.agel = false;
      ck.bank = false;
      ck.emp = false;
    }
    changChckedPM(ck.network, ck.agel, ck.bank, ck.emp, "");
    changAutosPM(ck.network, ck.bank, ck.emp, "");
  };
  const [or, setOr] = useState(insh.Or);
  const [ished, setIshed] = useState(insh.ished);


  const [permiElm, setPermiElm] = useState(insh.permiElm);
  const [permiCuCo, setPrmiCuCo] = useState({});

  const getPrmetions = (resDataElm, permiCuCo) => {
    setPermiElm(resDataElm);
    getAutoPricePays(permiCuCo.TSP);
    if (resDataElm.USP) {
      nevEve = "never";
    }

    setPrmiCuCo(permiCuCo);
  };

  const [uiElement, setUiElement] = useState({});

  // >>>>> datas
  const [currencies, setCurrencies] = useState([]);
  const [invsShow, setInvsShow] = useState([]);
  const [invsShowt, setInvsShowt] = useState([]);
  const [exsub, setExSub] = useState(0);
  const [invsShowFltr, setInvsShowFltr] = useState([]);

  const [infoInv, setInfoInv] = useState({ id: "", InvId_Return: "" });

  const [isdata, setIsData] = useState([]); //is data in ta

  const location = useLocation();

  const [stor, setStor] = useState({}); //is data in ta
  const [storS, setStorS] = useState({}); //is data in ta

  const [favGroups, setFavGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsSub, setGroupsSub] = useState([]);

  // Auto

  const [auto, setAuto] = useState(insh.auto);

  const [valueType, setValueType] = useState(insh.Auto);

  //TypeInvs
  const [valueTypeFltr, setValueTypeFltr] = useState(insh.Auto);
  //Safes
  const [valueSafes, setValueSafes] = useState(insh.Auto);
  //Customers
  const [optionsCustomers, setOptionsCustomers] = useState(insh.Auto);
  const [valueCustomers, setValueCustomers] = useState(insh.Auto);
  //Salesmen
  const [valueSalesmen, setValueSalesmen] = useState(insh.Auto);
  //Employee
  const [valueEmployePMD, setValueEmployePMD] = useState(insh.Auto);
  //Costcenter
  const [valueCostcenter, setValueCostcenter] = useState(insh.Auto);
  //Stock
  const [valueStock, setValueStock] = useState(insh.Auto);
  const [omlaValue, setOmlaValue] = useState(insh.Auto);
  // network
  const [valueBank, setValueBank] = useState(insh.AutoBank);
  // network PMD
  const [valueBankPMD, setValueBankPMD] = useState(insh.AutoBank);
  //Bank Trans PMD
  const [valueBankTransPMD, setValueBankTransPMD] = useState(insh.AutoBank);
  //Units

  const [countCurrBySafe, setCountCurrBySafe] = useState([]);
  const [currenciesToInv, setCurrenciesToInv] = useState([]);
  const [currUnit, setCurrUnit] = useState([]);
  const [currUnitsAll, setCurrUnitsAll] = useState([]);
  const [optionsCurrUnits, setOptionsCurrUnits] = useState(insh.AutoOp);
  const [valueCurrUnits, setValueCurrUnits] = useState(insh.Auto);
  const [indexUnit, setIndexUnit] = useState(0);
  //PricePay
  const [optionsPricePay, setOptionsPricePay] = useState(insh.AutoOp);
  const [valuePricePay, setValuePricePay] = useState(insh.Auto);
  // edrees changes
  const [itemId, setItemId] = useState("1");
  const [barcodes_eds, setBarcodes_eds] = useState();

  const handleFocus = (e) => e.target.select();
  const getAutoPricePays = (i) => {
    setOptionsPricePay(insh.optionPricePay);
    setValuePricePay(insh.optionPricePay[i]);
  };

  const addChangds = () => {
    // تعديل تاريخ فانورة المرتجع بتاريخ اليوم تم تعليقها لعدم امكانية التعديل
    // if (setting.PrTy_InPr == "12" || setting.PrTy_InPr == "11")
    //   idata.date =
    //     moment(new Date()).format("YYYY-MM-DD") +
    //     moment(new Date()).format(" h:mm A");
    // else
    idata.date =
      moment(idata.date).format("YYYY-MM-DD") +
      moment(new Date()).format(" h:mm A");
    idata.InvId_Return = infoInv.InvId_Return;
  };



  const fltringCurrencieToinv = async (res, pricePayId, changtaxperc) => {
    console.log("the response data is", res);
    if (setting.PrTy_InPr == "22") res.tax = 0;
    let sORp = 0;
    if (setting.cusType == 1) sORp = res.sale;
    else if (setting.cusType == 2) sORp = res.purch;
    if (changtaxperc == false) {
      res.tax = subInfo.taxperc;
      res.val1 = subInfo.val1;
    }
    if (pricePayId == 2) sORp = res.dPrice;
    else if (pricePayId == 3) sORp = res.jPrice;

    if (checked.autoOrStaticck === false) {
      setSubInfo({
        ...subInfo,
        hdshw: true,
        val1: res.val1,
        is_tab3_curr: res?.is_tab3_curr,
        id: res.id,
        minPrice: res.mPrice,
        Price: sORp,
        /*PricePu:res.purch,*/ barcode: res.barcode,
        symbol: res.symbol,
        type: res.type,
        perc: res.perc,
        taxperc: res.tax,
        totJust: (sORp * res.val1).toFixed(2),
        discount: "",
        DiscPers: "",
        taxval: ((sORp * res.tax) / 100).toFixed(2),
      });
    } else {
      subInfo.hdshw = true;
      subInfo.val1 = res.val1;
      subInfo.id = res.id;
      subInfo.Price = sORp;
      subInfo.minPrice = res.mPrice;
      subInfo.barcode = res.barcode;
      subInfo.symbol = res.symbol;
      subInfo.perc = res.perc;
      subInfo.taxperc = res.tax;
      subInfo.totJust = (sORp * res.val1).toFixed(2);
      subInfo.discount = "";
      subInfo.DiscPers = "";
      subInfo.type = res.type;
      subInfo.taxval = ((sORp * res.tax) / 100).toFixed(2);
      subInfo.is_tab3_curr = res.is_tab3_curr;
    }
  };
  const changCurrencieToinv = async (valuePrice, changtaxperc) => {
    const res = [];
    currUnit.map((x) => {
      if (x.barcode == subInfo.barcode) res.push(x);
    });
    let sORp = 0;

    if (setting.cusType === 1) sORp = res[0].sale;
    else if (setting.cusType === 2) sORp = res[0].purch;

    if (changtaxperc === false) {
      res[0].tax = subInfo.taxperc;
      res[0].val1 = subInfo.val1;
    }
    if (valuePrice === 2) sORp = res[0].dPrice;
    else if (valuePrice === 3) sORp = res[0].jPrice;

    setSubInfo({
      ...subInfo,
      hdshw: true,
      val1: res[0].val1,
      id: res[0].id,
      minPrice: res[0].mPrice,
      Price: sORp,
      /*PricePu:res[0].purch,*/ barcode: res[0].barcode,
      symbol: res[0].symbol,
      perc: res[0].perc,
      taxperc: res[0].tax,
      totJust: sORp * res[0].val1,
      discount: "",
      DiscPers: "",
      taxval: (sORp * res[0].tax) / 100,
    });
    // }).catch(error => {  })
  };

  const getSubGroups = async (id) => {
    // alert(id)
    const res = [];
    locStos.groupsSub.map((x) => {
      if (x.i == id) res.push(x);
    });
    setGroupsSub(res);
  };

  const reLoadLastInv = async () => {
    const tb = {
      other: true,
      parms: {
        sProcTypeInvProc: setting.PrTy_InPr,
        branch: sto.branchi,
        tname: "invs",
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setInfoInv({ id: res.data.other.lastid, InvId_Return: "" });
      })
      .catch((error) => { });
  };
  const getSafeDefault = (user_id, Users) => {
    const user = Users.find((us) => us.id === user_id);
    const safe = user.SafeEmp.find((safe) => safe.isDefault);
    if (safe != null) {
      return safe;
    }
    return user.SafeEmp[0];
  };

  const loadSto = async (cusType) => {
    const locSto = getLS("sto", {});
    setGets(locSto);
    console.log("the log sto is", locSto);
    // eds changed
    setBarcodes_eds(locSto.Currencies.map((c) => c.barcode));
    /* go Func we need check Condsh */
    let valCust = cusType == 1 ? locSto.CustomersA[0] : locSto.CustomersM[0];

    loadIdata(valCust, locSto.types, locSto?.Banks);

    // const safe = getSafeDefault(locSto.permisApp.user_id, locSto.Users);

    setValueSafes(locSto.Safes[0]);
    //setValueStock(locSto.Stocks[0]);
    const defOmla = getDefaultOmla(locSto.omlats);
    setOmlaValue(defOmla);
    setOptionsCustomers(cusType == 1 ? locSto.CustomersA : locSto.CustomersM);
    setValueCustomers(valCust);
    setCurrencies(locSto.Currencies);

    setcuRows(
      locSto.Currencies?.map((c) => {
        return { ...c, sale_price: c.sale_price * defOmla.exchange };
      })
    );
    setGroups(locSto.groups);
    setFavGroups(locSto.CurrenciesFav);
    setCurrenciesToInv(locSto.CurrenciesToInv);
    const inveTypeIndex = getInveTypeIndex(valCust.Cust_inv_type);

    //setValueType(locSto.types[inveTypeIndex]);
    setValueTypeFltr(locSto.typesFltr[0]);
    getCustInvType(valCust);
  };


  const loadGet = async (branch, pTpI, empid, formid, invType) => {
    const tb = {
      other: true,
      wait: true,
      PermissionsElemnts: true,
      PermissionsCuCo: true,
      PermissionsCuCoPurch: invType == 3 || invType == 4,
      // params
      parms: {
        tname: "invs",
        branch: branch,
        sProcTypeInvProc: pTpI,
        iuserid: empid,
        iformid: formid,
        iformidCuCo: dataF[3].id /* for curr and yashml */,
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setInfoInv({ ...infoInv, id: res.data.other.lastid });
        getPrmetions(res.data.permisElm, res.data.permiCuCo);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        setIsApiConnect(false);
      });

    //setUiElement(insh.UiElement);
  };

  

  const reloadGet = async () => {
    const tb = {
      CustomersWTypes: true,
      wait: true,
      parms: {
        icustmtype: location.state.setting.cusType,
      },
      autos: {},
    };

    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setOptionsCustomers(res.data.Customers);
        setValueCustomers(res.data.Customers[0]);
      })
      .catch((error) => {
        /*console.log('no');*/
      });
  };

  const loadGetCountSafe = async () => {
    const tb = {
      wait: true,
      inRunTime: true,
      parms: {
        iuserid: idata.create_user,
        iformid: dataF[47].id,
      },
      autos: {},
    };

    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        setCountCurrBySafe(res.data.countCurrBySafe);
        setPermiElm({ ...permiElm, SIM: res.data.permisElSinM });
      })
      .catch((err) => { });
  };

  const loadGetCountSafeByGroup = async () => {
    const urlts =
      uri +
      `TaswyaBasicData/countCurrBySafe?safe=${valueSafes.id}
      &&branch=${sto.branchi}`;
    await axios
      .get(urlts)
      .then((res) => {
        // console.log("the sge is ", res.data);
        setCountCurrBySafe(res.data);
        // isus
        if (!permiElm.hasOwnProperty("SIM"))
          setPermiElm({ ...permiElm, SIM: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reGet = async (takei, igroupid, sSearch) => {
    if (sSearch != "" || igroupid != -1 || takei == -2) {
      takei != -1
        ? setAuto({ ...auto, group: "", groupSub: "" })
        : setSearchedCurrShow(sSearch);

      // alert(igroupid)
      const tb = {
        Currencies: true, // wait:true,
        parms: {
          scurrenciestype: "I",
          iempid: sto.empi,
          // currShow:"D",
          safe: valueSafes.id,
          currShow: "M",
          currType: stor.invsType > 2 ? -1 : 2,
          igroupid: igroupid,
          takei: -1,
          sSearch: sSearch,
        },
        autos: {},
      };
      await axios
        .post(uri + "Res", tb)
        .then((res) => {
          setcuRows(res.data.Currencies);
        })
        .catch((error) => {
          /*console.log('no');*/
        });
    }
  };

  // const location = useLocation();
  const [setting, setSetting] = useState({});
  const [style, setStyle] = useState({});

  const loadInsh = async () => {
    setSetting(location.state.setting);

    // setIData(l_ocation.state.idata);
    setStor(location.state.stor);
    loadGet(
      location.state.idata.branch,
      location.state.setting.PrTy_InPr,
      location.state.idata.create_user,
      location.state.setting.iformid,
      location.state.stor.invsType
    );
    loadSto(location.state.setting.cusType);
    setStorS(location.state.setting.stors);

    setStyle(location.state.style);
  };





  let setupReload = function () {
    // for update date in midnight
    const timeUntilMidnight = moment()
      .endOf("day")
      .add(10, "ms")
      .diff(moment(), "ms");
    return setTimeout(function () {
      setIData({ ...idata, date: moment(new Date()).format("YYYY-MM-DD") });

      setupReload();
    }, timeUntilMidnight);
  };

  /* =============================== */
  useEffect(() => {
    loadInsh();
    setupReload();
  }, []);

  /* =============================== */

  const toDoIdata = (prop, value) => {
    setIData({ ...idata, [prop]: value });
  };
  const reInshAuto = (id, name) => {
    if (id == null && name == "") return insh.Auto;
    else return { id: id, name: name };
  };

  const loadIdata = (valCust, locStoTypes, Banks) => {
    let locR = getLS("DataRem", {});
    const inveTypeIndex = getInveTypeIndex(valCust.Cust_inv_type);

    setIData({
      ...location.state.idata,
      branch: locR.branchi,
      sales_emp: locR.empi,
      create_user: locR.idi,
      customer_name: valCust.name,
      cust_id: valCust.id,
      inv_type: locStoTypes[inveTypeIndex].id,
      //eds change



      date: moment(new Date()).format("YYYY-MM-DD"),
    });
    // eds change
  };

  const getCustInvType = (valCust) => {
    setUiElement(insh.UiElement);

    let kind_inv = 2;
    let locR = getLS("DataRem", {});

    const locSto = getLS("sto", {});



    if (valCust.Cust_inv_type != null) {
      if (valCust.Cust_inv_type == 0 || valCust.Cust_inv_type == 2) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: true,
        });
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);

        setPayment({ ...payment, paidup: 0, stay: 0 });

        setValueStock(locSto.Stocks[0]);
        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });
        setIData({
          ...location.state.idata, inv_type: 2,
          pay_type: 1,
          bank: 0, cust_id: valCust.id,
          stock: locSto.Stocks[0].id,
          customer_name: valCust.name,
          branch: locR.branchi,
          sales_emp: locR.empi,
          create_user: locR.idi,
          date: moment(new Date()).format("YYYY-MM-DD")
        });
        //setOptionsBank(insh.AutoBankOp);
        setValueType(locSto.types[1]);


      } else if (valCust.Cust_inv_type == 1) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: true,
          bank: true,
        });
        const reetS = reInshAuto(locSto.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...location.state.idata,
          inv_type: 1, pay_type: -1, bank: -1, cust_id: valCust.id,
          customer_name: valCust.name,
          branch: locR.branchi,
          sales_emp: locR.empi,
          create_user: locR.idi,
          date: moment(new Date()).format("YYYY-MM-DD")
        });
        setValueType(locSto.types[0]);

      }
      else if (valCust.Cust_inv_type == 3 || valCust.Cust_inv_type == 4) {
        setUiElement({
          ...uiElement,
          stock: false,
          payMethod: false,
          bank: false,
        });
        setValueStock(locSto.Stocks[0]);
        setValueBank(locSto.Banks[0]); //getAutobanks(0);
        setPayment({ ...payment, paidup: 0, stay: 0 });
        setSwitchState({ ...switchState, cashORnetworksw: true });
        const text = transForm("network").toString();
        setLbl({ ...lbl, chashNetwork: text });


        setIData({
          ...location.state.idata, inv_type: 2, pay_type: 2,
          branch: locR.branchi,
          sales_emp: locR.empi,
          create_user: locR.idi,
          bank: locSto.Banks[0].id, cust_id: valCust.id,
          customer_name: valCust.name, date: moment(new Date()).format("YYYY-MM-DD")
        });
        setValueType(locSto.types[1]); //getAutobanks(0);
      }
    } else {
      setValueStock(locSto.Stocks[0]); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({
        ...uiElement,
        stock: false,
        payMethod: false,
        bank: true,
      });
      setSwitchState({ ...switchState, cashORnetworksw: false });
      setIData({
        ...location.state.idata, inv_type: 2,
        branch: locR.branchi,
        stock: locSto.Stocks[0].id,
        sales_emp: locR.empi,
        create_user: locR.idi,
        pay_type: 1, bank: 0, cust_id: valCust.id,
        customer_name: valCust.name, date: moment(new Date()).format("YYYY-MM-DD")
      });

      setValueType(locSto.types[1]);

    }
  }


  const changRelationAutos = (action, newValue) => {

    console.log("action");
    console.log(action);
    console.log("newValue");
    console.log(newValue);
    setSwitchState({ ...switchState, cashORnetworksw: false });
    setLbl({ ...lbl, chashNetwork: transForm("cash") });

    let invT = null;

    if (action == "cust") {

      setValueCustomers(newValue);
      newValue.Cust_inv_type == 1
        ? setValueType(gets.types[0])
        : setValueType(gets.types[1]);
      newValue.Cust_inv_type == 1
        ? (invT = gets.types[0].id)
        : (invT = gets.types[1].id);
    } else if (action == "type") {
      setValueType(newValue);
      invT = newValue.id;
    }

    if (invT == 2) {
      if (action == "cust") {

        if (newValue.Cust_inv_type != null) {
          if (newValue.Cust_inv_type == 0 || newValue.Cust_inv_type == 2 || newValue.Cust_inv_type == 5) {
            setUiElement({
              ...uiElement,
              stock: false,
              payMethod: false,
              bank: true,
            });
            remains.cashPart = idata.netAmount;
            remains.agelPart = 0;
            remains.networkPart = 0;
            remains.bankPart = 0;
            setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
            setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });


            const reet = reInshAuto(0, "");
            setValueBank(reet);
            setValueStock(gets.Stocks[0]); // getAutoStocks(0);
            setSwitchState({ ...switchState, cashORnetworksw: false });
            setLbl({ ...lbl, chashNetwork: transForm("cash") });

            setIData({
              ...idata,
              pay_type: 1,
              bank: 0,
              inv_type: invT,
              customer_name: newValue.name,
              cust_id: newValue.id,
            });
          } else if (newValue.Cust_inv_type == 3 || newValue.Cust_inv_type == 4) {

            const text = transForm("network").toString();
            setLbl({ ...lbl, chashNetwork: text });
            setValueBank(gets.Banks[0]); //getAutobanks(0);
            setUiElement({ ...uiElement, bank: false });
            setSwitchState({ ...switchState, cashORnetworksw: true });
            setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: idata.netAmount });
            setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
            setIData({
              ...idata,
              pay_type: 2,
              bank: gets.Banks[0].id,
              inv_type: invT,
              customer_name: newValue.name,
              cust_id: newValue.id,
            });
          }
        }
        else {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });

          setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
          setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });

          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(gets.Stocks[0]); // getAutoStocks(0);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });

          setIData({
            ...idata,
            pay_type: 1,
            bank: 0,
            inv_type: invT,
            customer_name: newValue.name,
            cust_id: newValue.id,
          });
        }
      }
      else if (action == "type") {
        if (switchState.cashORnetworksw == false) {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });

          setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
          setRemains({ ...remains, cashPart: idata.netAmount, agelPart: 0, bankPart: 0, networkPart: 0 });

          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(gets.Stocks[0]); // getAutoStocks(0);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });

          setIData({ ...idata, pay_type: 1, bank: 0, cust_id: valueCustomers.id, customer_name: valueCustomers.name, inv_type: invT });
        }
        else if (switchState.cashORnetworksw == true) {
          setUiElement({ ...uiElement, bank: false });
          setPayment({ ...payment, paidup: idata.netAmount, stay: 0 });
          setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: idata.netAmount });

          setValueStock(gets.Stocks[0]); // getAutoStocks(0);
          setSwitchState({ ...switchState, cashORnetworksw: true });
          setLbl({ ...lbl, chashNetwork: transForm("network") });
          setValueBank(gets.Banks[0]); //getAutobanks(0);
          setIData({
            ...idata,
            pay_type: 2,
            bank: gets.Banks[0].id,
            cust_id: valueCustomers.id, customer_name: valueCustomers.name,
            inv_type: invT,
          });
        }
      }


    }
    else if (invT == 1) {

      if (payment.paidup > 0 && payment.stay > 0) {


        if (switchState.cashORnetworksw == false) {
          remains.cashPart = payment.paidup;
          remains.agelPart = payment.stay;
          remains.networkPart = 0;
          remains.bankPart = 0;
          setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

          if (action == "cust") {
            setIData({
              ...idata,
              pay_type: -1,
              bank: -1,
              stock: valueStock.id,
              cash_part: payment.paidup,
              Pay_mouny: payment.paidup,
              Remaining: payment.stay,
              network_part: "",
              agel_part: payment.stay,
              emp_part: "",
              bank_part: "",
              check_refno: "",
              netAmountPMDcn: idata.netAmount,
              customer_name: newValue.name, cust_id: newValue.id, inv_type: invT,
            });
          } else {
            setIData({
              ...idata,
              pay_type: -1,
              bank: -1,
              stock: valueStock.id,
              cash_part: payment.paidup,
              Pay_mouny: payment.paidup,
              Remaining: payment.stay,
              network_part: "",
              agel_part: payment.stay,
              emp_part: "",
              bank_part: "",
              check_refno: "",
              netAmountPMDcn: idata.netAmount,
              cust_id: valueCustomers.id, customer_name: valueCustomers.name
            });
          }
        }
        else if (switchState.cashORnetworksw == true) {
          setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: payment.paidup });
          // remains.cashPart = 0;
          // remains.agelPart = payment.stay;
          // remains.networkPart = payment.paidup;
          // remains.bankPart = 0;

          if (action == "cust") {
            setIData({
              ...idata,
              pay_type: -1,
              bank: valueBank.id,
              cash_part: "",
              Pay_mouny: payment.paidup,
              Remaining: payment.stay,
              network_part: payment.paidup,
              agel_part: payment.stay,
              emp_part: "",
              bank_part: "",
              check_refno: "",
              netAmountPMDcn: idata.netAmount,
              customer_name: newValue.name, cust_id: newValue.id, inv_type: invT,
            });
          }
          else {
            setIData({
              ...idata,
              pay_type: -1,
              bank: valueBank.id,
              cash_part: " ",
              Pay_mouny: payment.paidup,
              Remaining: payment.stay,
              network_part: payment.paidup,
              agel_part: payment.stay,
              emp_part: "",
              bank_part: "",
              check_refno: "",
              netAmountPMDcn: idata.netAmount,
              inv_type: invT,
              cust_id: valueCustomers.id, customer_name: valueCustomers.name
            });
          }
          // setValueBankPMD(valueBank); //setOptionsBankPMD(optionsBank);

        }

      } else if (payment.paidup > 0 && payment.stay == 0) {
        setUiElement({ ...uiElement, stock: false, payMethod: true, bank: true });
        setPayment({ ...payment, paidup: 0, stay: idata.netAmount });

        setRemains({ ...remains, cashPart: 0, agelPart: payment.stay, bankPart: 0, networkPart: 0 });
        // remains.cashPart = 0;
        // remains.agelPart = idata.netAmount;
        // remains.networkPart = 0;
        // remains.bankPart = 0;

        const reetS = reInshAuto(gets.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);

        if (action == "cust") {
          setIData({
            ...idata,
            pay_type: -1,
            bank: -1,
            inv_type: invT,
            customer_name: newValue.name,
            cust_id: newValue.id,
          });
        } else setIData({
          ...idata, pay_type: -1, bank: -1, inv_type: invT
        });
      }
      else {
        setUiElement({ ...uiElement, stock: false, payMethod: true, bank: true });
        setPayment({ ...payment, paidup: 0, stay: idata.netAmount });

        setRemains({ ...remains, cashPart: payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 });

        // remains.cashPart = payment.paidup;
        // remains.agelPart = payment.stay;
        // remains.networkPart = 0;
        // remains.bankPart = 0;

        const reetS = reInshAuto(gets.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);

        if (action == "cust") {
          setIData({
            ...idata,
            pay_type: -1,
            bank: -1,
            inv_type: invT,
            customer_name: newValue.name,
            cust_id: newValue.id,
            agel_part: payment.stay,
            Remaining: payment.stay,
            cash_part: payment.paidup
          });
        } else setIData({
          ...idata, pay_type: -1, bank: -1, inv_type: invT
          , agel_part: payment.stay, Remaining: payment.stay,
          cash_part: payment.paidup
        });
      }
    }

    // else if (invT == 1) {

    // }

    console.log(" the inv_type is", invT);
  };



  const changAutoType = (newValue) => {
    changRelationAutos("type", newValue);
  };

  const changAutoss = async (e, prop) => {
    switch (prop) {
      case "group":
        await reGet(-1, e, "");
        setAuto({ ...auto, [prop]: e, groupSub: "" });
        getSubGroups(e);
        break;
      case "groupSub":
        reGet(-1, e, "");
      default:
        setAuto({ ...auto, [prop]: e });
    }
  };

  const changAutoCustomers = (newValue) => changRelationAutos("cust", newValue);

  const changAutoSalesMen = (newValue) => {
    setValueSalesmen(newValue);
    toDoIdata("salesman", newValue.id);
  };

  const changAutoEmployee = (newValue) => {
    setValueEmployePMD(newValue);
    toDoIdata("emp", newValue.id);
  };

  const changAutoSafes = (newValue) => {
    setValueSafes(newValue);
    toDoIdata("safe", newValue.id);
  };
  const changAutoStock = (newValue) => {
    setValueStock(newValue);
    toDoIdata("stock", newValue.id);
  };
  const changAutoOmla = (newValue) => {
    setOmlaValue(newValue);
    toDoIdata("omla", newValue.id);
    // setcuRows(gets.Currencies?.map(c=>{
    //   return {...c, sale_price: c.sale_price * newValue.exchange }
    // }));
  };
  const changAutoCostcenter = (newValue) => {
    setValueCostcenter(newValue);
    toDoIdata("center", newValue.id);
  };
  const changAutoBank = (newValue) => {
    setValueBank(newValue);
    toDoIdata("bank", newValue.id); /*setValueBankPMD(newValue);*/
  };
  const changAutoBankPMD = (newValue) => {
    setValueBankPMD(newValue);
    toDoIdata("bank2", newValue.id);
  };
  const changAutoBank2 = (newValue) => {
    setValueBankTransPMD(newValue);
    toDoIdata("bank2", newValue.id);
  };

  const changAutoPricePay = (newValue) => {
    setValuePricePay(newValue);
    insh.stateChang.taxperc = false;
    setValueCurrUnits(optionsCurrUnits[0]);
    LetvalueCurrUnits = optionsCurrUnits[0];
    currUnit.length > 0 &&
      changCurrencieToinv(newValue.id, insh.stateChang.taxperc);
  };

  const changAutoCurrUnits = (op, newValue) => {
    setIndexUnit(op.nativeEvent.target.dataset.optionIndex);
    setValueCurrUnits(newValue);
    LetvalueCurrUnits = newValue;
    insh.stateChang.taxperc = false;
    if (newValue.IS_Default === false) {
      // /*statuDis.pricePay=true;*/ permiCuCo.DTSP = true;
    } else {
      // /*statuDis.pricePay=false;*/ permiCuCo.DTSP = false;
    }

    setValuePricePay(insh.optionPricePay[permiCuCo.TSP]);

    fltringCurrencieToinv(
      currUnitsAll[op.nativeEvent.target.dataset.optionIndex],
      insh.optionPricePay[0].id,
      insh.stateChang.taxperc
    );
  };
  const [cuRows, setcuRows] = useState({});

  const currShowSearch = (e) => {
    setSearchedCurrShow(e.target.value);

    if (e.target.value == "") {
      const locSto = JSON.parse(localStorage.getItem("sto")) || {};
      setcuRows(locSto.Currencies);
      setAuto({ ...auto, group: "", groupSub: "" });
      setGroupsSub([]);
    }
  };
  const [searchedInvShow, setSearchedInvShow] = useState("");
  const [searchedCurrShow, setSearchedCurrShow] = useState("");

  // fillter groups

  const changFavSlides = (val) => {
    const res = gets.CurrenciesFav.filter((x) => x.favorite === val);
    setFavGroups(res);
  };

  const changSlides = (e, t) => {
    if (t === "Gmain") getSubGroups(e);

    const filteredCuRows = gets.Currencies.filter((row) => {
      return (
        row.symbol.toLowerCase().includes(e) ||
        row.nameG.toLowerCase().includes(e) ||
        row.barcode.toLowerCase().includes(e) ||
        row.sale_price.toString().toLowerCase().includes(e)
      );
    });
    setcuRows(filteredCuRows);
  };
  const [checked, setChecked] = useState(insh.Checked);
  const RestAfterGetCurrMathRate = () => {
    setInvDisc(insh.InvDisc);
    afterGetCurrMathRate("");
  };
  const changChcked = (event) => {
    if (
      event.target.name === "discMinusck" ||
      event.target.name === "discRateck"
    ) {
      RestAfterGetCurrMathRate();

      if (event.target.name === "discMinusck") {
        setChecked({
          ...checked,
          discMinusck: event.target.checked,
          discRateck: false,
        });
        setUiElement({ ...uiElement, rate: true, minus: false });
      } else if (event.target.name === "discRateck") {
        setChecked({
          ...checked,
          discMinusck: false,
          discRateck: event.target.checked,
        });
        setUiElement({ ...uiElement, rate: false, minus: true });
      }
    }

    if (event.target.name === "networkck" || event.target.name === "cashck") {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setValueBankPMD(insh.AutoBank); //setOptionsBankPMD(insh.AutoBankOp);

      if (event.target.name === "networkck") {
        setChecked({ ...checked, networkck: true, cashck: false });
        setUiElement({ ...uiElement, bank: false });
        setValueBank(gets.Banks[0]); //getAutobanks(0);
        setValueBankPMD(gets.Banks[0]); //getAutobanksPMD(0);
      } else if (event.target.name === "cashck") {
        setChecked({ ...checked, networkck: false, cashck: true });
        setUiElement({ ...uiElement, bank: true });
        // setI_Data({...idata, pay_type:1 });
      }
    }

    /* ======== for payment Diloag ======== */
    if (event.target.name == "paymentDiloagck") {
      setChecked({ ...checked, paymentDiloagck: event.target.checked });
    } else if (event.target.name == "network_partck") {
      setChecked({
        ...checked,
        network_partck: event.target.checked,
        cash_partck: true,
      });
      if (event.target.checked === true) {
        setValueBankPMD(gets.Banks[0]);
        /*getAutobanksPMD(0);*/ changChckedPM("", "", "", "", "bankPM");
        chashVSnetwork(true);
        setUiElement({ ...uiElement, bankPM: false });
      } else if (event.target.checked === false) {
        MathChckedIdata("network_part", idata.network_part);
        changAutosPM("", "", "", "bankPM");
        chashVSnetwork(false);
        setUiElement({ ...uiElement, bankPM: true });
      }
    } else if (event.target.name === "agel_partck") {
      setChecked({
        ...checked,
        agel_partck: event.target.checked,
        cash_partck: true,
      });
      if (event.target.checked === false) {
        MathChckedIdata("agel_part", idata.agel_part);
      }
    } else if (event.target.name === "bank_partck") {
      setChecked({ ...checked, bank_partck: event.target.checked });
      if (event.target.checked === true) {
        setValueBankTransPMD(gets.Banks[0]);
        /*getAutobanks2PMD(0);*/ changChckedPM("", "", "", "", "bankPM2");
      } else if (event.target.checked === false) {
        MathChckedIdata("bank_part", idata.bank_part);
        changAutosPM("", "", "", "bankPM2");
      }
    } else if (event.target.name === "emp_partck") {
      setChecked({ ...checked, emp_partck: event.target.checked });
      if (event.target.checked === true) {
        setValueEmployePMD(gets.Employees[0]);
        /*getAutoEmployePMD(0);*/ changChckedPM("", "", "", "", "empPM");
      } else if (event.target.checked === false) {
        MathChckedIdata("emp_part", idata.emp_part);
        changAutosPM("", "", "", "empPM");
      }
    } else if (event.target.name === "autoOrStaticck") {
      setChecked({ ...checked, autoOrStaticck: event.target.checked });
    }
  }; // end checked
  const fltringAutoCurrUnits = (chitem) => {
    setIndexUnit(0);
    const res = [];
    setCurrUnitsAll(chitem);
    if (checked.autoOrStaticck == false) {
      setOptionsCurrUnits(chitem);
      setValueCurrUnits(chitem[0]);
      LetvalueCurrUnits = chitem[0];
    } else LetvalueCurrUnits = chitem[0];
  };
  const BefBarcodedataCurrencie = async (id, ious) => {
    setItemId(id);
    setCurrUnit(ious);
    // permiCuCo.DTSP = false;
    await fltringAutoCurrUnits(ious);
    insh.stateChang.taxperc = true;
    await fltringCurrencieToinv(
      ious[0],
      valuePricePay.id,
      insh.stateChang.taxperc
    );
    // eds change
    await changeCountCurr(ious[0].barcode);
  };

  const BefrowDataCurrencie = async (id, ious) => {
    setItemId(id);

    setCurrUnit(ious);
    // permiCuCo.DTSP = false;
    await fltringAutoCurrUnits(ious);
    insh.stateChang.taxperc = true;
    await fltringCurrencieToinv(
      ious[0],
      valuePricePay.id,
      insh.stateChang.taxperc
    );
    await changeCountCurr(ious[0].barcode);
  };

  const changeCountCurr = (bc) => {
    try {
      const resCurr = [];
      countCurrBySafe.map((x) => {
        if (x.barcode == bc && x.safe == valueSafes.id) resCurr.push(x);
      });
      if (checked.autoOrStaticck === false) {
        resCurr.length != 0
          ? setCounts({ curr: resCurr[0].Qtys })
          : setCounts({ curr: 0 });
      } else {
        resCurr.length != 0
          ? (counts.curr = resCurr[0].Qtys)
          : (counts.curr = 0);
      }
    } catch (error) {
      alert("هناك خطاء في الصنف قم برماجعة الدعم الفني");
    }
  };

  const retPrmiSDPandSDV = (val, prmPandV, f) => {
    if (val > prmPandV && f === "DiscP") {
      alert(`
قيمة الخصم المدخله أكبر من قيمة الخصم المسموح
قيمة الخصم الممنوحه: ${prmPandV}
سيتم تصفير قيمة الخصم .. قم بإدخال القيمة المسموح بها مره أخرى والتي أعلى قيمه لها : ${prmPandV}
      `);
      return "";
    } else if (val > prmPandV && f === "DiscC") {
      alert(`
قيمة الخصم المدخله أكبر من قيمة الخصم المسموح
قيمة الخصم الممنوحه: ${prmPandV}
سيتم تصفير قيمة الخصم .. قم بإدخال القيمة المسموح بها مره أخرى والتي أعلى قيمه لها : ${prmPandV}
      `);
      return "";
    }
  };

  const changInfo = (prop) => (event) => {
    if (prop === "discount") {
      subInfo.DiscPers = (
        (event.target.value * 100) /
        parseFloat(subInfo.val1 * subInfo.Price)
      ).toFixed(2);
      let ret = null;
      if (permiElm.SDV !== 0)
        ret = retPrmiSDPandSDV(
          parseFloat(event.target.value),
          permiElm.SDV,
          "DiscC"
        );
      else ret = retPrmiSDPandSDV(subInfo.DiscPers, permiElm.SDP, "DiscP");
      if (ret === "") {
        event.target.value = "";
        subInfo.DiscPers = "";
      }
    } else if (prop === "DiscPers") {
      subInfo.discount = (
        parseFloat(subInfo.val1 * subInfo.Price) *
        (event.target.value / 100)
      ).toFixed(2);
      let ret = null;
      if (permiElm.SDP !== 0)
        ret = retPrmiSDPandSDV(
          parseFloat(event.target.value),
          permiElm.SDP,
          "DiscP"
        );
      else ret = retPrmiSDPandSDV(subInfo.discount, permiElm.SDV, "DiscC");
      if (ret === "") {
        event.target.value = "";
        subInfo.discount = "";
      }
    } else if (prop === "Price") {
      subInfo.discount = (
        parseFloat(subInfo.val1 * event.target.value) *
        (subInfo.DiscPers / 100)
      ).toFixed(2);
      subInfo.totJust = parseFloat(subInfo.val1 * event.target.value).toFixed(
        2
      );
      subInfo.taxval = parseFloat(
        (subInfo.totJust * subInfo.taxperc) / 100
      ).toFixed(2);
    } else if (prop === "totJust") {
      let prce = parseFloat(event.target.value / subInfo.val1).toFixed(2);
      subInfo.Price = prce;
      subInfo.discount = (
        parseFloat(subInfo.val1 * prce) *
        (subInfo.DiscPers / 100)
      ).toFixed(2);
      subInfo.taxval = parseFloat(
        (subInfo.totJust * subInfo.taxperc) / 100
      ).toFixed(2);
    } else if (prop === "val1") {
      subInfo.discount = (
        parseFloat(event.target.value * subInfo.Price) *
        (subInfo.DiscPers / 100)
      ).toFixed(2);
      subInfo.totJust = parseFloat(event.target.value * subInfo.Price).toFixed(
        2
      );
      subInfo.taxval = parseFloat(
        (subInfo.totJust * subInfo.taxperc) / 100
      ).toFixed(2);
    } else if (prop === "taxperc") {
      subInfo.taxval = parseFloat(
        (subInfo.totJust * event.target.value) / 100
      ).toFixed(2);
    }

    setSubInfo({ ...subInfo, [prop]: event.target.value });
  };

  const changInvs = (prop) => (event) => {
    setInfoInv({ ...infoInv, [prop]: event.target.value });
    setIData({ ...idata, InvId_Return: event.target.value });
  };

  const handClickInvs = async () => {
    if (infoInv.InvId_Return === "") {
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "أدخل رقم المرتجع",
        time: 2000,
      });
    } else {
      let isExsist = false,
        pipt = setting.PrTy_InPr == "12" ? "02" : "01",
        piptName = setting.PrTy_InPr == "12" ? "مبيعات" : "مشتريات";
      await axios
        .get(
          uri + `InvRet?PcIdIvPc=${setting.PrTy_InPr}&&branch=${sto.branchi}`
        )
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (
              parseInt(res.data[i].InvId_Return) ==
              parseInt(infoInv.InvId_Return)
            )
              isExsist = true;
          }
        })
        .catch((error) => { });

      // alert(isExsist)
      if (isExsist == true) {
        handOSnack({
          vertical: "top",
          horizontal: "right",
          severit: "warning",
          text: `فاتورة ${piptName} المدخلة تم إرجاعها مسبقا`,
          time: 4000,
        });
      } else {
        await axios
          .get(
            uri +
            `InvRet?id=${infoInv.InvId_Return}&&PcIdIvPc=${pipt}&&branch=${sto.branchi}`
          )
          .then((res) => {
            showChosedInv(false, res.data.proc_id);

            setOr({ ...or, postput: "toPost" });
          })
          .catch((error) => {
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text: "رقم المرتجع غير موجود",
              time: 2000,
            });
          });
      } // else c
    } // else m

    // eds changes
    if (!stor.hasOwnProperty("comt")) setStor({ ...stor, comt: -1 });
  };
  const beZero = () => {
    setStor({ ...stor, comt: -1 });
    if (subInfo.discount === "") subInfo.discount = 0;
    if (subInfo.DiscPers === "") subInfo.DiscPers = 0;
    if (subInfo.Price === "") subInfo.Price = 0;
    if (subInfo.val1 === "") subInfo.val1 = 0;
    if (subInfo.perc === "") subInfo.perc = 0;
    if (subInfo.taxperc === "") subInfo.taxperc = 0;
    if (subInfo.taxval === "") subInfo.taxval = 0;
    if (subInfo.totJust === "") subInfo.totJust = 0;
  };

  const getrowDataCurrencie = async () => {
    beZero();
    or.cros = "newRow";
    or.PrAC = 0;

    if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12") {
      if (subInfo.barcode != "") {
        if (parseFloat(subInfo.val1) != 0) {
          let val1InSub = 0,
            countsCurr = 0,
            subInfoVal1_perc = subInfo.val1 * subInfo.perc;

          for (let m = 0; m < isdata.length; m++) {
            if (subInfo.barcode == isdata[m].barcode)
              val1InSub = isdata[m].val1;
          }
          countsCurr = parseInt(counts.curr) - parseInt(val1InSub);

          if (
            setting.PrTy_InPr == "12" ||
            permiElm.SIM == true ||
            countsCurr >= subInfoVal1_perc ||
            subInfo?.type == 4
          ) {
            /* الكمية مؤقت */

            if (permiElm.ASPZ === 1 || subInfo.Price > 0) {
              /* قيمة صفرية */

              if (
                permiElm.USP === true ||
                indexUnit !== 0 ||
                parseFloat(subInfo.Price) >= parseFloat(subInfo.minPrice)
              ) {
                /* سعر البيع اقل من الـسعر الادنى */

                for (let m = 0; m < isdata.length; m++) {
                  //  alert(parseInt(subInfo.Price)+' == '+parseInt(isdata[m].new_exchange_price) +' && '+ subInfo.barcode+' == '+isdata[m].barcode)
                  if (
                    parseInt(subInfo.Price) ==
                    parseInt(isdata[m].new_exchange_price) &&
                    subInfo.barcode == isdata[m].barcode
                  ) {
                    or.cros = "rnewRow";
                    or.i = m;
                    break;
                  } else {
                    or.cros = "newRow";
                  }
                }

                await handRowAdd(or);

                // await reCountCurr();
                await handleCloseCuDWithSWState();
                await handleClDia("fav", false);
                await setSubInfo(insh.InfoSub);
                setCurrUnit([]);
                //} /* end ISPLP */ // else{alert('سعر البيع اقل من سعر الشراء')}
              } /* end USP */ else
                alert(
                  `لا يمكن البيع اقل من السعر الادنى للصنف السعر الادنى المسجل للصنف هو : ${subInfo.minPrice}`
                );
            } /* price > 0 */ else alert("السعر اقل من صفر");
          } /* end val1 Curr */ else {
            alert(
              `
          الكمية المدخلة للصنف أكبر من رصيد المخزن
          الكمية الاجمالية المدخلة : ${parseFloat(subInfo.val1) * parseFloat(subInfo.perc)
              }
          رصيد المخزن : ${valueSafes.name} = ${parseInt(counts.curr) - parseInt(val1InSub)
              }
          `
            );
          }
        } /* end Val1 */ else {
          alert("لا يمكنك التنزيل الكمية 0");
        }
      } /* end barcode */ else {
        alert("لا يوجد باركود للصنف تحقق من البيانات");
      }
    } else {
      if (subInfo.barcode !== "") {
        if (parseFloat(subInfo.val1) !== 0) {
          let val1InSub = 0;
          for (let m = 0; m < isdata.length; m++) {
            if (subInfo.barcode == isdata[m].barcode)
              val1InSub = isdata[m].val1;
          }

          if (permiElm.ASPZ === 1 || subInfo.Price > 0) {
            for (let m = 0; m < isdata.length; m++) {
              if (
                parseInt(subInfo.Price) ==
                parseInt(isdata[m].new_exchange_price) &&
                subInfo.barcode == isdata[m].barcode
              ) {
                or.cros = "rnewRow";
                or.i = m;
                break;
              } else {
                or.cros = "newRow";
              }
            }

            await handRowAdd(or);
            await handleCloseCuDWithSWState();
            await setSubInfo(insh.InfoSub);
            setCurrUnit([]);
          } /* price > 0 */ else alert("السعر اقل من صفر");
        } /* end Val1 */ else {
          alert("لا يمكنك التنزيل الكمية 0");
        }
      } /* end barcode */ else {
        alert("لا يوجد باركود للصنف تحقق من البيانات");
      }
    }
  };

  // const noGetThings = () => { return null;}
  const noGetThings = () => { };

  const handNewInv = () => {
    // alert('hand New Inv');
    setIshedden(false);
    setPdfToggle(null);
    reLoadLastInv();
    setIsData([]);
    setValueCustomers(optionsCustomers[0]); //getAutoCustomers(0);
    setValueSalesmen(insh.Auto);
    setValueCostcenter(insh.Auto);
    // condshSet_ValueType
    const inveTypeIndex = getInveTypeIndex(optionsCustomers[0].Cust_inv_type);

    changAutoType(gets.types[inveTypeIndex], false); //chang_AutoType(optionsType[1]);
    setValueStock(gets.Stocks[0]); //getAutoStocks(0);
    setOmlaValue(getDefaultOmla(gets.omlats));
    // const safe = getSafeDefault(gets.permisApp.user_id, gets.Users);
    let invKind = null;



    if (optionsCustomers[0].Cust_inv_type != null) {
      if (optionsCustomers[0].Cust_inv_type == 0 || optionsCustomers[0].Cust_inv_type == 2) {
        setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
        setUiElement({ ...uiElement, bank: true });

        setSwitchState({ ...switchState, cashORnetworksw: false });
        setLbl({ ...lbl, chashNetwork: transForm("cash") });
        setUiElement({ ...uiElement, bank: true });
        setIData({
          ...location.state.idata, inv_type: 2, pay_type: 1, bank: 0, cust_id: optionsCustomers[0].id,
          customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD")
        });
        setValueStock(gets.Stocks[0]); //setOptionsBank(insh.AutoBankOp);
        setValueType(gets.types[1]);
        invKind = gets.types[1].id;
      } else if (optionsCustomers[0].Cust_inv_type == 1) {
        const reetS = reInshAuto(gets.Stocks[0].id, "");
        setValueStock(reetS);
        const reetB = reInshAuto(-1, "");
        setValueBank(reetB);
        setIData({
          ...location.state.idata, inv_type: 1, pay_type: -1, bank: -1, cust_id: optionsCustomers[0].id,
          customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD")
        });
        setValueType(gets.types[0]);
        invKind = gets.types[0].id;
      } else if (optionsCustomers[0].Cust_inv_type == 3 || optionsCustomers[0].Cust_inv_type == 4) {

        const text = transForm("network").toString();
        setLbl({ ...lbl, chashNetwork: text });
        setValueBank(gets.Banks[0]); //getAutobanks(0);
        setUiElement({ ...uiElement, bank: false });

        setSwitchState({ ...switchState, cashORnetworksw: true });
        setValueType(gets.types[1]); //getAutobanks(0);
        invKind = gets.types[1].id;
        setIData({
          ...location.state.idata, inv_type: 2, pay_type: 2, bank: gets.Banks[0].id, cust_id: optionsCustomers[0].id,
          customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD")
        });
      }
    } else {
      setValueBank(insh.AutoBank); //setOptionsBank(insh.AutoBankOp);
      setLbl({ ...lbl, chashNetwork: transForm("cash") });
      setUiElement({ ...uiElement, bank: true });
      setSwitchState({ ...switchState, cashORnetworksw: false });
      setIData({
        ...location.state.idata, inv_type: 2, pay_type: 1, bank: 0, cust_id: optionsCustomers[0].id,
        customer_name: optionsCustomers[0].name, date: moment(new Date()).format("YYYY-MM-DD")
      });
      setValueStock(gets.Stocks[0]); //setOptionsBank(insh.AutoBankOp);
      setValueType(gets.types[1]);
      setRemains(insh.Remains);
      invKind = gets.types[1].id;
    }
    const custType = optionsCustomers[0].Cust_inv_type == 3;

    setValueSafes(gets.Safes[0]);
    // setIData({
    //   ...location.state.idata,
    //   customer_name: optionsCustomers[0].name,
    //   cust_id: optionsCustomers[0].id,
    //   pay_type: custType ? 2 : location.state.idata?.pay_type,
    //   bank: custType ? gets.Banks[0].id : location.state.idata?.bank,
    //   date: moment(new Date()).format("YYYY-MM-DD"),
    //   inv_type:invKind
    // });
    setSums(insh.Sums);
    setStor({ ...stor, comt: -1 });
    setExSub(0);
    setPayment(insh.Payment);
    setIsClick(insh.ished);
    setRemains(insh.Remains);

    // if (custType) {
    //   setUiElement({ ...insh.UiElement, bank: false });
    //   setSwitchState({ ...switchState, cashORnetworksw: true });
    //   setLbl({ ...lbl, chashNetwork: transForm("network") });

    // } else {
    //   setUiElement(insh.UiElement);
    //   setLbl({ ...lbl, chashNetwork: transForm("cash") });
    //   setSwitchState(insh.SwitchState);
    // }
    setChecked({
      ...checked,
      costWithVat: true,
      discMinusck: false,
      discRateck: false,
      networkck: false,
      cashck: true,
      network_partck: false,
      cash_partck: false,
      agel_partck: false,
      bank_partck: false,
      emp_partck: false,
      // autoOrStaticck:false,
    });
    setOr(insh.Or);
    setIshed(insh.ished);
    setPermiElm({ ...permiElm, SaUp: permiElm.Sa });
  };

  const goAlert = () => {
    alert("النسبة كبيرة");
    return 0;
  };
  // editable?: ('always' | 'onUpdate' | 'onAdd' | 'never');
  // func m t
  var columnsInvSub = [
    { title: "المعرف", field: "id", hidden: true, rowStyle: stElemnt.SubR },
    {
      title: "رقم الصنف",
      field: "currency_from",
      hidden: true,
      rowStyle: stElemnt.SubR,
    },
    {
      title: "رقم الباركود",
      field: "barcode",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الصنف",
      field: "symbol",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الوحدة",
      field: "unitame",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الكمية",
      field: "val1",
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTval1]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "العبوة",
      field: "val",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "السعر",
      field: "new_exchange_price",
      type: "numeric",
      editable: nevEve,
      editComponent: (props) => (
        <TextField
          disabled={permiElm.USP}
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTpric]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الإجمالي",
      field: "totJust",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },

    {
      title: "قيمة الخصم",
      field: "discount",
      type: "numeric",
      //editable:nevEve,
      editable: "always",
      editComponent: (props) => (
        <TextField
          id="discount"
          name="discount"
          disabled={
            (permiElm.APDC === 2 || permiElm.APDC === 3) && permiElm.DSOI == 1
              ? false
              : true
          }
          variant="outlined"
          size="small"
          className={clasTF.rootmt}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          // onChange={changinvDisc('minus')}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الخصم%",
      field: "DiscPers",
      type: "numeric",
      //editable:nevEve,
      editable: "always",
      editComponent: (props) => (
        <TextField
          disabled={
            (permiElm.APDC === 1 || permiElm.APDC === 3) && permiElm.DSOI == 1
              ? false
              : true
          }
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTdisc]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) =>
            props.onChange(
              e.target.value > permiElm.SDP ? goAlert() : e.target.value
            )
          }
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الإجمالي بعد الخصم",
      field: "totAfterDisc",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "ضريبة التبغ",
      field: "tab3Val",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "القيمة المضافة%",
      field: "taxperc",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "إجمالي الضريبة",
      // title: "إجمالي القيمة المضافة",
      field: "taxval",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "الصافي",
      field: "netAmountSub",
      editable: "never",
      rowStyle: stElemnt.SubR,
    },
    {
      title: "ملاحظات",
      field: "",
      hidden: true,
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={clasTF.rootmt}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      hidden: true,
      rowStyle: stElemnt.SubR,
    },
    {
      title: "البيان",
      field: "notes",
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTnote]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
    {
      title: setting.priceNM,
      field: "sale_price",
      hidden: true,
      editComponent: (props) => (
        <TextField
          variant="outlined"
          size="small"
          className={[clasTF.rootmt, clasTF.mtTpric]}
          InputProps={{ className: clasTF.input }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      rowStyle: stElemnt.SubR,
    },
  ];

  // func m t
  const befPushTos = async () => {
    addChangds();
  };

  let his = useHistory();

  const toPrint = async (type, e, hist) => {
    or.PrAC = hist;
    if (isdata.length > 0) await toWhere(type, e);
    else
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف",
        time: 2000,
      });
  };


  const handPrint = async (procid) => {
    const serializedinv = JSON.stringify({
      proc_id: procid,
      invN: setting.titleHed,
      invNEn: setting.titleHedEn,
      salesman: valueSalesmen.name,
      backInv: "InvSO",
      branch: sto.branchi,
      ProcTypeInvProc: setting.PrTy_InPr,
    });
    await localStorage.setItem("datainv", serializedinv);
    const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};
    if (or.PrAC === 1) {
      if (premiApp?.cashirTemplate == 3) {
        his.push({ pathname: "/RestCasher" });
      } else if (premiApp?.cashirTemplate == 2) {
        his.push({ pathname: "/CashairNoTax" });
      } else {
        his.push({ pathname: "/C" });
      }
    } else if (or.PrAC === 2) {
      if (premiApp?.billTemplate == 4) {
        his.push({ pathname: "/bill4" });
      } else if (premiApp?.billTemplate == 3) {
        his.push({ pathname: "/gold-bill" });
      } else if (premiApp?.billTemplate == 5) {
        his.push({ pathname: "/RevCompany" });
      } else if (premiApp?.billTemplate == 6) {
        his.push({ pathname: "/RevInv" });
      }else if (premiApp?.billTemplate == 7) {
        his.push({ pathname: "/bill2" });
      }
      else if (premiApp?.billTemplate == 8) {
        his.push({ pathname: "/bill4" });
      }
       else if (premiApp?.billTemplate == 2) {

        his.push({ pathname: "/bill2" });
        // window.open( "/bill2");
      } else his.push({ pathname: "/A" });
    } else if (or.PrAC === 3) {
      setGenerating(true);
      await handleDirectPrintTemp2(
        JSON.parse(serializedinv),
        isdata.length,
        handNewInv
      );
      // await PrintInvoiceDirectly(JSON.parse(serializedinv), handNewInv);
      setGenerating(false);
    } else if (or.PrAC === 4) {
      handNewInv();

      setGenerating(true);
      await PrintInvoiceDirectly(JSON.parse(serializedinv), true, handNewInv);
      setGenerating(false);
    }
  };

  // const getIntervalY = (value)=>{
  //   if()
  // }
  // generate pdf file
  const [generating, setGenerating] = useState(false);

  const PushTos = async (t) => {
    befPushTos();

    //get_multi_payed_agel();
    let Sa_or_Pa_rest_id = null;

    console.log("the data is", remains);
    await ButtonLoading(t);




    const model = {

      inv: {
        ...idata,
        Pay_mouny: payment.paidup ? payment.paidup : 0,
        Remaining: payment.stay,
        agel_part: remains.agelPart,
        cash_part: remains.cashPart,
        network_part: remains.networkPart,
        bank_part: remains.bankPart,
        emp_part: remains.empPart,
      },
      stor: stor,

      parms: {
        isAgel: remains.isAgel,
        isCash: remains.isCash,
        isNetwork: remains.isNetwork,
        isBank: remains.isBank,
        isEmp: remains.isEmp,
        isWindow: remains.isWindow,
        empId: remains.empId,
        bankId: remains.bankId,
        networkId: remains.networkId,
        omlaId: omlaValue?.id,
        tobaccoFees: premiA.tobaccoFees,
        omlaExchange: omlaValue?.exchange,
        iuserid: idata.create_user,
        iformidCuCo: dataF[3].id,
        proc_type: setting.routIS == "Inv_Sub_ToS/" ? 2 : 1,
        yashml: permiCuCo.yashml == "W",
      },
      // parms: {
      //   remains:remains,
      //   omlaId: omlaValue?.id,
      //   tobaccoFees: premiA.tobaccoFees,
      //   omlaExchange: omlaValue?.exchange,
      //   iuserid: idata.create_user,
      //   iformidCuCo: dataF[3].id,
      //   proc_type: setting.routIS == "Inv_Sub_ToS/" ? 2 : 1,
      //   yashml: permiCuCo.yashml == "W",
      // },
      State: "Post",
      _Data: isdata,
    };

    if (or.postput == "toPost") {
      await axios
        .post(uri + "_HInv", model)
        .then((resI) => {
          /*console.log('done I_po');*/
          console.log(" the  data is", model);

          if (or.PrAC !== 0) handPrint(resI.data.proc_id);
          ButtonSuccess(t, true);
        })
        .catch((resI) => {
          // console.log(resI.response?.data?.Message);
          // console.log(Message);
          handOSnack({
            vertical: "top",
            horizontal: "right",
            severit: "warning",
            text:
              "لم يتم اضافة الفاتورة" + "بسبب " + resI.response?.data?.Message,
            time: 2000,
          });

          ButtonSuccess(t, false);
        });
    } else if (or.postput == "toPut") {
      await ButtonLoading(t);
      // I_Pu
      console.log(" the  data is", model);
                

      if (permiElm.IPU) {
        await axios
          .put(uri + "_HInv", model)
          .then((resI) => {
            /*console.log('done I_po');*/
            if (or.PrAC !== 0) handPrint(resI.data.proc_id);
            ButtonSuccess(t, true);
          })
          .catch((resI) => {
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text:
                "لم يتم تعديل الفاتورة" +
                "  " +
                "بسبب " +
                resI.response?.data?.Message,
              time: 2000,
            });
            ButtonSuccess(t, false);
          });
      } else {

        const tb = { inv: idata, stor: stor };

        await axios
          .put(uri + "Inv_R_RS_", tb)
          .then((resI_Pu) => {
            /*console.log('done I_Pu');*/

            if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
              Sa_or_Pa_rest_id = resI_Pu.data.sale_rest_id;
            else Sa_or_Pa_rest_id = resI_Pu.data.purch_rest_id;

            // // axios.put(uri+'Restrictions'+'?pcid='+resI_Pu.data.proc_id)
            // // .then(retR_Pu => { /*console.log('done R_Pu');*/ }).catch(retR_Pu => { /*console.log('no R_Pu');*/ });

            // // // IS_De&Po
            // // axios.post(uri+setting.routIS+permiCuCo.yashml+'?pcid='+resI_Pu.data.proc_id+'&&State=rePost', isdata)
            // // .then(resIS_Po => { /*console.log('done resIS_Po');*/ }).catch(resIS_Po => { /*console.log('no resIS_Po');*/ });

            // // // RS_De&Po
            // // axios.post(uri+'Restrictions_Sub'+'?idR='+Sa_or_Pa_rest_id, idata)
            // // .then(resRS_Po => { /*console.log('done RS_Po');*/ }).catch(resRS_Po => { /*console.log('no RS_Po');*/ });

            // /* E inner */
            if (or.PrAC !== 0) handPrint(resI_Pu.data.proc_id);
            ButtonSuccess(t, true);
          })
          .catch((resI_Pu) => {
            ButtonSuccess(t, false); /*console.log('no I_Pu');*/
          });
      }
    }
  };

const sendToZatca = async (t) => {

    console.log("the data is", remains);
    const model = {
      ...idata,
        Pay_mouny: payment.paidup ? payment.paidup : 0,
        Remaining: payment.stay,
        agel_part: remains.agelPart,
        cash_part: remains.cashPart,
        network_part: remains.networkPart,
        bank_part: remains.bankPart,
        emp_part: remains.empPart,
    };
if (or.postput == "toPut") {
      await ButtonLoading(t);
      // I_Pu
      console.log(" the  data is", model);
                
      const URL = uri + "Zatca/SignInvAndSendToZatca";
      await axios
        .put(URL, model)
        .then((resI) => {
          ButtonSuccess(t, true);
        })
        .catch((resI) => {
          handOSnack({
            vertical: "top",
            horizontal: "right",
            severit: "warning",
            text:
              "لم يتم submite الفاتورة" +
              "  " +
              "بسبب " +
              resI.response?.data?.Message,
            time: 2000,
          });
          ButtonSuccess(t, false);
        });
    }
  };

  const PushTost = async (t) => {
    befPushTos();
    let Sa_or_Pa_rest_id = null;
    if (or.postput == "toPost") {
      await ButtonLoading(t);
      const tb = {
        invtemp: idata,
        stor: stor,
        parms: {
          iuserid: idata.create_user,
          iformidCuCo: dataF[3].id,
        },
      };

      await axios
        .post(uri + "Inv_R_RS__", tb)
        .then((resI) => {
          /*console.log('done I_po');*/
          axios
            .post(
              uri +
              setting.routIS +
              permiCuCo.yashml +
              "?pcid=" +
              resI.data.proc_id +
              "&&State=Postt",
              isdata
            )
            .then((resS) => {
              /*console.log('done IS_po');*/
              if (or.PrAC !== 0) handPrint(resI.data.proc_id);
              ButtonSuccess(t, true);
            })
            .catch((resS) => {
              /*console.log('no IS_po');*/
            });
        })
        .catch((resI) => {
          ButtonSuccess(t, false);
        });
    }
    if (or.postput == "toPut") {
      await ButtonLoading("t");
      // I_Pu
      const tb = { inv: idata, stor: stor };

      await axios
        .put(uri + "Inv_R_RS", tb)
        .then((resI_Pu) => {
          /*console.log('done I_Pu');*/

          if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
            Sa_or_Pa_rest_id = resI_Pu.data.sale_rest_id;
          else Sa_or_Pa_rest_id = resI_Pu.data.purch_rest_id;

          // R_Pu
          axios
            .put(uri + "Restrictions" + "?pcid=" + resI_Pu.data.proc_id)
            .then((retR_Pu) => {
              /*console.log('done R_Pu');*/
            })
            .catch((retR_Pu) => {
              /*console.log('no R_Pu');*/
            });

          // IS_De&Po
          axios
            .post(
              uri +
              setting.routIS +
              permiCuCo.yashml +
              "?pcid=" +
              resI_Pu.data.proc_id +
              "&&State=rePost",
              isdata
            )
            .then((resIS_Po) => {
              /*console.log('done resIS_Po');*/
            })
            .catch((resIS_Po) => {
              /*console.log('no resIS_Po');*/
            });

          // RS_De&Po
          axios
            .post(uri + "Restrictions_Sub" + "?idR=" + Sa_or_Pa_rest_id, idata)
            .then((resRS_Po) => {
              /*console.log('done RS_Po');*/
            })
            .catch((resRS_Po) => {
              /*console.log('no RS_Po');*/
            });

          /* E inner */
          if (or.PrAC !== 0) handPrint(resI_Pu.data.proc_id);
          ButtonSuccess("t", true);
        })
        .catch((resI_Pu) => {
          ButtonSuccess(t, false); /*console.log('no I_Pu');*/
        });
    }
  };
  const deleteTos = async (e) => {



    await e.preventDefault();
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    ButtonLoading("delete");

    await axios.delete(uri + `_HInv?id=${idata.proc_id}`).then(res => {
      toast.success("invoice has deleted");
      ButtonSuccess("delete", true);

    }).catch(err => {
      console.log("eror", err)
      ButtonSuccess("delete", false);
      toast.error("not delete ")
    })
    // await axios
    //   .delete(uri + setting.routI_R_RS + "?pcid=" + parseInt(idata.proc_id))
    //   .then((resIsRs) => {
    //     ButtonSuccess("delete", true);
    //   })
    //   .catch((resIsRs) => {
    //     ButtonSuccess("delete", false);
    //   });
  };

  const loadePayMents = () => {
    //  alert(idata.netAmount)
    setPayment({
      ...payment,
      paidupPMDcn: payment.paidup,
      stayPMDcn: payment.stay,
    });

    if (switchState.cashORnetworksw == false && valueType.id == 2) {
      setChecked({
        ...checked,
        cash_partck: true,
        network_partck: false,
        agel_partck: false,
      });
      setIData({
        ...idata,
        cash_part: idata.netAmount,
        network_part: "",
        agel_part: "",
        emp_part: "",
        bank_part: "",
        check_refno: "",
        netAmountPMDcn: idata.netAmount,
      });
    } else if (switchState.cashORnetworksw == true && valueType.id == 2) {
      setChecked({
        ...checked,
        cash_partck: false,
        network_partck: true,
        agel_partck: false,
      });
      setIData({
        ...idata,
        cash_part: "",
        network_part: idata.netAmount,
        agel_part: "",
        emp_part: "",
        bank_part: "",
        check_refno: "",
        netAmountPMDcn: idata.netAmount,
      });


      setValueBankPMD(valueBank); //setOptionsBankPMD(optionsBank);
    }
    // else if (valueType.id === 1) {
    //   setChecked({
    //     ...checked,
    //     network_partck: false,
    //     cash_partck: false,
    //     agel_partck: true,
    //   });
    //   setIData({
    //     ...idata,
    //     cash_part: "",
    //     network_part: "",
    //     agel_part: idata.netAmount,
    //     emp_part: "",
    //     bank_part: "",
    //     check_refno: "",
    //     netAmountPMDcn: idata.netAmount,
    //   });
    // }
    else if (valueType.id === 1) {

      if (payment.paidup > 0) {

        alert(payment.paidup)
        if (switchState.cashORnetworksw == false) {
          // setChecked({
          //   ...checked,
          //   cash_partck: false,
          //   network_partck: false,
          //   agel_partck: true,
          // });
          setIData({
            ...idata,
            cash_part: payment.paidup,
            network_part: "",
            agel_part: payment.stay,
            emp_part: "",
            bank_part: "",
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
          });
        } else if (switchState.cashORnetworksw == true) {
          setChecked({
            ...checked,
            cash_partck: false,
            network_partck: true,
            agel_partck: true,
          });
          setIData({
            ...idata,
            cash_part: "",
            network_part: payment.paidup,
            agel_part: payment.stay,
            emp_part: "",
            bank_part: "",
            check_refno: "",
            netAmountPMDcn: idata.netAmount,
          });
          setValueBankPMD(valueBank); //setOptionsBankPMD(optionsBank);

        }
      } else {
        setChecked({
          ...checked,
          cash_partck: false,
          network_partck: false,
          agel_partck: true,
        });
        setIData({
          ...idata,
          cash_part: payment.paidup,
          network_part: "",
          agel_part: payment.stay,
          emp_part: "",
          bank_part: "",
          check_refno: "",
          netAmountPMDcn: idata.netAmount,
        });
      }


    }


  };

  const usedPayedWindow = (v) => {
    if (v === 0) idata.used_payed_window = v;
    else idata.used_payed_window = v;
  };

  // ============================================ btn load
  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const timer = React.useRef();

  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameSavetmp = clsx({
    [clasCAB.buttonSuccessCAB]: success.savetmp,
  });

  const buttonClassnameSavePMD = clsx({
    [clasCAB.buttonSuccessCAB]: success.savePMD,
  });

  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const buttonClassnamePrint = clsx({
    [clasCAB.buttonSuccessCAB]: success.print,
  });
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const ButtonLoading = (prop) => {
    setLoading({ ...loading, [prop]: true });
  };
  const ButtonSuccess = (prop, state) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });
      if (
        prop == "delete" ||
        prop == "save" ||
        prop == "savetmp" ||
        prop == "savePMD"
      ) {
        if (state) handNewInv();
      }
      if (prop == "savePMD") {
        handClosePMD("done");
      }

      timer.current = window.setTimeout(() => {
        setSuccess({ ...success, [prop]: false });
      }, 500);
    }, 2000);
  };
  // ========================================== btn load

  const toDB = async (type, e) => {
    // if (checked.paymentDiloagck === true) {
    if (showPaid === true) {
      if (parseFloat(idata.netAmount) !== parseFloat(remains.allpart)) {
        alert("المبلغ غير متساوي");
      } else {
        // usedPayedWindow(1);
        //await PushTos("savePMD");

        handClosePMD("cancle");
        //usedPayedWindow(0);
        await PushTos(type);

      }
    } else {
      usedPayedWindow(0);

      if (type == "savetmp") {
        await PushTost(type);
      } else {
        await PushTos(type);
      }
    }
  };

  const paidFun = (type) => {


    if (valueType.id == 2) {
      setRemains({
        ...remains,
        cashPart: switchState.cashORnetworksw == false ? idata.netAmount : 0,
        agelPart: 0,
        bankPart: 0,
        networkPart: switchState.cashORnetworksw == true ? idata.netAmount : 0,
        empPart: 0,
        isCash: switchState.cashORnetworksw == false,
        isWindow: true,
        isNetwork: switchState.cashORnetworksw == true,
        isAgel: false,
        isEmp: false,
        allpart: idata.netAmount,
        typePress: type,
      });
      // setValueBank(gets.Banks[0]); //getAutobanks(0);

    } else if (valueType.id == 1 && parseFloat(payment.paidup) > 0) {
      setRemains({
        ...remains,
        cashPart: switchState.cashORnetworksw == false ? payment.paidup : 0,
        agelPart: payment.stay,
        bankPart: 0,
        networkPart: switchState.cashORnetworksw == true ? payment.paidup : 0,
        empPart: 0,
        isCash: switchState.cashORnetworksw == false,
        isWindow: true,
        isAgel: true,
        isEmp: false,
        isNetwork: switchState.cashORnetworksw == true,
        allpart: idata.netAmount,
        typePress: type,
      });


      // if (switchState.cashORnetworksw == false) {
      // // setRemains({ ...remains, cashPart:payment.paidup, agelPart: payment.stay, bankPart: 0, networkPart: 0 ,isCash:true,isAgel:true,isWindow:true});
      //   remains. networkPart=0;
      //   remains. cashPart=payment.paidup;
      //   remains. agelPart=payment.stay;
      //   remains. bankPart=0;
      //   remains. empPart=0;
      //   remains. isCash=true;
      //   remains.isAgel=true;
      //   remains.isWindow=true;
      //   remains.allpart=idata.netAmount;
      //   remains.typePress=type;
      // } else if (switchState.cashORnetworksw == true) {
      // //  setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: payment.paidup,isNetwork:true,isAgel:true,isWindow:true });
      //   remains. networkPart=payment.paidup;
      //   remains. cashPart=0;
      //   remains. agelPart=payment.stay;
      //   remains. bankPart=0;
      //   remains. empPart=0;
      //   remains. isNetwork=true;
      //   remains.isAgel=true;
      //   remains.isWindow=true;
      //   remains.allpart=idata.netAmount;
      //   remains.typePress=type;
      // }
    } else {
      // setRemains({ ...remains, cashPart:0, agelPart: idata.netAmount, bankPart: 0, networkPart: 0 ,isAgel:true,isWindow:true});
      // remains. networkPart=0;
      // remains. cashPart=0;
      // remains. agelPart=idata.netAmount;
      // remains. bankPart=0;
      // remains. empPart=0;
      // remains.isAgel=true;
      // remains.isWindow=true;
      // remains.allpart=idata.netAmount;
      //  remains.typePress=type;
      setRemains({
        ...remains,
        cashPart: 0,
        agelPart: idata.netAmount,
        bankPart: 0,
        networkPart: 0,
        empPart: 0,
        isCash: false,
        isWindow: true,
        isAgel: true,
        isEmp: false,
        isNetwork: false,
        allpart: idata.netAmount,
        typePress: type,
      });
    }

    setValueBankPMD(switchState.cashORnetworksw ? valueBank : gets.Banks[0]);
    setValueEmployePMD(gets.Employees[0]);
    setPayment({ ...payment, paidupPMDcn: payment.paidup, stayPMDcn: payment?.stay });
  }


  const toWhere = (type, e) => {
    if (parseFloat(payment.paidup) > parseFloat(idata.netAmount)) {
      if ((setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")) {
        if (switchState.cashORnetworksw == true) {
          setValueType(gets.types[1]);

          remains.networkPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.cashPart = 0;


          idata.pay_type = 2;
          idata.inv_type = 2;
          idata.cash_part = 0;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = idata.netAmount;

          setChecked({
            ...checked,
            cash_partck: false,
            network_partck: true,
            agel_partck: false,
          });
          blurPaid2(payment.paidup);

        }
        else {
          setValueType(gets.types[1]);
          remains.cashPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.networkPart = 0;


          idata.pay_type = 1;
          idata.inv_type = 2;
          idata.cash_part = idata.netAmount;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = 0;

          setChecked({
            ...checked,
            cash_partck: true,
            network_partck: false,
            agel_partck: false,
          });
          blurPaid2(payment.paidup);

        }
      }
      else {

        if (switchState.cashORnetworksw == true) {
          setValueType(gets.types[1]);

          remains.networkPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.cashPart = 0;
          remains.empPart = 0;
          payment.paidup = idata.netAmount;
          payment.stay = 0;

          idata.pay_type = 2;
          idata.inv_type = 2;
          idata.cash_part = 0;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = idata.netAmount;

          setChecked({
            ...checked,
            cash_partck: false,
            network_partck: true,
            agel_partck: false,
          });
          if (isdata.length > 0) {
            if (showPaid === true) {
              // loadePayMents();
              ///handleClickOpenPMD(TransitionRight);
              // loadePayMents();
              paidFun(type);
              handleClickOpenPaid(type);

            } else

              toDB(type, e);
          }
          else {
            // handOSnack({ vertical: 'top', horizontal: 'center' });
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text: "يجب تنزيل اصناف",
              time: 2000,
            });
          }


        }
        else {
          setValueType(gets.types[1]);
          remains.cashPart = idata.netAmount;
          remains.agelPart = 0;
          remains.bankPart = 0;
          remains.networkPart = 0;


          payment.paidup = idata.netAmount;
          payment.stay = 0;
          idata.pay_type = 1;
          idata.inv_type = 2;
          idata.cash_part = idata.netAmount;
          idata.agel_part = 0;
          idata.bank_part = 0;
          idata.network_part = 0;

          setChecked({
            ...checked,
            cash_partck: true,
            network_partck: false,
            agel_partck: false,
          });
          if (isdata.length > 0) {
            if (checked.paymentDiloagck === true) {
              loadePayMents();
              handleClickOpenPMD(TransitionRight);
            } else

              toDB(type, e);
          }
          else {
            // handOSnack({ vertical: 'top', horizontal: 'center' });
            handOSnack({
              vertical: "top",
              horizontal: "right",
              severit: "warning",
              text: "يجب تنزيل اصناف",
              time: 2000,
            });
          }


        }
      }

    }
    else {
      if (isdata.length > 0) {
        if (showPaid === true) {
          // loadePayMents();
          paidFun(type);
          handleClickOpenPaid(type);
        } else

          toDB(type, e);
      }
      else {
        // handOSnack({ vertical: 'top', horizontal: 'center' });
        handOSnack({
          vertical: "top",
          horizontal: "right",
          severit: "warning",
          text: "يجب تنزيل اصناف",
          time: 2000,
        });
      }
    }
  };

  const toWhere2 = (type, e) => {

    if (isdata.length > 0) {
      if (checked.paymentDiloagck === true) {
        loadePayMents();
        handleClickOpenPMD(TransitionRight);
      } else

        toDB(type, e);
    } else {
      // handOSnack({ vertical: 'top', horizontal: 'center' });
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف",
        time: 2000,
      });
    }
  };

  const toWhereold = (type, e) => {

    if (isdata.length > 0) {
      if (checked.paymentDiloagck === true) {
        loadePayMents();
        handleClickOpenPMD(TransitionRight);
      } else

        toDB(type, e);
    } else {
      // handOSnack({ vertical: 'top', horizontal: 'center' });
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف",
        time: 2000,
      });
    }
  };

  const hRowrAdd = (newData, resolve) => {
    let restaxvalrA = 0,
      resDiscPerA = 0,
      restotJustSumrA = 0,
      restotJustAfterDiscSumrA = 0,
      resDisCountrA = 0,
      resVal1SumrA = 0,
      resNetrA = 0,
      tot_befor_vat_after_disc = 0;

    resVal1SumrA = parseFloat(subInfo.val1) + parseFloat(isdata[or.i].val1);
    restotJustSumrA =
      parseFloat(isdata[or.i].new_exchange_price) * resVal1SumrA;
    resDisCountrA = (
      restotJustSumrA * parseFloat(subInfo.DiscPers / 100)
    ).toFixed(2);
    restotJustAfterDiscSumrA =
      parseFloat(restotJustSumrA) - parseFloat(resDisCountrA);

    restaxvalrA = maths(
      parseFloat(restotJustAfterDiscSumrA),
      parseFloat(subInfo.taxperc),
      "taxval"
    );
    resNetrA = maths(
      parseFloat(restotJustAfterDiscSumrA),
      parseFloat(subInfo.taxperc),
      "Net"
    );
    tot_befor_vat_after_disc = maths(
      parseFloat(restotJustAfterDiscSumrA),
      parseFloat(subInfo.taxperc),
      "before_vat"
    );



    newData = {
      /* to show */
      unitame: LetvalueCurrUnits.name /*  - الـوحدة name */,
      symbol: subInfo.symbol /*  - إسم الصنف  */,

      /* to maaths */
      /* int - الإجمالي */
      totJust: restotJustSumrA,
      /* int - إجمالي الخصم بعد الخصم */
      totAfterDisc: tot_befor_vat_after_disc,

      tot_before_vat_after_d: tot_befor_vat_after_disc,


      tab3Val: subInfo?.is_tab3_curr == 1 ? restotJustAfterDiscSumrA : 0,

      /* to go */
      currency_from: parseInt(itemId) /* float - id الصنف */,
      unit: parseInt(LetvalueCurrUnits.id) /* int - الـوحدة id */,
      /* float - الكمية */
      val1: resVal1SumrA,
      /* float - العبوة */
      val: parseFloat(subInfo.perc),
      /* float - الـسعر */
      new_exchange_price: parseFloat(isdata[or.i].new_exchange_price),
      /* float - الخصم  مبلغ*/
      discount: resDisCountrA,
      /* float - %  خصم نسبة */

      DiscPers: subInfo.DiscPers,
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: parseFloat(subInfo.taxperc),

      taxval: subInfo?.is_tab3_curr == 1 ? 2 * restaxvalrA : restaxvalrA,


      /* float - الصافي */
      netAmountSub: subInfo?.is_tab3_curr == 1 ? 2 * resNetrA : resNetrA,
      barcode: subInfo.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,
    };

    //  alert(newData.netAmountSub)
    const isDataUpdate = [...isdata];
    const index = or.i;
    isDataUpdate[index] = newData;
    setExSub(isDataUpdate.length);
    handSums(isDataUpdate);

    setIsData([...isDataUpdate]);
  };

  const maths = (rTotAfterDic, taxperc, ty) => {
    if (permiCuCo.CWVat == 0 && taxperc != 0) {
      if (ty === "taxval") return ((rTotAfterDic * taxperc) / 100).toFixed(2);
      if (ty === "Net")
        return (rTotAfterDic + (rTotAfterDic * taxperc) / 100).toFixed(2);
      if (ty === "before_vat") return rTotAfterDic.toFixed(2);
    } else if (permiCuCo.CWVat == 1 && taxperc != 0) {
      if (ty === "taxval")
        return (
          parseFloat(rTotAfterDic) -
          rTotAfterDic / (1 + taxperc / 100)
        ).toFixed(2);
      if (ty === "Net") return rTotAfterDic.toFixed(2);

      if (ty === "before_vat") return (rTotAfterDic - (rTotAfterDic / ((100 + taxperc) / taxperc))).toFixed(2);

    } else if (taxperc == 0) {
      if (ty === "taxval") return (0).toFixed(2);
      if (ty === "Net")
        return (rTotAfterDic).toFixed(2);
      if (ty === "before_vat") return rTotAfterDic.toFixed(2);
    }
  };

  const hRowAdd = (newData, resolve) => {
    let errorList = [];
    let restaxvalA = 0,
      resNetA = 0,
      restotJustSumA = 0,
      restotJustAfterDiscSumA = 0,
      resDiscPerA = 0,
      resDisCountA = 0,
      tot_befor_vat_after_disc = 0;


    restotJustSumA = parseFloat(subInfo.totJust);
    restotJustAfterDiscSumA =
      parseFloat(subInfo.totJust) - parseFloat(subInfo.discount);

    if (checked.discRateck === true) {
      //  alert('in if')
      resDiscPerA = parseFloat(invDisc.rate);
      resDisCountA = (
        parseFloat(invDisc.rate / 100) * parseFloat(subInfo.totJust)
      ).toFixed(2);

      restotJustAfterDiscSumA =
        parseFloat(subInfo.totJust) - parseFloat(resDisCountA);
    } else if (checked.discMinusck === true) {
      resDiscPerA = (
        (parseFloat(idata.minus) / parseFloat(subInfo.totJust)) *
        100
      ).toFixed(2);
      resDisCountA = (
        parseFloat(resDiscPerA / 100) * parseFloat(subInfo.totJust)
      ).toFixed(2);
    }
    // disc sub level
    else {
      // alert('in else')
      resDiscPerA = parseFloat(subInfo.DiscPers);
      resDisCountA = (
        parseFloat(subInfo.DiscPers / 100) * parseFloat(subInfo.totJust)
      ).toFixed(2);
    }

    restaxvalA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(subInfo.taxperc),
      "taxval"
    );

    tot_befor_vat_after_disc = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(subInfo.taxperc),
      "before_vat"
    );


    resNetA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(subInfo.taxperc),
      "Net"
    );
    newData = {
      /* to show */
      unitame: LetvalueCurrUnits.name /*  - الـوحدة name */,
      symbol: subInfo.symbol /*  - إسم الصنف  */,
      /* to maaths */
      /* int -  الإجمالي */
      totJust: restotJustSumA,
      /* int - إجمالي الخصم بعد الخصم */
      totAfterDisc: tot_befor_vat_after_disc,

      tot_before_vat_after_d: tot_befor_vat_after_disc,



      tab3Val: subInfo?.is_tab3_curr == 1 ? restotJustAfterDiscSumA : 0,

      /* to go */
      // currency_from: parseInt(subInfo.id), /* float - id الصنف */
      currency_from: parseInt(itemId) /* float - id الصنف */,
      unit: parseInt(LetvalueCurrUnits.id) /* int - الـوحدة id */,
      /* float - الكمية */
      val1: parseFloat(subInfo.val1),
      // new _val1:parseFloat(subInfo.new _val1),
      /* float - العبوة */
      val: parseFloat(subInfo.perc),
      // new _val:parseFloat(subInfo.perc),
      /* float - الـسعر */
      new_exchange_price: parseFloat(subInfo.Price),
      // all_new_exchange_price: parseFloat(subInfo.Price),
      /* float - الخصم  مبلغ*/
      discount: resDisCountA,
      // new _discount: parseFloat(subInfo.discount),
      /* float - %  خصم نسبة */
      DiscPers: resDiscPerA,
      // new _DiscPers: parseFloat(subInfo.DiscPers),
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: parseFloat(subInfo.taxperc),
      // new _taxperc: parseFloat(subInfo.taxperc),
      /* float - إجمالي قيمة المضافة  */
      taxval: subInfo?.is_tab3_curr == 1 ? 2 * restaxvalA : restaxvalA,


      //vatVals: parseFloat(restaxvalA),

      // new_taxval: parseFloat((subInfo.totJust-subInfo.discount)*subInfo.taxperc/100),
      /* float - الصافي */
      // netAmountSub: resNetA,

      netAmountSub: subInfo?.is_tab3_curr == 1 ? 2 * resNetA : resNetA,


      // netAmo untSub: parseFloat(subInfo.totJust-subInfo.discount) + parseFloat((subInfo.totJust-subInfo.discount)*subInfo.taxperc/100),
      barcode: subInfo.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,
    };
    if (errorList.length < 1) {
      //no error
      let dataToAdd = [...isdata];
      dataToAdd.push(newData);
      setIsData(dataToAdd);
      handSums(dataToAdd);
      setExSub(dataToAdd.length);
    } else {
      resolve();
    }
  };

  const [sums, setSums] = useState(insh.Sums);

  // الدفع المبقي
  const [payment, setPayment] = useState(insh.Payment);
  const [remains, setRemains] = useState(insh.Remains);
  const [isHedden, setIshedden] = useState(false);
  const [isClick, setIsClick] = useState(insh.ished);
  const [invDisc, setInvDisc] = useState(insh.InvDisc);


  const handSums = (data) => {
    let val1s = 0,
      tot_sale_or_purch = 0,
      /*tot_sales=0,tot_purchs=0,*/ amountOfVats = 0,
      netAmounts = 0,
      amountAfterDiscs = 0,
      amount_after_disc_before_vat = 0,
      discounts = 0,
      amountWithVats = 0,
      invTab3Val = 0,
      taxpercs;

    for (var m = 0; m < data.length; m++) {
      val1s += parseFloat(data[m].val1);
      tot_sale_or_purch += parseFloat(data[m].totJust);
      taxpercs = data[m].taxperc;
      /* جمالي القيمة المضافة */
      amountOfVats += parseFloat(data[m].taxval);
      netAmounts += parseFloat(data[m].netAmountSub);
      /* إجمالي قبل الضريبة */
      amountAfterDiscs += parseFloat(data[m].totAfterDisc);
      discounts += parseFloat(data[m].discount);

      // tobacco tax
      invTab3Val += parseFloat(data[m].tab3Val);
      amount_after_disc_before_vat += parseFloat(data[m].totAfterDisc);
    }

    /* في الحالتين */
    /* إجمالي بعد الخصم */
    amountWithVats = (
      parseFloat(tot_sale_or_purch) - parseFloat(discounts)
    ).toFixed(2);
    /* يشمل */


    if (permiCuCo.CWVat == 1) {
      amountAfterDiscs = (
        parseFloat(amount_after_disc_before_vat)
      ).toFixed(2);

      amountOfVats = (
        parseFloat(amountWithVats) - parseFloat(amountAfterDiscs)
      ).toFixed(2);

      netAmounts = (
        parseFloat(amountWithVats)
      ).toFixed(2);

    } else {
      /*لا يشمل */
      amountAfterDiscs = (
        parseFloat(amount_after_disc_before_vat)
      ).toFixed(2);

      netAmounts = (
        parseFloat(amountAfterDiscs) + parseFloat(amountOfVats)
      ).toFixed(2);

      amountWithVats = (
        parseFloat(amountAfterDiscs) + parseFloat(amountOfVats)
      ).toFixed(2);


    }



    setSums({ val1: val1s });
    setIData({
      ...idata,
      amountAfterDisc: amountAfterDiscs,
      amountWithVat: amountWithVats,
      tot_sale: tot_sale_or_purch,
      tot_purch: tot_sale_or_purch,
      netAmount: netAmounts,
      amountBefordisc: tot_sale_or_purch,
      amountOfVat: amountOfVats,
      minus: discounts,
      tab3Val: invTab3Val,
    });



    fcHandSumsToPayment(netAmounts);

    fcHandSumsToinvDisc(tot_sale_or_purch, discounts);
  };

  const fcHandSumsToPayment = (newExPrice) => {
    // screan payment
    if (idata.inv_type == 1) {
      setPayment({ ...payment, stay: newExPrice, paidup: 0 });
      setRemains({ ...remains, cashPart: 0, agelPart: newExPrice, bankPart: 0, networkPart: 0 });
    }
    else {
      setPayment({ ...payment, paidup: newExPrice, stay: 0 });
      if (switchState.cashORnetworksw == false) {
        setRemains({ ...remains, cashPart: newExPrice, agelPart: 0, bankPart: 0, networkPart: 0 });
      } else if (switchState.cashORnetworksw == true) {
        setRemains({ ...remains, cashPart: 0, agelPart: 0, bankPart: 0, networkPart: newExPrice });
      }
    }
  };

  const fcHandSumsToinvDisc = (totj, discs) => {
    // قيمة الخصم  مبلغ

    setInvDisc({
      minus: discs,
      rate: (parseFloat(parseFloat(discs) / totj) * 100).toFixed(2),
    });
  };

  const handRowAdd = (or) => {
    if (or.cros === "newRow") {
      hRowAdd();
    } else if (or.cros === "rnewRow") {
      hRowrAdd();
    }
    // }
  };
  const hRowUpdate = (newData, oldData, resolve) => {
    // alert(resolve)
    let resDisCountU = 0,
      restotJustSumU = 0,
      restaxvalU = 0,
      resNetU = 0,
      tot_before_vat_after_desc = 0;

    resDisCountU = (
      parseFloat(newData.DiscPers / 100) *
      newData.new_exchange_price *
      newData.val1
    ).toFixed(2);
    restotJustSumU = parseFloat(
      newData.new_exchange_price * newData.val1 - resDisCountU
    );

    restaxvalU = maths(
      parseFloat(restotJustSumU),
      parseFloat(newData.taxperc),
      "taxval"
    );
    resNetU = maths(
      parseFloat(restotJustSumU),
      parseFloat(newData.taxperc),
      "Net"
    );

    tot_before_vat_after_desc = maths(
      parseFloat(restotJustSumU),
      parseFloat(newData.taxperc),
      "before_vat"

    );

    console.log("the old data is", oldData);
    console.log("the new data is", newData);
    newData = {
      /* to show */
      // unitame: LetvalueCurrUnits.name, /*  - الـوحدة name */
      unitame: newData.unitame /*  - الـوحدة name */,
      symbol: newData.symbol /*  - إسم الصنف  */,
      /* to maaths */
      /* float - الإجمالي */
      totJust: newData.new_exchange_price * newData.val1,
      /* float - الإجمالي بعد الخصم */
      totAfterDisc: tot_before_vat_after_desc,

      tot_before_vat_after_d: tot_before_vat_after_desc,

      tab3Val:
        oldData?.tab3Val == null || oldData?.tab3Val == 0 ? 0 : restotJustSumU,
      // totAfterDisc: newData.val1 * newData.new_exchange_price - newData.discount,
      /* to go */

      currency_from: newData.currency_from /* float - id الصنف */,
      unit: newData.unit /* int - الـوحدة id */,
      /* float - الكمية */
      val1: newData.val1,
      // new _val1:newData.new _val1,
      /* float - العبوة */
      val: newData.val,
      // new _val:newData.val,
      /* float - الـسعر */
      new_exchange_price: newData.new_exchange_price,
      // all_new_exchange_price: newData.new_exchange_price,
      /* float - الخصم مبلغ _ قيمة الخصم  مبلغ*/
      discount: resDisCountU,
      DiscPers: newData.DiscPers,
      // new _DiscPers: newData.DiscPers,
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: newData.taxperc,
      // new _taxperc: newData.taxperc,
      /* float - إجمالي قيمة المضافة  */

      taxval:
        oldData?.tab3Val == null || oldData?.tab3Val == 0
          ? restaxvalU
          : 2 * restaxvalU,
      // new _taxval: (newData.totJust-newData.discount)*newData.taxperc/100,
      /* float - الصافي */

      // netAmountSub: resNetU,
      netAmountSub:
        oldData?.tab3Val == null || oldData?.tab3Val == 0
          ? resNetU
          : 2 * resNetU,
      // netAmo untSub: ((restotJustSumU - resDisCountU)+((restotJustSumU-resDisCountU)*newData.taxperc/100)).toFixed(2),
      barcode: newData.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,
      notes: newData.notes /* string - ملاحضة */,
      sale_price: newData.sale_price /* string - سعر الببيع */,
      tot_before_vat_after_d: tot_before_vat_after_desc,
    };
    const dataUpdate = [...isdata];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData;
    setIsData([...dataUpdate]);
    handSums(dataUpdate);
    resolve();
  };

  const hRowDelete = (event, rowData) => {
    let dataDelete = [...isdata];

    rowData.forEach((rd) => {
      dataDelete = dataDelete.filter((t) => t.tableData.id !== rd.tableData.id);
    });

    setIsData([...dataDelete]);
    // 
    // setIsData(dataDelete);
    handSums(dataDelete);

    if (dataDelete.length === 0) {
      setInvDisc(insh.InvDisc);
      toDoIdata("minus", "");
      setIData({
        ...idata, netAmount: 0,
        amountOfVat: 0,
        amountWithVat: 0,
        tot_purch: 0,
        totAfterDisc: 0,
        amountOfVats: 0,
        cash_part: 0,
        agel_part: 0,
        bank_part: 0,
        network_part: 0,
        Pay_mouny: 0,
        tot_sale: 0,
        Remaining: 0,
        tab3Val: 0,
        taxperc: 0,
        netAmount: 0,
        amountAfterDisc: 0,
        minus: 0,
        discounts: 0,
      });
      setPayment({
        ...payment,
        paidup: 0,
        stay: 0,
      });
      setRemains({
        ...remains,
        cashPart: 0,
        agelPart: 0,
        networkPart: 0,
        bankPart: 0,
        empPart: 0,
      });
    }
    setExSub(dataDelete.length);
  };

  const pinAutosValue = async (Tbl, id) => {
    if (Tbl === "Safe")
      gets.Safes.map((x) => {
        if (x.id == id) setValueSafes(x);
      });
    else if (Tbl === "Stock")
      gets.Stocks.map((x) => {
        if (x.id == id) setValueStock(x);
      });
    else if (Tbl === "omla")
      gets.omlats.map((x) => {
        if (x.id == id) setOmlaValue(x);
      });
    else if (Tbl === "Customer")
      optionsCustomers.map((x) => {
        if (x.id == id) setValueCustomers(x);
      });
    else if (Tbl === "salesmen")
      gets.salesmen.map((x) => {
        if (x.id == id) setValueSalesmen(x);
      });
    else if (Tbl === "costcenter")
      gets.costcenters.map((x) => {
        if (x.id == id) setValueCostcenter(x);
      });
    else if (Tbl === "Bank")
      gets.Banks.map((x) => {
        if (x.i == id) setValueBank(x);
      });
    else if (Tbl === "BankPMD")
      gets.Banks.map((x) => {
        if (x.id == id) setValueBankPMD(x);
      });
    else if (Tbl === "Bank2PMD")
      gets.Banks.map((x) => {
        if (x.id == id) setValueBankTransPMD(x);
      });
    else if (Tbl === "EmployeePMD")
      gets.Employees.map((x) => {
        if (x.id == id) setValueEmployePMD(x);
      });
    // }).catch(error => { })
  };

  const [numberbarcode, setNumberbarcode] = useState("");
  const [isBarcode, setIsBarcode] = useState(false);
  const [isApiConnect, setIsApiConnect] = useState(true);

  useEffect(() => {
    if (isBarcode) {
      getrowDataCurrencie();
      setNumberbarcode("");
    }
  }, [isBarcode]);

  const [barcoding, setBarcoding] = useState(false);

  const [multiCodeList, setMultiCodeList] = useState([]);
  const SearchActivityReports = {
    barcode: '',
    curr: ''
  };
  const codevalue = {
    barcode: '',
    curr: ''
  };
  const [rptParams, setRptParams] = useState(SearchActivityReports);
  const [code, setCode] = useState([]);

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    getBarcodeMulti();
  };
  const getBarcodeMulti = async () => {
    // checked.autoOrStaticck = false;
    // setNumberbarcode(event.target.value);
    let item = null;
    // setCode([]);
    // setBarcoding(true);
    await axios
      .get(
        uri +
        `RptSales/CurrCodes?currCode=${rptParams?.barcode}
            &&emp=${sto.empi}`
      )
      .then((res) => {

        item = res.data?.barcodes[0];
        //  setCode(res.data?.barcodes);
      })
      .catch((err) => {
        toast.error("لا يوجد صنف لهذا الباركود");
        console.log(err);
      });



    if (item != null) {
      setRptParams({ ...rptParams, barcode: '' });
      await barcodeadd(item);
      // setIsBarcode(true);
    } else {
      toast.error("لا يوجد صنف لهذا الباركود");
    }
    //  setBarcoding(false);
  };

  // const addCodeItem = async (dataCode) => {

  //   barcodeadd(dataCode);
  // };
  const barcodeadd = (dataCode) => {
    let pricei = 0.0;
    let totJust = 0.0;
    let taxval = 0.0;
    let taxperc = 0.0;
    let diaperc = 0.0;
    let dismount = 0.0;
    let valperc = 0.0;
    if (setting.PrTy_InPr === "22") {
      taxperc = 0.0;
      pricei = parseFloat(dataCode?.purch).toFixed(2);
    } else if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" || setting.PrTy_InPr == "03") {
      pricei = parseFloat(dataCode?.sale);
      taxperc = (parseFloat(dataCode.tax)).toFixed(2);
    }
    else {
      pricei = parseFloat(dataCode?.purch);
      taxperc = (parseFloat(dataCode.tax)).toFixed(2);
    };

    if (dataCode.discount == null) { dataCode.discount = 0; };
    if (dataCode.amount_disc == null) { dataCode.amount_disc = 0; };

    taxval = ((parseFloat(pricei) * parseFloat(dataCode.tax)) / 100).toFixed(2);
    totJust = (parseFloat(pricei)).toFixed(2);
    diaperc = (parseFloat(dataCode.discount)).toFixed(2);
    dismount = (parseFloat(dataCode.amount_disc)).toFixed(2);
    valperc = (parseFloat(dataCode.perc)).toFixed(2);

    setSubInfo({
      ...subInfo,
      val1: 1,
      is_tab3_curr: dataCode?.is_tab3_curr,
      id: dataCode.curr,
      minPrice: dataCode.mPrice,
      Price: pricei,
      val: dataCode.perc,
      barcode: dataCode.barcode,
      symbol: dataCode.symbol,
      type: dataCode.ctype,
      perc: dataCode.perc,
      taxperc: dataCode.tax,
      totJust: (pricei).toFixed(2),
      discount: 0,
      DiscPers: 0,
      taxval: ((pricei * dataCode.tax) / 100).toFixed(2),
      unitame: dataCode.unitName,

    });
    let errorList = [];
    let restaxvalA = 0,
      resNetA = 0,

      restotJustSumA = 0,
      restotJustAfterDiscSumA = 0,
      resDiscPerA = 0,
      resDisCountA = 0,
      tot_befor_vat_after_disc = 0;


    restotJustSumA = parseFloat(totJust);
    restotJustAfterDiscSumA =
      parseFloat(totJust) - parseFloat(dismount);

    resDiscPerA = parseFloat(diaperc);
    resDisCountA = (
      parseFloat(diaperc / 100) * parseFloat(totJust)
    ).toFixed(2);

    restotJustAfterDiscSumA =
      parseFloat(totJust) - parseFloat(resDisCountA);


    restaxvalA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(taxperc),
      "taxval"
    );

    tot_befor_vat_after_disc = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(taxperc),
      "before_vat"
    );


    resNetA = maths(
      parseFloat(restotJustAfterDiscSumA),
      parseFloat(taxperc),
      "Net"
    );
    dataCode = {
      /* to show */
      unitame: dataCode.unitName /*  - الـوحدة name */,
      symbol: dataCode.symbol /*  - إسم الصنف  */,
      /* to maaths */
      /* int -  الإجمالي */
      totJust: restotJustSumA,
      /* int - إجمالي الخصم بعد الخصم */
      totAfterDisc: tot_befor_vat_after_disc,

      tot_before_vat_after_d: tot_befor_vat_after_disc,



      tab3Val: dataCode?.is_tab3_curr == 1 ? restotJustAfterDiscSumA : 0,

      /* to go */
      // currency_from: parseInt(subInfo.id), /* float - id الصنف */
      currency_from: parseInt(dataCode.curr) /* float - id الصنف */,
      unit: parseInt(dataCode.unit) /* int - الـوحدة id */,
      /* float - الكمية */
      val1: parseFloat(1),
      // new _val1:parseFloat(subInfo.new _val1),
      /* float - العبوة */
      val: parseFloat(valperc),
      // new _val:parseFloat(subInfo.perc),
      /* float - الـسعر */
      new_exchange_price: parseFloat(pricei),
      // all_new_exchange_price: parseFloat(subInfo.Price),
      /* float - الخصم  مبلغ*/
      discount: resDisCountA,
      // new _discount: parseFloat(subInfo.discount),
      /* float - %  خصم نسبة */
      DiscPers: resDiscPerA,
      // new _DiscPers: parseFloat(subInfo.DiscPers),
      /* float -  ضريبة القيمة المضافة نسبة */
      taxperc: parseFloat(taxperc),
      // new _taxperc: parseFloat(subInfo.taxperc),
      /* float - إجمالي قيمة المضافة  */
      taxval: parseFloat(restaxvalA),


      //vatVals: parseFloat(restaxvalA),

      // new_taxval: parseFloat((subInfo.totJust-subInfo.discount)*subInfo.taxperc/100),
      /* float - الصافي */
      // netAmountSub: resNetA,

      netAmountSub: (parseFloat(restaxvalA) + parseFloat(tot_befor_vat_after_disc)).toFixed(2),




      // netAmo untSub: parseFloat(subInfo.totJust-subInfo.discount) + parseFloat((subInfo.totJust-subInfo.discount)*subInfo.taxperc/100),
      barcode: dataCode.barcode /*  - الباركود */,
      sub_center: 0 /* int -  */,
      sub_branch: sto.branchi /* int - الفرع */,

      unitame: dataCode.unitName,
      DiscPers: parseFloat(diaperc),
      discount: parseFloat(dataCode.amount_disc),
      taxperc: parseFloat(dataCode.tax),
    };
    if (errorList.length < 1) {
      //no error
      var oldData1 = isdata.filter((obj) => obj.barcode == dataCode.barcode);
      console.log("the oldadata is", oldData1);


      if (oldData1.length != 0) {

        var oldData = isdata.filter((obj) => obj.barcode == dataCode.barcode);

        let valq = (parseFloat(oldData[0].val1) + parseFloat(1)).toFixed(2);
        let totb = (parseFloat(oldData[0].totJust) + parseFloat(totJust)).toFixed(2);

        let taxv = (parseFloat(oldData[0].taxval) + parseFloat(restaxvalA)).toFixed(2);

        let totAfter = (parseFloat(oldData[0].totAfterDisc) + parseFloat(tot_befor_vat_after_disc)).toFixed(2);

        let net = (parseFloat(oldData[0].netAmountSub) + parseFloat(resNetA)).toFixed(2);

        const dataUpdate = isdata.filter((obj) => obj.id != dataCode.curr);
        const musadata = [...dataUpdate, {
          ...oldData[0], val1: valq, totJust: totb,
          taxval: taxv, netAmountSub: net, totAfterDisc: totAfter,
          val: parseFloat(valperc),
        }];
        setIsData([...musadata]);
        handSums(musadata);
      } else {
        let dataToAdd = [...isdata];
        dataToAdd.push(dataCode);
        setIsData(dataToAdd);
        handSums(dataToAdd);
        setExSub(dataToAdd.length);
      }
    } else {
      // resolve();
    }
  };


  const getBarCode = async (barcode) => {
    // checked.autoOrStaticck = false;
    // setNumberbarcode(event.target.value);
    const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

    setBarcoding(true);
    const tb = {
      Currencies: true, // wait:true,
      parms: {
        scurrenciestype: "B",
        useMizan: premiApp?.useElectronicScale ?? false,
        iempid: sto.empi,
        // currShow:"D",
        safe: valueSafes.id,
        currShow: "M",
        currType: stor.invsType > 2 ? -1 : 2,
        igroupid: -1,
        takei: -1,
        sSearch: barcode,
      },
      autos: {},
    };
    let item = null;
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        // setcuRows(res.data.Currencies);
        console.log(" the return values", res);

        if (res.data?.Currencies?.length == 0)
          toast.error("لا يوجد صنف لهذا الباركود");
        else {
          item = res.data?.Currencies[0];
          // Object.assign(item, );
        }
      })
      .catch((error) => {
        toast.error("لا يوجد صنف لهذا الباركود");
        console.log(error);
      });

    if (item != null) {
      await BefBarcodedataCurrencie(item.id, item.units);
      setIsBarcode(true);
    }
    setBarcoding(false);
    // const indexOfItem = barcodes_eds.indexOf(event.target.value);
    // console.log("the barcode is", barcodes_eds);
    // if (indexOfItem > -1) {
    //   await _onDetected(event.target.value);
    // } else setIsBarcode(false);
  };


  const transformDone = () => {
    his.replace({
      pathname: `/Invoces`,
      state: transToSal,
    });

    // }, 2000);
    loadGet(
      transToSal.idata.branch,
      transToSal.setting.PrTy_InPr,
      transToSal.idata.create_user,
      transToSal.setting.iformid,
      location.state.stor.invsType
    );

    location.state.setting = transToSal.setting;
    setSetting(location.state.setting);
    location.state.stor = transToSal.stor;
    setStor(location.state.stor);

    setOr({ ...or, postput: "toPost" });
  };

  const showChosedInv = async (t, val) => {
    const tbs = {
      parms: {
        iprocid: val,
        bol1: permiCuCo.CWVat,
        sProTyInvPro: setting.PrTy_InPr,
      },
      autos: {},
    };
    const alt = { View_Inv_reseloving: true };

    const al = Object.entries(alt).reduce((u, [n, v]) => {
      u[`${n}` + `${t ? "t" : ""}`] = v;
      return u;
    }, {});
    const alts = { ...al, ...tbs };
    await axios
      .post(uri + "Res", alts)
      .then((res) => {
        //console.log('res.data'); console.log(res.data);

        setIData(res.data.inv);
        console.log("the res data inve is", res.data.inv);
        // .then(resSub => {
        setIsData(res.data.invS);
        setPayment({
          ...payment,
          paidup: res.data.inv?.Pay_mouny,
          stay: res.data.inv?.Remaining,
        });
        setRemains({
          ...remains,
          cashPart: res.data.inv?.cash_part,
          agelPart: res.data.inv?.agel_part,
          networkPart: res.data.inv?.network_part,
          bankPart: res.data.inv?.bank_part,
          empPart: res.data.inv?.emp_part,
        });
        setSums({
          ...sums,
          val1: res.data.invS.reduce(
            (totalVal1, sub) => totalVal1 + sub.val1,
            0
          ),
        });

        res.data.inv.inv_type == 1
          ? setValueType(gets.types[0])
          : setValueType(gets.types[1]);
        pinAutosValue("Safe", res.data.inv.safe);

        pinAutosValue("Stock", res.data.inv.stock);
        pinAutosValue("Customer", res.data.inv.cust_id);
        /* Autos options */
        if (res.data.inv.salesman != -1)
          pinAutosValue("salesmen", res.data.inv.salesman);
        else {
          setValueSalesmen(insh.Auto);
        }
        if (res.data.inv.center != -1)
          pinAutosValue("costcenter", res.data.inv.center);
        else {
          setValueCostcenter(insh.Auto);
        }
        let letNetworkck = false,
          letCashck = false,
          letcashORnetworksw = false;
        if (res.data.inv.pay_type == 1) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type == 2) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type == -1 && res.data.inv.cash_part > 0) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type == -1 && res.data.inv.network_part > 0) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });

          setValueBank(res.data.inv.bank);
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type == -1 && res.data.inv.Pay_mouny == 0) {
          setValueStock(insh.Auto);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        else {
          setValueStock(insh.Auto);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }

        setInfoInv({
          id: res.data.inv.id,
          InvId_Return:
            res.data.inv.InvId_Return != null
              ? res.data.inv.InvId_Return
              : infoInv.InvId_Return,
        });

        /* ======= S in PMD ======= */
        /* checked AND Autos */
        let cash_p = false,
          network_p = false,
          agel_p = false,
          bank_p = false,
          emp_p = false;

        if (res.data.inv.cash_part !== null || res.data.inv.cash_part === 0) {
          cash_p = true;
        }

        if (
          res.data.inv.network_part !== null ||
          res.data.inv.network_part === 0
        ) {
          // g etAutosOptions('Bank',res.data.inv.bank,'NWinPMD');
          pinAutosValue("BankPMD", res.data.inv.bank);
          network_p = true;
        } else {
          setValueBankPMD(insh.AutoBank);
        }

        if (res.data.inv.agel_part !== null || res.data.inv.agel_part === 0) {
          agel_p = true;
        }

        if (res.data.inv.bank_part !== null || res.data.inv.bank_part === 0) {
          // g etAutosOptions('Bank',res.data.inv.bank,'Bk2inPMD');
          pinAutosValue("Bank2PMD", res.data.inv.bank);
          bank_p = true;
        }

        if (res.data.inv.emp_part !== null || res.data.inv.emp_part === 0) {
          // g etAutosOptions('Employee',res.data.inv.emp,'EmpinPMD');
          pinAutosValue("EmployeePMD", res.data.inv.emp);
          emp_p = true;
        }

        setSwitchState({ ...switchState, cashORnetworksw: letcashORnetworksw });
        setChecked({
          ...checked,

          // in UP
          cashck: letCashck,
          networkck: letNetworkck,
          // in PMD
          cash_partck: cash_p,
          network_partck: network_p,
          agel_partck: agel_p,
          bank_partck: bank_p,
          emp_partck: emp_p,
        });

        /* ======= E in PMD ======= */
        setPermiElm({ ...permiElm, SaUp: permiElm.Up });
        // set discount values
        fcHandSumsToinvDisc(res.data.inv?.amountBefordisc, res.data.inv?.minus);

        handCloseDw();

        if (permiElm.IPU == false && (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "01")) {
          // if (permiElm.IPU==false && isClick.hed==false){
          setIshedden(true);
        } else {
          setIshedden(false);

        }


        //  }).catch(error => { })
      })
      .catch((error) => {
        /*console.log('no res');*/
      });
  };

  const showChosedInvt = async (t, val) => {
    const tbs = { parms: { iprocid: val, bol1: permiCuCo.CWVat }, autos: {} };
    const alt = { View_Inv_reseloving: true };
    const al = Object.entries(alt).reduce((u, [n, v]) => {
      u[`${n}` + `${t ? "t" : ""}`] = v;
      return u;
    }, {});
    const alts = { ...al, ...tbs };

    //  console.log('__tb--')
    //  console.log(tb)
    await axios
      .post(uri + "Res", alts)
      .then((res) => {
        //console.log('res.data'); console.log(res.data);

        setIData(res.data.inv);
        setIsData(res.data.invS);

        // .then(resSub => {
        setPayment({
          ...payment,
          paidup: res.data.inv?.Pay_mouny,
          stay: res.data.inv?.Remaining,
        });
        setRemains({
          ...remains,
          cashPart: res.data.inv?.cash_part,
          agelPart: res.data.inv?.agel_part,
          networkPart: res.data.inv?.network_part,
          empPart: res.data.inv?.emp_part,
          bankPart: res.data.inv?.bank_part,

        });
        setSums({
          ...sums,
          val1: res.data.invS.reduce(
            (totalVal1, sub) => totalVal1 + sub.val1,
            0
          ),
        });
        setStor({ ...stor, comt: val });
        res.data.inv.inv_type == 1
          ? setValueType(gets.types[0])
          : setValueType(gets.types[1]);
        pinAutosValue("Safe", res.data.inv.safe);
        pinAutosValue("Stock", res.data.inv.stock);
        pinAutosValue("Customer", res.data.inv.cust_id);


        /* Autos options */
        if (res.data.inv.salesman != -1)
          pinAutosValue("salesmen", res.data.inv.salesman);
        else {
          setValueSalesmen(insh.Auto);
        }
        if (res.data.inv.center != -1)
          pinAutosValue("costcenter", res.data.inv.center);
        else {
          setValueCostcenter(insh.Auto);
        }
        let letNetworkck = false,
          letCashck = false,
          letcashORnetworksw = false;
        if (res.data.inv.pay_type == 1) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;

          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
        } else if (res.data.inv.pay_type == 2) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          setLbl({ ...lbl, chashNetwork: transForm("network") });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type == -1 && res.data.inv.cash_part > 0) {
          setValueBank(insh.AutoBank);
          letCashck = true;
          letcashORnetworksw = false;
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: false,
            DeleIcon: true,
          });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        }
        else if (res.data.inv.pay_type == -1 && res.data.inv.network_part > 0) {
          letNetworkck = true;
          letcashORnetworksw = true;
          setUiElement({ ...uiElement, bank: false, DeleIcon: true });
          const text = transForm("network").toString();
          setLbl({ ...lbl, chashNetwork: text });
          pinAutosValue("Bank", res.data.inv.bank);
        }
        else if (res.data.inv.pay_type == -1 && res.data.inv.Pay_mouny == 0) {
          setValueStock(insh.Auto);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        else {
          setValueStock(insh.Auto);
          setUiElement({
            ...uiElement,
            bank: true,
            payMethod: true,
            stock: true,
            DeleIcon: true,
          });
          setValueBank(insh.AutoBank);
        }
        setInfoInv({
          id: res.data.inv.id,
          InvId_Return:
            res.data.inv.InvId_Return != null
              ? res.data.inv.InvId_Return
              : infoInv.InvId_Return,
        });

        /* ======= S in PMD ======= */
        /* checked AND Autos */
        let cash_p = false,
          network_p = false,
          agel_p = false,
          bank_p = false,
          emp_p = false;

        if (res.data.inv.cash_part !== null || res.data.inv.cash_part === 0) {
          cash_p = true;
        }

        if (
          res.data.inv.network_part !== null ||
          res.data.inv.network_part === 0
        ) {
          pinAutosValue("BankPMD", res.data.inv.bank);
          network_p = true;
        } else {
          setValueBankPMD(insh.AutoBank);
        }

        if (res.data.inv.agel_part !== null || res.data.inv.agel_part === 0) {
          agel_p = true;
        }

        if (res.data.inv.bank_part !== null || res.data.inv.bank_part === 0) {
          pinAutosValue("Bank2PMD", res.data.inv.bank);
          bank_p = true;
        }

        if (res.data.inv.emp_part !== null || res.data.inv.emp_part === 0) {
          pinAutosValue("EmployeePMD", res.data.inv.emp);
          emp_p = true;
        }

        setSwitchState({ ...switchState, cashORnetworksw: letcashORnetworksw });
        setChecked({
          ...checked,

          // in UP
          cashck: letCashck,
          networkck: letNetworkck,
          // in PMD
          cash_partck: cash_p,
          network_partck: network_p,
          agel_partck: agel_p,
          bank_partck: bank_p,
          emp_partck: emp_p,
        });

        /* ======= E in PMD ======= */
        setPermiElm({ ...permiElm, SaUp: permiElm.Up });
        handCloseDwt();
      })
      .catch((error) => {
        /*console.log('no res');*/
      });
  };

  const [voucherDialog, setVoucherDialog] = useState({
    isOpen: false,
    data: "",
    onSucsess: "",
    onEnd: ""
  });
  const blurPaid = (event) => {
    event.preventDefault();

    if (event.target.value > idata.netAmount) {
      let valueVcher = (event.target.value - parseFloat(idata.netAmount)).toFixed(2);
      event.stopPropagation();
      setVoucherDialog({
        isOpen: true,
        data: {
          ...idata, id: infoInv.id,
          omlaId: omlaValue.id,
          exchange: omlaValue.exchange,
          valueOfVoucher: valueVcher
        },
        onSuccess: () => {
          // toDiloa
          setVoucherDialog({ ...voucherDialog, isOpen: false });
          toDoIdata("notes", "Receipt voucher for the amount:" + valueVcher);
        },

      });
      payment.paidupPMDcn = parseFloat(idata.netAmount);
      payment.stayPMDcn = payment.stay;
      setPayment({ ...payment, paidup: parseFloat(idata.netAmount), stay: 0 });
    }
  };

  const blurPaid2 = (pay) => {
    //event.preventDefault();
    if (parseFloat(pay) > parseFloat(idata.netAmount)) {
      let valueVcher = (pay - parseFloat(idata.netAmount)).toFixed(2);
      //event.stopPropagation();
      setVoucherDialog({
        isOpen: true,
        data: {
          ...idata, id: infoInv.id,
          omlaId: omlaValue.id,
          exchange: omlaValue.exchange,
          valueOfVoucher: valueVcher
        },
        onSuccess: () => {
          // toDiloa
          setVoucherDialog({ ...voucherDialog, isOpen: false });
          toDoIdata("notes", "Receipt voucher for the amount:" + valueVcher);
        },

      });
      payment.paidupPMDcn = parseFloat(idata.netAmount);
      payment.stayPMDcn = payment.stay;
      payment.paidup = idata.netAmount;
      payment.stay = 0;
      //setPayment({ ...payment, paidup: parseFloat(idata.netAmount), stay: 0 });
    }
  };

  const changPayment = (prop) => (event) => {

    if (prop === "paidup") {

      let total = (parseFloat(idata.netAmount) - event.target.value).toFixed(2);
      // toDiloag
      payment.paidupPMDcn = event.target.value;
      payment.stayPMDcn = payment.stay;
      setPayment({ ...payment, paidup: event.target.value, stay: total });
      if (valueType.id == 1) {

        if (switchState.cashORnetworksw == false) {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });

          setRemains({ ...remains, cashPart: event.target.value, agelPart: total, bankPart: 0, networkPart: 0 });

          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(gets.Stocks[0]);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });
        } else if (switchState.cashORnetworksw == true) {
          setRemains({ ...remains, cashPart: 0, agelPart: total, bankPart: 0, networkPart: event.target.value });

          setUiElement({
            ...uiElement,
            stock: true,
            payMethod: false,
            bank: false,
          });

          setSwitchState({ ...switchState, cashORnetworksw: true });
          setLbl({ ...lbl, chashNetwork: transForm("network") });
        }
        else {
          setUiElement({
            ...uiElement,
            stock: false,
            payMethod: false,
            bank: true,
          });

          setRemains({ ...remains, cashPart: event.target.value, agelPart: total, bankPart: 0, networkPart: 0 });

          const reet = reInshAuto(0, "");
          setValueBank(reet);
          setValueStock(gets.Stocks[0]);
          setSwitchState({ ...switchState, cashORnetworksw: false });
          setLbl({ ...lbl, chashNetwork: transForm("cash") });

        }

        // setUiElement({
        //   ...uiElement,
        //   stock: false,
        //   payMethod: false,
        //   bank: true,
        // });       
        // const reet = reInshAuto(0, "");
        // setValueBank(reet);
        // setValueStock(gets.Stocks[0]);
        // setSwitchState({ ...switchState, cashORnetworksw: false });
        // setLbl({ ...lbl, chashNetwork: transForm("cash") });

        // if (event.target.value==idata.netAmount){

        //   Message("برجاء تغيير نوع الفاتورة الى نقدية")
        // }
      }

    } else if (prop === "paidupPMDcn") {
      payment.stayPMDcn = (
        event.target.value - parseFloat(idata.netAmount)
      ).toFixed(2);
      setPayment({ ...payment, [prop]: event.target.value });
    }
  };

  const MathChckedIdata = (prop, val) => {
    let res = Number(val);
    res += idata.cash_part;
    setIData({ ...idata, [prop]: "", cash_part: res });
  };


  const MathChangIdata = (prop, val) => {
    let i = {
      cash: Number(remains.cashPart),
      network: Number(remains.networkPart),
      agel: Number(remains.agelPart),
      bank: Number(remains.bankPart),
      emp: Number(remains.empPart),
      sum: Number(0),
      sub: Number(0),
      sumall: Number(0),
      totinv: Number(idata.netAmount),
      res: Number(val),
    };
    let kind = "";
    if (idata.pay_type == 1) {
      kind = "cashPart";
    } else if (idata.pay_type == 2) {
      kind = "networkPart";
    } else {
      kind = "agelPart";
    }
    // if (prop === "cashPart") i.sum = i.res + i.agel + i.bank + i.emp+i.network;
    // else if (prop === "networkPart") i.sum = i.res + i.agel + i.bank + i.emp+i.cash;
    // else if (prop === "agelPart") i.sum = i.network + i.res + i.bank + i.emp+i.cash;
    // else if (prop === "bankPart") i.sum = i.network + i.agel + i.res + i.emp+i.cash;
    // else if (prop === "empPart") i.sum = i.network + i.agel + i.bank + i.res+i.cash;

    if (prop === "cashPart") {
      // i.sum = i.res + i.agel + i.bank + i.emp+i.sub;
      if (switchState.cashORnetworksw == false && remains.isCash == true) {
        i.sum = i.res + i.agel + i.bank + i.emp + i.network;
        remains.cashPart = i.res;
        remains.allpart = i.sum;
      }
      else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        i.sum = i.res + i.agel + i.bank + i.emp + i.sub;
        remains.cashPart = i.res;
        remains.networkPart = i.sub;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.cashPart = i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.sub + i.bank + i.emp + i.network;
      }
    }
    else if (prop === "networkPart") {
      //i.sum = i.res + i.agel + i.bank + i.emp+i.cash;
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.networkPart = i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.agel + i.bank + i.emp + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        i.sum = i.res + i.agel + i.bank + i.emp + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.networkPart = i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.sub + i.bank + i.emp + i.cash;
        remains.allpart = i.sum;
      }
    }
    else if (prop === "agelPart") {
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.network + i.bank + i.emp + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.agelPart = i.res;
        remains.networkPart = i.sub;
        i.sum = i.res + i.sub + i.bank + i.emp + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1) {
        i.sum = i.res + i.network + i.bank + i.emp + i.cash;
        remains.agelPart = i.res;
        remains.allpart = i.sum;
      }
    }
    else if (prop === "bankPart") {
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = i.cash - i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.network + i.agel + i.emp + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.bankPart = i.res;
        remains.networkPart = i.sub;
        i.sum = i.res + i.sub + i.agel + i.emp + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.network + i.sub + i.emp + i.cash;
        remains.allpart = i.sum;
      }

    }
    else if (prop === "empPart") {
      if (switchState.cashORnetworksw == false && valueType.id == 2 &&
        remains.isCash == true && parseFloat(remains.cashPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.cashPart = i.sub;
        i.sum = i.res + i.network + i.agel + i.bank + i.sub;
        remains.allpart = i.sum;
      } else if (switchState.cashORnetworksw == true && valueType.id == 2 &&
        remains.isNetwork == true && parseFloat(remains.networkPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.networkPart = i.sub;
        i.sum = i.res + i.sub + i.agel + i.bank + i.cash;
        remains.allpart = i.sum;
      } else if (remains.isAgel == true && valueType.id == 1 && parseFloat(remains.agelPart) > 0) {
        let all = i.cash + i.agel + i.bank + i.emp + i.network;
        i.sub = all - i.res;
        remains.agelPart = i.sub;
        i.sum = i.res + i.network + i.sub + i.bank + i.cash;
        remains.allpart = i.sum;
      }
    }
    setRemains({
      ...remains,
      [prop]: i.res,
      allpart: i.sum,
    });
  };

  const changIdata = (prop) => (event) => {
    if (prop === "cashPart" && remains.isCash === true) {
      MathChangIdata(prop, event.target.value);
    } if (prop === "networkPart" && remains.isNetwork === true) {
      MathChangIdata(prop, event.target.value);
    } else if (prop === "agelPart" && remains.isAgel === true) {
      MathChangIdata(prop, event.target.value);
    } else if (prop === "bankPart" && remains.isBank === true) {
      MathChangIdata(prop, event.target.value);
    }
    else if (prop === "empPart" && remains.isEmp === true) {
      MathChangIdata(prop, event.target.value);
    }
    else if (prop == "check_refno" && remains.isBank == true) {
      toDoIdata(prop, event.target.value);
    } else if (prop == "refno") {
      toDoIdata(prop, event.target.value);
    } else if (prop == "date") {
      toDoIdata(prop, event.target.value);
    } else if (prop == "notes") {
      toDoIdata(prop, event.target.value);
    }
  };



  const changinvDisc = (prop) => (event) => {
    console.log("the permissions is", permiElm);

    let ret = null,
      evenTargetValue = null;
    if (isdata.length !== 0) {
      if (prop === "minus") {
        if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
          evenTargetValue = (
            (event.target.value * 100) /
            parseFloat(idata.tot_sale)
          ).toFixed(4);
        else
          evenTargetValue = (
            (event.target.value * 100) /
            parseFloat(idata.tot_purch)
          ).toFixed(4);

        if (permiElm.SDV !== 0)
          ret = retPrmiSDPandSDV(
            parseFloat(event.target.value),
            permiElm.SDV,
            "DiscC"
          );
        else ret = retPrmiSDPandSDV(evenTargetValue, permiElm.SDP, "DiscP");
        if (ret === "") {
          event.target.value = "";
          /*invDisc.rate='';*/ afterGetCurrMathRate(0);
        } else {
          afterGetCurrMathRate(evenTargetValue);
        }
      } else if (prop === "rate") {
        if (setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12")
          evenTargetValue = (
            parseFloat(idata.tot_sale) *
            (event.target.value / 100)
          ).toFixed(2);
        else
          evenTargetValue = (
            parseFloat(idata.tot_purch) *
            (event.target.value / 100)
          ).toFixed(2);

        if (permiElm.SDP !== 0)
          ret = retPrmiSDPandSDV(
            parseFloat(event.target.value),
            permiElm.SDP,
            "DiscP"
          );
        else ret = retPrmiSDPandSDV(evenTargetValue, permiElm.SDV, "DiscC");
        if (ret === "") {
          event.target.value = "";
          /*idata.minus='';*/ afterGetCurrMathRate(0);
        } else {
          afterGetCurrMathRate(event.target.value);
        }
      }
    }
    prop === "minus"
      ? setInvDisc({
        ...invDisc,
        [prop]: event.target.value,
        rate: evenTargetValue,
      })
      : setInvDisc({
        ...invDisc,
        [prop]: event.target.value,
        minus: evenTargetValue,
      });
  };
  const afterGetCurrMathRate = (rate) => {
    const isdataU = [...isdata];
    if (rate === "") rate = 0;
    for (let m = 0; m < isdata.length; m++) {
      isdataU[m].DiscPers = rate;
      isdataU[m].barcode = isdata[m].barcode;
      isdataU[m].currency_from = isdata[m].currency_from;
      isdataU[m].discount = ((isdata[m].totJust * rate) / 100).toFixed(2);
      isdataU[m].new_exchange_price = isdata[m].new_exchange_price;
      isdataU[m].sub_branch = isdata[m].sub_branch;
      isdataU[m].sub_center = isdata[m].sub_center;
      isdataU[m].symbol = isdata[m].symbol;
      isdataU[m].totJust = isdata[m].totJust;


      // isdataU[m].totAfterDisc =
      //   isdata[m].totJust - parseFloat(isdataU[m].discount);

      isdataU[m].tot_before_vat_after_d =
        parseFloat(isdataU[m].totJust) - parseFloat(isdataU[m].discount);
      isdataU[m].taxperc = isdata[m].taxperc;

      /* from samer */
      isdataU[m].taxval = maths(
        parseFloat(isdataU[m].tot_before_vat_after_d),
        parseFloat(isdataU[m].taxperc),
        "taxval"
      );
      isdataU[m].netAmountSub = maths(
        parseFloat(isdataU[m].tot_before_vat_after_d),
        parseFloat(isdataU[m].taxperc),
        "Net"
      );
      isdataU[m].totAfterDisc = maths(
        parseFloat(isdataU[m].tot_before_vat_after_d),
        parseFloat(isdataU[m].taxperc),
        "before_vat"
      );

      /* from me */
      // console.log("memememememmemmememmm");
      isdataU[m].totJust = isdata[m].totJust;
      isdataU[m].unit = isdata[m].unit;
      isdataU[m].unitame = isdata[m].unitame;
      isdataU[m].val = isdata[m].val;
      isdataU[m].val1 = isdata[m].val1;
      isdataU[m].tab3Val = subInfo?.is_tab3_curr == 1 ? parseFloat(isdataU[m].tot_before_vat_after_d) : 0;
    }

    handSums(isdataU);
    setIsData([...isdataU]);
  };

  /* s toggle btn */
  const [toggle, setToggle] = React.useState(null);
  const [pdfToggle, setPdfToggle] = React.useState(null);

  const clickOpenToggle = (event, no = 1) => {
    if (isdata.length !== 0) {
      if (no != 1) setPdfToggle(event.currentTarget);
      else setToggle(event.currentTarget);
    } else {
      handOSnack({
        vertical: "top",
        horizontal: "right",
        severit: "warning",
        text: "يجب تنزيل اصناف أو إختيار فاتورة",
        time: 2000,
      });
    }
  };

  const clickCloseToggle = () => {
    setToggle(null);
  };
  /* e toggle btn */
  // ========================================== s Snack

  /* 'error' 'warning' 'info' 'success'
     'top' 'bottom' 'center' 'left' 'right' */
  const [snack, setSnack] = React.useState(insh.Snack);

  const { vertical, horizontal, openSnak } = snack;

  const elmntBtn = (title, icondiv) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
        disabled={true}
        startIcon={icondiv}
      >
        {" "}
        {title}
      </Button>
    );
  };

  const elmntIcon = (icondiv, dis) => {
    return (
      <Fab
        size="medium"
        color="primary"
        disabled={dis}
        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
      >
        {icondiv}
      </Fab>
    );
  };

  const styBtn = {
    dis: { color: "#3b773d", background: "#4fa152" },
    undis: { color: "#fff" },
  };
  const handOSnack = (newState) => {
    setSnack({ openSnak: true, ...newState });
  };

  const handCSnack = (event, reason) => {
    // if (reason === 'clickaway') { return; }
    setSnack({ ...snack, openSnak: false });
  };

  const frontSnack = () => {
    return (
      <div className={clasSN.rootSN}>
        <Toaster />

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSnak}
          autoHideDuration={snack.time}
          onClose={handCSnack}
          key={vertical + horizontal}
          className={clasSN.snackbarSN}
        >
          <Alert onClose={handCSnack} severity={snack.severit}>
            {" "}
            {snack.text}{" "}
          </Alert>
        </Snackbar>
      </div>
    );
  };

  const frontAppBar = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          // className={clasBA.appBarBASal}
          style={{ ...style.bgImg, ...stElemnt.all.appBarBA }}
        >
          <Toolbar className={clasBA.ToolbarBA} variant="dense">
            <Hidden smUp={["sm"]}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="backH"
                onClick={backHome}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="searsh"
                disabled={permiElm.Se}
                onClick={() => handOpenDwt(TransitionDown)}
              >
                <StopIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="searsh"
                disabled={permiElm.Se}
                onClick={() => handOpenDw(TransitionDown)}
              >
                <SearchIcon />
              </IconButton>

              <IconButton
                edge="start"
                color="inherit"
                disabled={permiElm.Ne}
                aria-label="handNew"
                onClick={handNewInv}
              >
                <AddBoxIcon />
              </IconButton>
            </Hidden>

            <Hidden only={["xs"]}>
              <Button
                style={styBtn.undis}
                onClick={backHome}
                startIcon={<CloseIcon />}
              >
                خروج
              </Button>
              <Button
                style={permiElm.Se ? styBtn.dis : styBtn.undis}
                disabled={permiElm.Se}
                onClick={() => handOpenDwt(TransitionDown)}
                startIcon={<StopIcon />}
              >
                {transForm("out-billing")}
              </Button>
              <Button
                style={permiElm.Se ? styBtn.dis : styBtn.undis}
                disabled={permiElm.Se}
                onClick={() => handOpenDw(TransitionDown)}
                startIcon={<SearchIcon />}
              >
                {transForm("invoice-search")}
              </Button>
              <Button
                style={permiElm.Ne ? styBtn.dis : styBtn.undis}
                disabled={permiElm.Ne}
                onClick={handNewInv}
                startIcon={<AddBoxIcon />}
              >
                {transForm("new")}
              </Button>
            </Hidden>
            {/* <Button color="primary">Primary</Button> */}

            <Tooltip title="قرائة باركود">
              <Switch
                edge="start"
                checked={switchState.tableOrscanBC}
                onChange={changSwitch("tableOrscanBC")}
                id="tableOrscanBC"
                value="tableOrscanBC"
                name="tableOrscanBC"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Tooltip>
            {!isHedden
              ?
              switchState.tableOrscanBC ?
                (
                  <Fab
                    color="primary"
                    aria-label="add"
                    style={{
                      ...style.fabButtonBA,
                      ...stElemnt.all.fabButtonBA,
                      ...{ height: 0, width: 0 },
                    }}
                    onClick={() => handCOpenCu(TransitionUp)}
                  >
                    <HomeBcode />{" "}
                  </Fab>
                ) :
                (
                  <Fab
                    color="primary"
                    aria-label="add"
                    style={{ ...style.fabButtonBA, ...stElemnt.all.fabButtonBA }}
                    onClick={() => handCOpenCu(TransitionUp)}
                  >
                    <PostAddIcon />{" "}
                  </Fab>
                )
              :
              false
            }

            <div className={clasBA.growBA} />
            <StyledMenu
              id="customized-menu"
              anchorEl={pdfToggle}
              keepMounted
              open={Boolean(pdfToggle)}
              onClose={() => setPdfToggle(null)}
              style={stElemnt.itemHigh}
            >
              <div className={clasCAB.wrapperCAB}>
                <StyledMenuItem
                  disabled={loading.print || loading.save || generating}
                  className={buttonClassnamePrint}
                  onClick={() => toPrint("print", "e", 3)}
                >
                  <ListItemIcon>
                    <PrintIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={transForm("مباشر ")} />
                </StyledMenuItem>
                {(loading.print || loading.save || generating) && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>

              <div className={clasCAB.wrapperCAB}>
                <StyledMenuItem
                  disabled={loading.print || loading.save || generating}
                  className={buttonClassnamePrint}
                  onClick={() => toPrint("print", "e", 4)}
                >
                  <ListItemIcon>
                    <ScannerIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={transForm("طباعة كبير")} />
                </StyledMenuItem>
                {(loading.print || loading.save || generating) && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>
              {
                (idata.ProcTypeInvProc == "02" || idata.ProcType_InvProc == "12" ) && !idata.IsReportedToZatca &&
                <div className={clasCAB.wrapperCAB}>
                <StyledMenuItem
                  disabled={loading.print || loading.save || generating}
                  className={buttonClassnamePrint}
                  onClick={() => sendToZatca("save")}
                >
                  <ListItemIcon>
                    <PrintIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={transForm("مباشر ")} />
                </StyledMenuItem>
                {(loading.print || loading.save || generating) && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>
              }
            </StyledMenu>

            <StyledMenu
              id="customized-menu"
              anchorEl={toggle}
              keepMounted
              open={Boolean(toggle)}
              onClose={clickCloseToggle}
              style={stElemnt.itemHigh}
            >
              <div className={clasCAB.wrapperCAB}>
                <StyledMenuItem
                  disabled={loading.print || loading.save}
                  className={buttonClassnamePrint}
                  onClick={() => toPrint("print", "e", 1)}
                >
                  <ListItemIcon>
                    <PrintIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={transForm("cashier-view")} />
                </StyledMenuItem>
                {(loading.print || loading.save) && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>

              <div className={clasCAB.wrapperCAB}>
                <StyledMenuItem
                  disabled={loading.print || loading.save}
                  className={buttonClassnamePrint}
                  onClick={() => toPrint("print", "e", 2)}
                >
                  <ListItemIcon>
                    <ScannerIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={transForm("great-view")} />
                </StyledMenuItem>
                {(loading.print || loading.save) && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>
            </StyledMenu>

            <Hidden smUp={["sm"]}>
               {setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" ?(null):(
                  (uiElement.DeleIcon === true ? (
                    <div className={clasCAB.wrapperCAB}>
                      {/* {!permiElm.De ? ( */}
                      {!permiElm.IPU ? (
                        <Fab
                          size="medium"
                          color="primary"
                          // disabled
                          style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                          className={buttonClassnameDelete}
                          onClick={(e) => {
                            setConfirmDialog({
                              isOpen: true,
                              onConfirm: () => {
                                deleteTos(e);
    
                              },
                            })
                          }}
                        >
                          {success.delete ? <CheckIcon /> : <DeleteIcon />}
                        </Fab>
                      ) : (
                        <Fragment>{elmntIcon(<DeleteIcon />, true)}</Fragment>
                      )}
                      {loading.delete && (
                        <CircularProgress
                          size={56}
                          className={clasCAB.fabProgressCAB}
                        />
                      )}
                    </div>
                  ) : null)
               ) }

            
              <div className={clasCAB.wrapperCAB}>
                {!permiElm.Prv && !permiElm.SaUp ? (
                  <Fab
                    size="medium"
                    color="primary"
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    onClick={(e) => clickOpenToggle(e, 2)}
                  >
                    <MoreVert />
                  </Fab>
                ) : (
                  <Fragment>{elmntIcon(<MoreVert />, true)}</Fragment>
                )}
              </div>
              <div className={clasCAB.wrapperCAB}>
                {!permiElm.Prv && !permiElm.SaUp ? (
                  <Fab
                    size="medium"
                    color="primary"
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    onClick={clickOpenToggle}
                  >
                    <PrintIcon />
                  </Fab>
                ) : (
                  <Fragment>{elmntIcon(<PrintIcon />, true)}</Fragment>
                )}
              </div>


              {setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" ? (              <div className={clasCAB.wrapperCAB}>
                {!permiElm.SaUp ?
                  (
                    <Fab
                      size="medium"
                      color="primary"
                      disabled={
                        or.postput == "toPost"
                          ? false
                          : loading.print || loading.save
                      }
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      className={buttonClassnameSave}
                      onClick={() => toWhere("save", "e")}
                    >
                      {" "}
                      {success.save ? <CheckIcon /> : <SaveIcon />}
                    </Fab>
                  ) : (
                    <Fragment>{elmntIcon(<SaveIcon />, true)}</Fragment>
                  )}
                {(loading.print || loading.save) && (
                  <CircularProgress
                    size={56}
                    className={clasCAB.fabProgressCAB}
                  />
                )}
              </div>):(              <div className={clasCAB.wrapperCAB}>
                {!permiElm.SaUp ?
                  (
                    <Fab
                      size="medium"
                      color="primary"
                      disabled={
                        or.postput == "toPost"
                          ? false
                          : loading.print || loading.save
                      }
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      className={buttonClassnameSave}
                      onClick={() => toWhere("save", "e")}
                    >
                      {" "}
                      {success.save ? <CheckIcon /> : <SaveIcon />}
                    </Fab>
                  ) : (
                    <Fragment>{elmntIcon(<SaveIcon />, true)}</Fragment>
                  )}
                {(loading.print || loading.save) && (
                  <CircularProgress
                    size={56}
                    className={clasCAB.fabProgressCAB}
                  />
                )}
              </div>)}
              {stor.invsType == 2 && or.postput != "toPost" ? (
                <div className={clasCAB.wrapperCAB}>
                  <Fab
                    size="medium"
                    color="primary"
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    onClick={transformDone}
                  >
                    {" "}
                    <TransformIcon />
                  </Fab>
                </div>
              ) : null}
            </Hidden>
            {/* 
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
              onClick={() => {
                setChecked({ ...checked, autoOrStaticck: true });
                handleDia(TransitionUp, "fav", true);
              }}
              startIcon={<StarIcon style={{ color: "#f7c801" }} />}
            >
              {" "}
              {""}
            </Button> */}

            <Hidden only={["xs"]}>
              {setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" ? (null) : (
                (uiElement.DeleIcon === true ? (
                  <Fragment>
                    <div className={clasCAB.wrapperCAB}>
                      {/* {!permiElm.De ? ( */}
                      {permiElm.IPU ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={buttonClassnameDelete}
                          startIcon={<DeleteIcon />}
                          style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                                      // disabled
                                      /*={loading.delete}*/ onClick={(e) => {
                            setConfirmDialog({
                              isOpen: true,
                              onConfirm: () => {
                                deleteTos(e);
                              },
                            })
                          }}
                        >
                          {transForm("delete")}
                        </Button>
                      ) : (
                        <Fragment>{elmntBtn("حـذف", <DeleteIcon />)}</Fragment>
                      )}
                      {loading.delete && (
                        <CircularProgress
                          size={24}
                          className={clasCAB.buttonProgressCAB}
                        />
                      )}
                    </div>
                  </Fragment>
                ) : null)

              )}
              {/* {uiElement.DeleIcon === true ? (
                <Fragment>
                  <div className={clasCAB.wrapperCAB}>
                    {permiElm.IPU ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={buttonClassnameDelete}
                        startIcon={<DeleteIcon />}
                        style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            onConfirm: () => {
                              deleteTos(e);
                            },
                          })
                        }}
                      >
                        {transForm("delete")}
                      </Button>
                    ) : (
                      <Fragment>{elmntBtn("حـذف", <DeleteIcon />)}</Fragment>
                    )}
                    {loading.delete && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>
                </Fragment>
              ) : null} */}

              {stor.invsType == 2 && or.postput != "toPost" ? (
                <div className={clasCAB.wrapperCAB}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<TransformIcon />}
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    onClick={transformDone}
                  >
                    تحويل لفاتورة مبيعات
                  </Button>
                </div>
              ) : null}
              <div className={clasCAB.wrapperCAB}>
                {!permiElm.Prv && !permiElm.SaUp ? (
                  <Fab
                    size="medium"
                    color="primary"
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    onClick={(e) => clickOpenToggle(e, 2)}
                  >
                    <MoreVert />
                  </Fab>
                ) : (
                  <Fragment>{elmntIcon(<MoreVert />, true)}</Fragment>
                )}
              </div>
              {!permiElm.Prv && !permiElm.SaUp ? (
                <Fragment>
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={buttonClassnamePrint}
                      startIcon={<ScannerIcon />}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={loading.print || loading.save}
                      onClick={() => toPrint("print", "e", 2)}
                    >
                      {transForm("great-view")}
                    </Button>
                    {(loading.print || loading.save) && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={buttonClassnamePrint}
                      startIcon={<PrintIcon />}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={loading.print || loading.save}
                      onClick={() => toPrint("print", "e", 1)}
                    >
                      {transForm("cashier-view")}
                    </Button>
                    {(loading.print || loading.save) && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>

                  <div className={clasCAB.wrapperCAB}>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      // className={buttonClassnamePrint}
                      startIcon={<ArrowDownward />}
                      // style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={loading.print || loading.save || generating}
                      onClick={() => toPrint("print", "e", 3)}
                    >
                      {/* {transForm("cashier-view")} 
                    </Button> */}
                    {/* {(loading.print || loading.save || generating) && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )} */}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {elmntBtn("معاينة كبير", <ScannerIcon />)}
                  {elmntBtn("معاينة كاشير", <PrintIcon />)}
                </Fragment>
              )}

              {/* <div>{or.postput}</div> */}
              {setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" ? (
                <div className={clasCAB.wrapperCAB}>
                  {!permiElm.SaUp ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      className={buttonClassnameSave}
                      disabled={
                        loading.print ||
                        loading.save ||
                        (or.postput == "toPost" ? false : true)

                      }
                      onClick={() => toWhere("save", "e")}
                      startIcon={<SaveIcon />}
                    >
                      {transForm("save-out-print")}
                    </Button>
                  ) : (
                    <Fragment>
                      {elmntBtn(transForm("save-out-print"), <SaveIcon />)}
                    </Fragment>
                  )}
                  {(loading.print || loading.save) && (
                    <CircularProgress
                      size={24}
                      className={clasCAB.buttonProgressCAB}
                    />
                  )}
                </div>
              ) :
                (<div className={clasCAB.wrapperCAB}>
                  {!permiElm.SaUp ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      className={buttonClassnameSave}
                      disabled={
                        loading.print ||
                        loading.save ||
                        (or.postput == "toPost" ? false : !permiElm.IPU)

                      }
                      onClick={() => toWhere("save", "e")}
                      startIcon={<SaveIcon />}
                    >
                      {transForm("save-out-print")}
                    </Button>
                  ) : (
                    <Fragment>
                      {elmntBtn(transForm("save-out-print"), <SaveIcon />)}
                    </Fragment>
                  )}
                  {(loading.print || loading.save) && (
                    <CircularProgress
                      size={24}
                      className={clasCAB.buttonProgressCAB}
                    />
                  )}
                </div>)}


            </Hidden>
            {/* </div> */}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  };

  const changChckedYahm = () =>
    alert("يمكنك تغييرها من إعدادات المبيعات في النظام");

  const frontUp = () => {
    return (
      <div className={clasBA.continarBAInv}>
        <React.Fragment>
          <Grid item container spacing={0} className={clashd.rootHd}>
            <Grid item lg={4} md={4} sm={4} xs={4}></Grid>

            <Grid item lg={4} md={4} sm={4} xs={3}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={setting.titleHed}
                color="primary"
              />
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={5}>
              <FormControlLabel
                id="costWithVat"
                value="costWithVat"
                name="costWithVat"
                className={clashd.ckBoxHd} //disabled={true}
                control={
                  <SaleCheckbox
                    checked={checked.costWithVat}
                    onChange={changChckedYahm}
                    color="primary"
                  />
                }
                label={permiCuCo.CWVtxt}
                labelPlacement="end"
              />
            </Grid>
          </Grid>

          <CssBaseline />
          <Container maxWidth="xl" className={clasup.AboverootupInv}>
            {/* S front H */}
            <Grid item container spacing={1} className={clasup.rootup}>
              <Grid
                item
                lg={1}
                md={1}
                sm={1}
                xs={2}
                style={{
                  padding: "0px",
                  color: "#fff",
                  textAlign: "-webkit-center",
                }}
              >
                <IconButton
                  color="inherit"
                  style={{
                    background: "#b925bcd4",
                    padding: "10px",
                    color: "#fff",
                  }}
                  aria-label="open drawer"
                  onClick={handleOpenCustomer}
                >
                  <AddIcon />
                </IconButton>
              </Grid>

              {permiElm.RCNA === 0 ? (
                <Grid item lg={1} md={3} sm={3} xs={5}>
                  <Autocomplete
                    classes={clasAo}
                    value={valueCustomers}
                    onChange={(event, newValue) => {
                      changAutoCustomers(newValue);
                    }}
                    id="controllable-states-demo"
                    size="small"
                    options={optionsCustomers}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    // style={{  }}

                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        id="valueCustomers"
                        {...params}
                        label={setting.cusN}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ className: claslbl.clor }}
                      // InputLabelProps={{shrink: true}}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item lg={1} md={3} sm={3} xs={5}>
                  <Autocomplete
                    classes={clasAo}
                    value={valueCustomers}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setValueCustomers({
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValueCustomers({
                          name: newValue.inputValue,
                        });
                      } else {
                        changAutoCustomers(newValue);

                        setValueCustomers(newValue);
                      }
                    }}
                    // getOptionSelected={}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `إضافة "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    disableClearable
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={optionsCustomers}
                    // options={optionsCustomers}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        if (valueType.id === 1)
                          changAutoType(gets.types[0], true);
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        id="valueType"
                        {...params}
                        label={setting.cusN}
                        size="small"
                        variant="outlined"
                        InputLabelProps={{ className: claslbl.clor }}
                      />
                    )}
                  />
                </Grid>
              )}

              {/* //eds change  */}

              <Grid item lg={1} md={2} sm={2} xs={5}>
                <Autocomplete
                  classes={clasAo}
                  value={valueSalesmen}
                  onChange={(event, newValue) => {
                    changAutoSalesMen(newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.salesmen}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      id="valueSalesmen"
                      {...params}
                      label={transForm("delegate")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={1} md={2} sm={2} xs={4}>
                <Autocomplete
                  classes={clasAo}
                  value={valueCostcenter}
                  onChange={(event, newValue) => {
                    changAutoCostcenter(newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.costcenters}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      id="valueCostcenter"
                      {...params}
                      label={transForm("center")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={1} md={2} sm={2} xs={4}>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="id"
                  label={transForm("invoice-id")}
                  className={clasTF.root}
                  InputProps={{ className: clasTF.input, readOnly: true }}
                  name="id"
                  value={infoInv.id}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item lg={1} md={2} sm={2} xs={4}>
                {
                  // (permiElm.USD || permiElm.UPD || setting.PrTy_InPr==="22")
                  //     ?

                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    id="date"
                    label={transForm("date")}
                    className={clasTF.root}
                    InputProps={{ className: clasTD.input }}
                    type="date"
                    format="YYYY-MM-DD"
                    InputLabelProps={{ shrink: true }}
                    name="date"
                    value={idata.date}
                    onChange={changIdata("date")}
                  />

                  // :<TextField fullWidth variant="outlined" disabled size="small" id="date" label="التاريخ"
                  // className={clasTF.root} InputProps={{ className: clasTD.input }}
                  // name="date" format="MM/DD/YYYY" value={idata.date}  InputLabelProps={{shrink: true}} />
                }
              </Grid>

              <Grid item lg={1} md={2} sm={2} xs={4}>
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="refno"
                  label={transForm("ref-no")}
                  className={clasTF.root}
                  InputProps={{ className: clasTF.input }}
                  name="refno"
                  value={idata.refno}
                  onChange={changIdata("refno")}
                />
              </Grid>

              <Grid item lg={1} md={2} sm={2} xs={3}>
                <Autocomplete
                  classes={clasAo}
                  value={valueType}
                  onChange={(event, newValue) => {
                    changAutoType(newValue, true);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.types}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  autoComplete={false}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      id="valueTypeF"
                      {...params}
                      label={transForm("invoice-type")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    // InputLabelProps={{shrink: true}}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={1} md={2} sm={2} xs={5}>
                <Autocomplete
                  classes={clasAo}
                  value={valueSafes}
                  onChange={(event, newValue) => {
                    changAutoSafes(newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.Safes}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  style={{}}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      id="valueSafes"
                      {...params}
                      label={transForm("safe")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    //  InputLabelProps={{shrink: true}}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={1} md={2} sm={2} xs={5}>
                <Autocomplete
                  classes={clasAo}
                  value={valueStock}
                  onChange={(event, newValue) => {
                    changAutoStock(newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.Stocks}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}

                  disableClearable
                  disabled={uiElement.stock}
                  renderInput={(params) => (
                    <TextField
                      id="valueStock"
                      {...params}
                      label={transForm("account")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={1} md={3} sm={2} xs={4}>
                <Autocomplete
                  classes={clasAo}
                  value={valueBank}
                  onChange={(event, newValue) => {
                    changAutoBank(newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.Banks}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  className={clasup.colorSal}
                  // style={{  }}
                  disableClearable
                  disabled={uiElement.bank}
                  renderInput={(params) => (
                    <TextField
                      id="valueBank"
                      {...params}
                      label={""}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    />
                  )}
                />
              </Grid>

              <FormControlLabel
                control={
                  <Tooltip title="التبديل بين شبكة وكاش">
                    <Grid item lg={1} md={1} sm={2} xs={3}>
                      <SaleSwitch
                        size="small"
                        disabled={uiElement.payMethod}
                        checked={switchState.cashORnetworksw}
                        onChange={changSwitch("cashORnetworksw")}
                        id="cashORnetworksw"
                        value="cashORnetworksw"
                        name="cashORnetworksw"
                      />
                    </Grid>
                  </Tooltip>
                }
                className={claslbl.clorSw}
                label={lbl.chashNetwork}
                labelPlacement="start"
              />

              <Grid item lg={1} md={3} sm={2} xs={4}>
                <Autocomplete
                  classes={clasAo}
                  value={omlaValue}
                  onChange={(event, newValue) => {
                    changAutoOmla(newValue);
                  }}
                  id="controllable-states-demo"
                  size="small"
                  options={gets.omlats}
                  // disabled={disabled? disabled: false}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  // style={{  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      id="omlaValue"
                      {...params}
                      label={transForm("currency")}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: claslbl.clor }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item lg={2} md={3} sm={4} xs={6}>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="numberbarcode"
                  label={transForm("barcode")}
                  className={clasTF.root}
                  onFocus={handleFocus}
                  onClick={() => {

                   // loadGetCountSafeByGroup();
                  }}
                  // InputProps={{
                  //   className: clasTF.input,
                  //   onKeyPress: (event) => {
                  //     const { key } = event;
                  //     if (key == "Enter") {
                  //       onChangeSearch(numberbarcode);

                  //       // setNumberbarcode("");
                  //     }
                  //   },
                  // }}
                  InputProps={{
                    onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            onChangeSearch(event);
                        }
                    },                       
                }}
                  // autoFocus={true}
                  // disabled={barcoding}
                  name="text"
                  value={rptParams.barcode}
                  // onChange={getBarCode}
                  // onChange={(e) => {
                  //   setNumberbarcode(e.target.value);
                  //   setIsBarcode(false);
                  // }}
                  onChange={onChangeData("barcode")} 

                  // InputLabelProps={{ shrink: true, readOnly: barcoding }}
                />
              </Grid> */}


              <Grid item lg={3} md={3} sm={3} xs={6} >
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="barcode"
                  label={transForm("forms:barcode")}
                  style={{ backgroundColor: colors.green[50], marginBlock: 5 }}
                  name="barcode"
                  autoFocus={true}
                  value={rptParams.barcode}
                  InputProps={{
                    onKeyDown: (event) => {
                      if (event.key === 'Enter') {
                        onChangeSearch(event);
                      }
                    },
                  }}
                  onFocus={handleFocus}

                  onChange={onChangeData("barcode")} />
              </Grid>

              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className={clasPM.checkPM}
                style={{ direction: 'rtl' }}
              >
                <FormControlLabel
                  id="showPaid"
                  value="showPaid"
                  name="showPaid"
                  className={claslbl.clor}
                  control={
                    <SaleCheckbox
                      checked={showPaid}
                      onChange={(e) => {
                        setShowPaid(e.target.checked);
                        // changeIsPay(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="شاشة الدفع"
                  labelPlacement="end"
                />
              </Grid>

              {exsub > 0 ? (
                <Grid item lg={2} md={2} sm={2} xs={4}>
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      style={{
                        ...{
                          background: "#ff8080",
                          color: "#fff",
                          direction: "initial",
                        },
                      }}
                      className={buttonClassnameSavetmp}
                      disabled={loading.savetmp}
                      onClick={() => toWhere("savetmp", "e")}
                      startIcon={<StopIcon />}
                    >
                      {transForm("invoice-suspension")}
                    </Button>
                    {loading.savetmp && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>
                </Grid>
              ) : null}
              {setting.PrTy_InPr === "11" || setting.PrTy_InPr === "12" ? (
                <Fragment>
                  <Grid item lg={2} md={3} sm={4} xs={6}>
                    <TextField
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="InvId_Return"
                      label="رقم المرتجع"
                      className={clasTF.root}
                      InputProps={{ className: clasTF.input }}
                      name="InvId_Return"
                      value={infoInv.InvId_Return}
                      onChange={changInvs("InvId_Return")}
                      placeholder=""
                    />
                  </Grid>

                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fontSize="large"
                      size="medium"
                      onClick={handClickInvs}
                    >
                      تنزيل
                    </Button>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
            </Grid>

            {/* E front H */}
            {/* // eds changes  */}

            {/* S front B */}
            <Grid item container className={clasup.rootup}>
              {/* S MatTableOld */}

              <Grid item md={12} sm={12} xs={12}>
                <MaterialTable
                  style={{ boxShadow: "none" }}
                  //style={{direction:'ltr'}}
                  options={{
                    showTitle: false,
                    toolbar: true,
                    search: true,
                    // search:false,
                    sorting: false,
                    selection: true,
                    // ToolBar Search
                    searchFieldAlignment: "left",
                    searchFieldVariant: "outlined",

                    // ToolBar header
                    showSelectAllCheckbox: false,
                    showTextRowsSelected: false,

                    cellStyle: stElemnt.SubR,
                    headerStyle: stElemnt.SubH,

                    // footer
                    pageSize: 5,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,

                    paging: false,

                    maxBodyHeight: Mtb.minmaxBHSub,
                    minBodyHeight: Mtb.minmaxBHSub,
                  }}
                  components={{
                    Toolbar: (props) => (
                      <Fragment>
                        <div
                          className={clstoolbar.toolbarWrapper}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "6.1vh",
                          }}
                        >
                          <MTableToolbar
                            {...props}

                            searchFieldStyle={{ height: "6vh" }}
                          />
                        </div>
                      </Fragment>
                    ),
                  }}

                  actions={

                    (!isHedden
                      ?
                      ([
                        {
                          icon: () => <Delete />,
                          tooltip: "حذف الأصناف",
                          onClick: hRowDelete,
                        },
                      ])
                      :
                      false
                    )

                  }
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <h3 style={{ color: "#000" }}></h3>
                      ),
                    },
                    toolbar: [{ searchPlaceholder: "بحث في السجل" }],
                  }}
                  columns={columnsInvSub}
                  data={isdata}
                  icons={
                    (!isHedden
                      ?
                      tableIcons
                      :
                      false
                    )
                  }


                  editable={
                    (!isHedden
                      ?
                      ({
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve) => {
                            hRowUpdate(newData, oldData, resolve);
                          })
                      })
                      :
                      false
                    )
                  }
                // cellEditable={{
                //   onCellEditApproved: (
                //     newValue,
                //     oldValue,
                //     rowData,
                //     columnDef
                //   ) =>
                //     new Promise((resolve, reject) => {
                //       hRowUpdate(newValue, oldValue, resolve);
                //     }),
                // }}
                />
              </Grid>

              {/* E MatTableNew */}
            </Grid>
            {/* E front B */}

            {/* S front F */}

            {/* S front F */}
          </Container>
        </React.Fragment>
      </div>
    );
  };

  const frontInvs = () => {
    return (
      // <SearchInvoiceModal
      <SearchInvoice

        {...{
          openDw,
          handCloseDw,
          transition,
          showChosedInv,
          invsShow,
          stElemnt,
          style,
          valueTypeFltr,
          gets,
          searchedInvShow,
          setOr,
          or,
          optionsCustomers,
          PcIdIvPc: setting.PrTy_InPr,
          setting,
          setIsClick,
          ished,
        }}
      />
    );
  };

  function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handUp = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      );
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    return (
      <Zoom in={trigger}>
        <div onClick={handUp} role="presentation" className={clasFu.root}>
          {children}
        </div>
      </Zoom>
    );
  }
  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };

  const frontInvst = () => {
    return (
      // <Outstandingdf />
      <OutstandingInvoiceModal
        {...{
          openDwt,
          handCloseDwt,
          transition,
          showChosedInvt,
          invsShowt,
          stElemnt,
          style,
        }}
      />
    );
  };

  const [scroll, setScroll] = React.useState("paper");

  const CurrFavorite = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={dialog.fav}
          onClose={handleClDia}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            className={clasSM.dialogTitleSM}
            id="customized-dialog-title"
          >
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {gets.FavGroup.map((group, i) => (
                <Tab
                  key={i}
                  label={group.name}
                  onClick={() => changFavSlides(group.id)}
                />
              ))}
            </Tabs>
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText id="scroll-dialog-description">
              <FavCard
                prop={favGroups}
                onclick={(id, barcode) => BefrowDataCurrencie(id, barcode)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClDia("fav", false)} color="primary">
              {transForm("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const stylesserch = {
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
        fontSize: 10,
      },
    },
  };

  const frontCurrencis = () => {
    return (
      <InvoiceItemsModal
        {...{
          openCuD,
          handleCloseCuD,
          transition,
          switchState,
          groups,
          groupsSub,
          changSlides,
          reGet,
          currShowSearch,
          auto,
          changAutoss,
          searchedCurrShow,
          subInfo,
          BefrowDataCurrencie,
          cuRows,
          permiCuCo,
          setting,
          changAutoPricePay,
          valueCurrUnits,
          valuePricePay,
          changAutoCurrUnits,
          permiElm,
          changInfo,
          optionsPricePay,
          optionsCurrUnits,
          checked,
          changSwitch,
          _onDetected,
          handleOpenSy,
          changChcked,
          getrowDataCurrencie,
          stElemnt,
          style,
          counts,
        }}
      />
    );
  };

  const frontPMDialog = () => {
    return (
      <></>
      // <PaidWindowForm 
      // {... { 
      //   idata, 
      //   invDisc, 
      //   payment, 
      //   changPayment, buttonClassnameSavePMD, toDB, restPM, loading,
      //   handClosePMD,
      //   style,
      //   gets,
      //   openPMD:true,
      //   valueEmployePMD,
      //   uiElement,
      //   changChcked,
      //   transition,
      //   checked,
      //   clasAo,
      //   valueBankPMD,
      //   changAutoBankPMD,
      //   changIdata,
      //   changAutoEmployee,
      //   valueBankTransPMD,
      //   changAutoBank2 } }
      // />
    );
  };

  const frontFootr = () => {
    return (
      <Grid
        item
        container
        spacing={1}
        className={clasup.rootup}
        style={{ marginBottom: 80 }}
      >
        <Grid item lg={3} md={3} sm={4} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="notes"
            label={transForm("note")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input }}
            name="notes"
            value={idata.notes}
            onChange={changIdata("notes")}
          />
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={3}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label={transForm("remaining")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="stay"
            value={payment.stay}
            placeholder="0"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={3}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="paidup"
            label={transForm("paid")}
            className={clasTF.root}
            // InputProps={{ className: clasTF.input }
            InputProps={{

              className: clasTF.input,
              // onBlur: (event) => {
              //   blurPaid(event);

              //   // console.log("the key is", event)

              // },
              readOnly: valueType.id == 1 ? false : true
            }}
            name="paidup"
            value={payment.paidup}
            placeholder="0"

            onChange={changPayment("paidup")
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={1} md={1} sm={2} xs={3}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="val1"
            label={transForm("total-quantity")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="val1"
            value={sums.val1}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {setting.PrTy_InPr == "02" || setting.PrTy_InPr == "12" ? (
          <Grid item lg={2} md={2} sm={2} xs={5}>
            <TextField
              type="number"
              fullWidth
              variant="outlined"
              size="small"
              id="tot_sale"
              label={setting.priceTotNM}
              className={clasTF.root}
              InputProps={{ className: clasTF.input, readOnly: true }}
              name="tot_sale"
              value={parseFloat(idata.tot_sale).toFixed(2)}
              placeholder="0"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        ) : (
          <Grid item lg={2} md={2} sm={2} xs={4}>
            <TextField
              type="number"
              fullWidth
              variant="outlined"
              size="small"
              id="tot_purch"
              label={setting.priceTotNM}
              className={clasTF.rootPurch}
              InputProps={{ className: clasTF.inputPurch, readOnly: true }}
              name="tot_purch"
              value={parseFloat(idata.tot_purch).toFixed(2)}
              placeholder="0"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
        <Grid item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="amountWithVat"
            label={transForm("total-after-descount")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="amountWithVat"
            value={idata.amountWithVat}
            placeholder="0"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={7}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="amountAfterDisc"
            label={transForm("total-before-tax")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="amountAfterDisc"
            value={idata.amountAfterDisc}
            placeholder="0"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={5}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="amountOfVat"
            label={transForm("total-value-add")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="amountOfVat"
            value={parseFloat(idata.amountOfVat).toFixed(2)}
            placeholder="0"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={3}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="netAmountSub"
            label={transForm("net")}
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="netAmountSub"
            value={parseFloat(idata.netAmount).toFixed(2)}
            placeholder="0"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="minus"
            label={transForm("discount-amount")}
            disabled={
              (permiElm.APDC === 2 || permiElm.APDC === 3) && permiElm.DSOB == 1
                ? false
                : true
            }
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: uiElement.minus }}
            onClick={() => changClick("minus", false)}
            name="minus"
            value={invDisc.minus}
            placeholder="0"
            onChange={changinvDisc("minus")}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={3}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="rate"
            label={transForm("discount")}
            disabled={
              (permiElm.APDC === 1 || permiElm.APDC === 3) && permiElm.DSOB == 1
                ? false
                : true
            }
            className={clasTF.root}
            InputProps={{ className: clasTF.input, readOnly: uiElement.rate }}
            onClick={() => changClick("rate", false)}
            name="rate"
            value={invDisc.rate}
            placeholder="0"
            onChange={changinvDisc("rate")}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    );
  };

  if (!isApiConnect) return <ReConnect />;

  return showSc ? (
    <BackdropScI />
  ) : (
    <div>
      {/* s Fixed */}
      {frontSnack()}
      {frontAppBar()}
      {/* e Fixed */}
      {/* s UP */}
      {frontUp()}
      {/* <Divider /> */}
      {/* e UP */}
      {/* s Fotr */}
      {/* <Hidden only={['xs','sm']}> */}
      {frontFootr()}
      {/* </Hidden> */}
      {/* e Fotr */}
      {/* s DW */}
      {frontInvs()}
      {/* e DW */}
      {/* s DW */}
      {frontInvst()}
      {frontCurrencis()}
      {/* s currFavD */} {CurrFavorite()} {/* e currFavD */}
      {/* s PMD */}
      {/* {frontPMDialog()} */}
      {/* e PMD */}
      {
        allow.c ? (
          <CustomersDialog
            open={isOpenCustomer}
            type={location.state.setting.cusType}
            onClose={() => {
              setIsOpenCustomer(false);
              reloadGet();
            }}
          />
        ) : null}
      <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* {allow.s ? (
        <ItemsDialog
          DialogOpened={isOpenSy}
          DialogClose={() => {
            setIsOpenSy(false);
            setAllow({ ...allow, s: false });
          }}
          locats={{ state: storS }}
        />
      ) : null} */}
      <ExtVoucherDialog
        voucherDialog={voucherDialog}
        setVoucherDialog={setVoucherDialog}
      />

      <PaidWindowForm
        {... {
          idata,
          invDisc,
          remains,
          setRemains,
          payment,
          changPayment,
          buttonClassnameSavePMD,
          toDB,
          restPM,
          loading,
          handClosePMD,
          style,
          changClick,
          changinvDisc,
          gets,
          openPMD,
          valueEmployePMD,
          uiElement,
          changChcked,
          transition,
          checked,
          clasAo,
          valueBankPMD,
          changAutoBankPMD,
          changIdata,
          changAutoEmployee,
          valueBankTransPMD,
          changAutoBank2,
          typePress,
          valueType,
        }}
      />
      {/* {allowPaid.c ? ( */}
      {/* <PaidWindowForm
          key="ddd"
          isOpen={isOpenCustomer}
          // remains={{}}
          // idata={{}}
          // payment={{}}
          // onClose={() => {
          //   setOpenPaidWindows(false);
          //   reloadGet();
          // }}
        /> */}
      {/* ) : null} */}

    </div>
  );
}
