function splitSentenceToLines(sentence, lineLength) {
  const words = sentence.split(" ");
  let currentLine = "";
  const result = [];

  for (const word of words) {
    if (currentLine.length + word.length + 1 <= lineLength) {
      // Add the word to the current line
      currentLine += (currentLine.length > 0 ? " " : "") + word;
    } else {
      // Start a new line with the current word
      var text = currentLine.split(" ");
      result.push(text.reverse().join(" ") + "\n");
      currentLine = word;
    }
  }

  // Add the last line
  if (currentLine.length > 0) {
    var text = currentLine.split(" ");
    result.push(text.reverse().join(" ") + "\n");
    // result.push(currentLine + "\n");
  }
  // console.log(result.reverse().join("\n"));

  return result;
}

export function handleArabicCharacters(message) {
  let arabic = [];
  let english = [];

  // var regex = new RegExp(
  //   "[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]"
  // );

  // var text = message;
  const regexArabic = /[\u0600-\u06FF]+/;
  if (regexArabic.test(message)) return splitSentenceToLines(message, 30);
  return message;
  // arabic = message.split(" ").filter((e) => regex.test(e));
  // english = message.split(" ").filter((e) => !regex.test(e));
  // return `${english.join(" ")} ${arabic.reverse().join("  ")}`;
}

export function reverseArabicAndEnglish(message) {
  var regex = new RegExp(
    "[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]"
  );

  // var text = message.split(" ");
  console.log("the text is", message);
  var arabic = message.split(" ").filter((e) => regex.test(e));
 // var arabic = message.split(" ").filter((e) => regex.test(e));

  // const english = message.split(',').filter((e) => !regex.test(e));
 var english = message.split(" ").filter((e) => !regex.test(e));

  return `${english.join(" ")} ${arabic.reverse().join("  ")}`;
}

function detectLang(text) {
  let lang = "ENG";

  if (/[A-Z]|[a-z]/g.test(text)) {
    lang = "ENG";
  }

  if (
    /[ء-ي]/gi.test(text) ||
    /[گچپژیلفقهمو ء-ي]/gi.test(text) ||
    /[\u0600-\u06FF]/gi.test(text)
  ) {
    lang = "ARA";
  }

  if (lang === "ARA") {
    return true;
  } else return false;
}
